import { TebonusBagIcon } from "components/_graphics";
import { TegutBadge } from "components/_tegut/TegutBadge";
import { Colors } from "styles";
import React, { FunctionComponent, ReactElement } from "react";

interface DashboardPromotionsHeaderProps {
    isInitial: boolean;
    arePromotionsLocked: boolean;
}

export const DashboardPromotionsHeader: FunctionComponent<DashboardPromotionsHeaderProps> = (props): ReactElement => {
    /**
     * Render the header depended on if the promotions are locked, initial or a new month has started
     * @returns the corresponding text.
     */
    function getHeaderText(): React.JSX.Element | string {
        if (props.arePromotionsLocked) {
            return "Viel Spaß beim Punkte sammeln!";
        }

        if (props.isInitial) {
            return (
                <>
                    {"Wählen Sie bis zu "}
                    <span className="font-unitText-Bold">7 Aktionsprodukte</span>
                    {", auf die Sie "}
                    <span className="font-unitText-Bold">diesen Monat</span>
                    {" punkten."}
                </>
            );
        }

        return (
            <>
                {"Welche "}
                <span className="font-unitText-Bold">7 Aktionsprodukte</span>
                {" wählen Sie "}
                <span className="font-unitText-Bold">diesen Monat</span>, um Punkte zu sammeln?
            </>
        );
    }

    return (
        <>
            {/* TITLE */}
            <div className="d-flex flex-md-row flex-column justify-content-center justify-content-lg-start align-items-center mb-2 mb-md-1">
                <TegutBadge bgColor={Colors.orange} className="dashboard-icon-small">
                    <TebonusBagIcon htmlColor={Colors.white} fontSize="medium" />
                </TegutBadge>
                <span className="dashboard-tile-title pl-1 ml-md-2">Aktionsprodukte</span>
            </div>

            {/* subtitle */}
            <div className="text-justify text-center dashboard-tile-subheader">{getHeaderText()}</div>
        </>
    );
};
