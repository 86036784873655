import LeavePageModal from "components/LeavePageModal";
import { SearchProps } from "types";
import { CommonButtonTitles } from "utils/FirebaseAnalytics";
import FilterModalMobile from "./FilterModalMobile";
import React, { FunctionComponent, ReactElement } from "react";

interface ModalsProps {
    isFilterModalOpen: boolean;
    setShowModal: Function;
    setSelectedBrands: (value: SearchProps[]) => void;
    selectedBrands: SearchProps[];
    setSelectedCategories: (value: SearchProps[]) => void;
    selectedCategories: SearchProps[];
    categoryOptions: SearchProps[];
    handleLeavePage: () => void;
}

const ArticleFinderModals: FunctionComponent<ModalsProps> = (props): ReactElement => {
    return (
        <>
            <LeavePageModal
                shownWhen={true}
                discardButtonTitle={CommonButtonTitles.multiplier.discardMultiplier}
                returnButtonTitle={CommonButtonTitles.multiplier.returnToMultiplier}
                ignoringMessagePath="/meintebonus/Lieblingsprodukte"
                handleLeavePage={props.handleLeavePage}
            />

            {props.isFilterModalOpen && (
                <FilterModalMobile
                    setShowModal={props.setShowModal}
                    setSelectedBrands={props.setSelectedBrands}
                    selectedBrands={props.selectedBrands}
                    setSelectedCategories={props.setSelectedCategories}
                    selectedCategories={props.selectedCategories}
                    categoryOptions={props.categoryOptions}
                />
            )}
        </>
    );
};

export default ArticleFinderModals;
