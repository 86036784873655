import { useAppDispatch, useAppSelector } from "utils/hooks";
import PageBase from "components/Shared/PageBase";
import { CommonButtonTitles, RouteMaps, logAnalyticsButton } from "utils/FirebaseAnalytics";
import { getMultiplierStatus, hasInternetConnection } from "utils/MultiplierPromotionUtils";
import { ArticleMultiplierState } from "store/ArticleMultipliers";
import Footer from "components/ArticleMultipliers/Footer";
import ProductTiles from "components/ArticleMultipliers/ProductTiles";
import { LEAVE_PAGE_FROM_BROWSER_EVENT_TEXT } from "components/LeavePageModal";
import { TebonusSingleHeart } from "../../components/_graphics";
import { TegutBadge } from "../../components/_tegut/TegutBadge";
import { AppState } from "../../store/configureStore";
import { Article } from "../../types";
import { actionCreators } from "../../store";
import { Colors } from "../../styles";
import { calculateTimeUntilNewChoice } from "../../utils/DateTimeUtils";
import MultiplierWelcomeText from "../../components/MultiplierWelcomeText";
import MultiplierModals, { ModalTypes } from "../../components/ArticleMultipliers/Modals";
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// initial multiplier articles
const initialArticles = [undefined, undefined, undefined];

/**
 * @returns the the number of temporary selected multipliers, which have been saved in the session storage
 */
export const getNumberOfMultipliersInSessionStorage = (): number => {
    let counter = 0;
    initialArticles.forEach((item, index) => {
        if (sessionStorage.getItem(index.toString())) {
            counter++;
        }
    });

    return counter;
};

/**
 * Remove all selected multiplier articles from the session storage
 */
export const handleLeavePage = (): void => {
    if (getNumberOfMultipliersInSessionStorage() !== 0) {
        initialArticles.forEach((item, index) => {
            sessionStorage.removeItem(index.toString());
        });
    }
};

const ArticleMultipliers: FunctionComponent = (): ReactElement => {
    const IS_ON_DETAILS_PAGE = true;
    const HEADING_BADGE_SIZE = 35;
    const HEADING_HEART_ICON_MULTIPLIER = 0.75;

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const articleMultipliers: ArticleMultiplierState = useAppSelector((state: AppState) => state.multiplierReducer);
    const customers = useAppSelector((state: AppState) => state.customerReducer);

    const [isAnalyzeOptInVisible, setIsAnalyzeOptInVisible] = useState<boolean>(false);
    const [visibleModal, setVisibleModal] = useState<ModalTypes>(ModalTypes.NONE);
    const [hasOptInError, setHasOptInError] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = useState<number>();
    const [isCheckingInternetConnection, setIsCheckingInternetConnection] = useState<boolean>(false);

    /**
     * Changed multipliers are the ones that are displayed to the user.
     * If there are saved article multipliers in the store, they are displayed by default.
     * If the user is currently selecting new multipliers, the newly selected ones
     * (which are saved in the session storage) are displayed.
     */
    const changedMultipliers =
        getNumberOfMultipliersInSessionStorage() === 0
            ? (articleMultipliers.articles as Article[]) ?? initialArticles
            : getMultipliersFromSessionStorage();

    // #region useEffects

    useEffect(() => {
        dispatch(actionCreators.articleMultipliers.requestGetArticleMultipliers());
        dispatch(actionCreators.articleCategories.requestGetArticleCategories());
    }, []);

    useEffect(() => {
        dispatch(actionCreators.articleMultipliers.clearPromotionCollisions());

        if (calculateChosenArticleAmount() > 0) {
            window.addEventListener("beforeunload", handleTabClose);
            window.addEventListener("unload", handleLeavePageEvent);
        }

        return () => {
            window.removeEventListener("beforeunload", handleTabClose);
            window.removeEventListener("unload", handleLeavePageEvent);
        };
    }, []);

    useEffect(() => {
        const existingMultipliers = changedMultipliers.filter(m => m && m !== null);

        if (existingMultipliers.length > 0) {
            dispatch(
                actionCreators.articleMultipliers.validatePromotionCollisions({
                    multiplierTans: existingMultipliers.map(m => m.tan),
                })
            );
        }
    }, [changedMultipliers]);

    useEffect(() => {
        if (articleMultipliers.shouldFetchDetails) {
            dispatch(
                actionCreators.articleMultipliers.getMultiplierDetails(
                    articleMultipliers.articles.map(multiplier => multiplier?.tan)
                )
            );
        }
    }, [articleMultipliers.shouldFetchDetails]);

    // #endregion

    // #region functions

    const handleTabClose = useCallback((event: { preventDefault: () => void; returnValue: string }) => {
        event.preventDefault();
        event.returnValue = LEAVE_PAGE_FROM_BROWSER_EVENT_TEXT;
    }, []);

    const handleLeavePageEvent = useCallback(() => {
        handleLeavePage();
    }, []);

    /**
     * Fetches the article multipliers from the session storage and converts them
     * to JSON objects, since they are saved as strings.
     * @returns an array with the selected multipliers in JSON format
     */
    function getMultipliersFromSessionStorage(): Article[] {
        const array = initialArticles;
        array.forEach((item, index) => {
            if (sessionStorage.getItem(index.toString())) {
                array[index] = JSON.parse(sessionStorage.getItem(index.toString()));
            } else {
                array[index] = articleMultipliers.articles[index];
            }
        });

        return array;
    }

    function hideModal(): void {
        setIsAnalyzeOptInVisible(false);
    }

    /**
     * Return the number of temporary selected multiplier products
     * @returns
     */
    function calculateChosenArticleAmount(): number {
        if (isInitial() || areMultipliersEditable()) {
            return getNumberOfMultipliersInSessionStorage();
        }

        let selectedArticleMultipliers = 0;
        articleMultipliers.articles.forEach(article => article && selectedArticleMultipliers++);

        return selectedArticleMultipliers;
    }

    /**
     * Fetches the current multiplier status
     * @returns true if the user has never selected multipliers before, false otherwise
     */
    function isInitial(): boolean {
        return !articleMultipliers.nextChangeDate;
    }

    /**
     * Fetches the current multiplier status
     * @returns true if multipliers can be selected again (but not initial), false otherwise
     */
    function areMultipliersEditable(): boolean {
        return !articleMultipliers.areMultiplierLocked;
    }

    function openArticleFinder(index: number): void {
        const isFirst = isInitial();
        const isEditable = areMultipliersEditable();
        setVisibleModal(ModalTypes.NONE);

        navigate("/ArticleFinder", {
            state: {
                index,
                advantageAmount: articleMultipliers.values[index],
                isInitial: isFirst,
                isEditable,
            },
        });
    }

    function checkInternetConnection(): void {
        hasInternetConnection()
            .then(result => {
                setVisibleModal(ModalTypes.NONE);
                setIsCheckingInternetConnection(false);
                if (!result) {
                    setHasOptInError(true);
                } else {
                    setIsAnalyzeOptInVisible(true);
                }
            })
            .catch(() => setHasOptInError(true));
    }

    function evaluateNextStep(index: number): void {
        setCurrentIndex(index);
        setIsCheckingInternetConnection(true);
        if (customers.customer.analysisOptInDate) {
            openArticleFinder(index);
        } else {
            checkInternetConnection();
        }
    }

    function handleAgreement(): void {
        dispatch(actionCreators.customers.getCustomerInfo());
        hideModal();
        openArticleFinder(currentIndex);
    }

    function openArticleSelection(index: number): void {
        logAnalyticsButton({
            button_title: CommonButtonTitles.multiplier.multplierTile,
            screen_title: RouteMaps.Lieblingsprodukte,
            button_position: index + 1,
        });

        evaluateNextStep(index);
    }

    function saveArticleMultipliers(): void {
        // CASE: if analyze opt in is true
        dispatch(
            actionCreators.articleMultipliers.setArticleMultipliers(
                changedMultipliers.map((article, index) => {
                    return {
                        articleTan: article?.tan,
                        amount: articleMultipliers.values[index],
                    };
                })
            )
        );

        logAnalyticsButton({
            button_title: CommonButtonTitles.selection.saveSelection,
        });

        hideModal();
        setVisibleModal(ModalTypes.NONE);
    }

    function changeArticleMultipliers(modalType: ModalTypes): void {
        setVisibleModal(modalType);

        logAnalyticsButton({
            button_title: CommonButtonTitles.selection.changeSelection,
        });
    }

    function calculateEmptySlotsAmount(): number {
        let emptySlotsAmount = 0;
        if (isInitial()) {
            emptySlotsAmount = initialArticles.length - calculateChosenArticleAmount();
        } else if (!isInitial() && areMultipliersEditable()) {
            let selected = 0;
            changedMultipliers.forEach(article => article && selected++);
            emptySlotsAmount = initialArticles.length - selected;
        }

        return emptySlotsAmount;
    }

    function retryConnection(): void {
        setHasOptInError(false);
        evaluateNextStep(currentIndex);
    }

    function onSaveButtonClicked(): void {
        setVisibleModal(ModalTypes.CONFIRM);
        logAnalyticsButton({
            button_title: CommonButtonTitles.multiplier.saveMultiplier,
        });
    }

    function getModals(): React.JSX.Element {
        return (
            <MultiplierModals
                setOpenModal={setVisibleModal}
                openModal={visibleModal}
                currentArticleMultipliers={articleMultipliers}
                saveArticleMultipliers={saveArticleMultipliers}
                changeArticleMultipliers={changeArticleMultipliers}
                selectedProductsAmount={calculateChosenArticleAmount()}
                multiplierStatus={getMultiplierStatus(isInitial(), areMultipliersEditable())}
                emptySlotsAmount={calculateEmptySlotsAmount()}
                articleMultipliers={changedMultipliers}
                isCheckingInternetConnection={isCheckingInternetConnection}
                isAnalyzeOptInVisible={isAnalyzeOptInVisible}
                hasOptInError={hasOptInError}
                errorRetryButtonFunction={retryConnection}
                errorCancelButtonFunction={() => setHasOptInError(false)}
                handleOptInAgreement={handleAgreement}
                setHasOptInError={setHasOptInError}
                hideOptInModal={hideModal}
                handleLeavePage={handleLeavePage}
            />
        );
    }

    // #endregion

    return (
        <PageBase
            title="Ihre Lieblingsprodukte"
            icon={
                <TegutBadge bgColor={Colors.orange} size={HEADING_BADGE_SIZE}>
                    <TebonusSingleHeart
                        preserveAspectRatio={true}
                        size={HEADING_BADGE_SIZE * HEADING_HEART_ICON_MULTIPLIER}
                        color={Colors.white}
                    />
                </TegutBadge>
            }
            modals={getModals()}
        >
            <div className="container-md content-in-tile">
                <MultiplierWelcomeText isInitial={isInitial()} isEditable={areMultipliersEditable()} />
                <ProductTiles
                    isInitial={isInitial()}
                    isEditable={areMultipliersEditable()}
                    isOnDetailsPage={IS_ON_DETAILS_PAGE}
                    isLoading={articleMultipliers.isLoading}
                    amounts={articleMultipliers.values}
                    changedMultipliers={changedMultipliers}
                    openArticleSelection={i => openArticleSelection(i)}
                />
                <Footer
                    isLoading={articleMultipliers.isLoading}
                    isInitial={isInitial()}
                    isEditable={areMultipliersEditable()}
                    chosenArticleAmount={calculateChosenArticleAmount()}
                    daysChooseable={calculateTimeUntilNewChoice(articleMultipliers.nextChangeDate)}
                    arePromotionsCollisionsFound={articleMultipliers.arePromotionsCollisionsFound}
                    changedMultipliers={changedMultipliers}
                    collidedMultiplierTans={articleMultipliers.collidedMultiplierTans}
                    numberOfMultipliers={getNumberOfMultipliersInSessionStorage()}
                    onSaveButtonClicked={onSaveButtonClicked}
                />
            </div>
        </PageBase>
    );
};

export default ArticleMultipliers;
