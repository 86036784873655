import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import { CustomerState } from "store/Customers";
import { EmailSentStateModal } from "./EmailSentStateModal";
import React, { FunctionComponent, ReactElement } from "react";

interface RegistrationConfirmationModalsProps {
    isSendingConfirmation: boolean;
    isEmailSentStateVisible: boolean;
    customerState: CustomerState;
    storageKey: string;
    setIsEmailSentStateVisible: (value: boolean) => void;
}

export const RegistrationConfirmationModals: FunctionComponent<RegistrationConfirmationModalsProps> = (props): ReactElement => {
    return (
        <>
            {props.isSendingConfirmation ? (
                <ModalConfirmInfo bodyText="Bestätigungsmail wird angefordert." showLoadingIndicator={true} />
            ) : (
                <EmailSentStateModal
                    isVisible={props.isEmailSentStateVisible}
                    customerState={props.customerState}
                    storageKey={props.storageKey}
                    setIsVisible={props.setIsEmailSentStateVisible}
                />
            )}
        </>
    );
};
