import { TebonusFilterIcon } from "components/_graphics";
import { AppState } from "store/configureStore";
import { Colors } from "styles";
import { ArticleCategory, SearchProps } from "types";
import SearchFilterDropDowns from "./SearchFilterDropDowns";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

interface ArticleSearchFilterProps {
    searchTerm: string;
    selectedBrands: SearchProps[];
    setSelectedBrands: (SearchProps) => void;
    selectedCategories: SearchProps[];
    setSelectedCategories: (SearchProps) => void;
    allCategories: SearchProps[];
    setAllCategories: (SearchProps) => void;
    isFilterModalOpen: boolean;
    setIsFilterModalOpen: (value: boolean) => void;
}

export const ArticleSearchFilter: React.FunctionComponent<ArticleSearchFilterProps> = props => {
    const articleCategoriesReducer = useSelector((state: AppState) => state.articleCategoriesReducer);

    useEffect(() => {
        getCategoryNamesFirstLevel(articleCategoriesReducer.categories);
    }, []);

    /**
     * Get all categories from the first level
     * @param categories
     */
    const getCategoryNamesFirstLevel = (categories: ArticleCategory[]): void => {
        const allCategories: SearchProps[] = [];
        categories.map(category => allCategories.push({ id: category.tegutCategoryId, name: category.categoryName }));
        props.setAllCategories(allCategories);
    };

    return (
        <>
            {/* Desktop version */}
            <div className="d-none d-md-block">
                <SearchFilterDropDowns
                    selectedBrands={props.selectedBrands}
                    selectedCategories={props.selectedCategories}
                    setSelectedBrands={props.setSelectedBrands}
                    setSelectedCategories={props.setSelectedCategories}
                    allCategories={props.allCategories}
                    searchTerm={props.searchTerm}
                />
            </div>

            {/* Mobile version */}
            <div className="d-md-none d-flex w-100 align-items-center justify-content-center">
                <button
                    className="btn font-orange font-unitText-Bold d-flex align-items-center justify-content-center p-1"
                    onClick={() => props.setIsFilterModalOpen(!props.isFilterModalOpen)}
                >
                    <div className="mr-2">
                        <TebonusFilterIcon htmlColor={Colors.orange} />
                    </div>
                    <div>Suchergebnisse filtern</div>
                </button>
            </div>
        </>
    );
};

export default ArticleSearchFilter;
