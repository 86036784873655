import LoadingIndicator from "components/LoadingIndicator";
import { TegutBadge } from "components/_tegut/TegutBadge";
import { Colors } from "styles";
import { SearchProps } from "types";
import CheckIcon from "@material-ui/icons/Check";
import CloseSharp from "@material-ui/icons/CloseSharp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useRef, useState } from "react";

interface DropDownMenuProps {
    title: string;
    options: SearchProps[];
    selectedItems: SearchProps[];
    isLoading?: boolean;
    hasSearchBar?: boolean;
    searchPlaceholder?: string;
    setSelectedItems: (value: SearchProps[]) => void;
    onOpen?: () => void;
    onClose?: () => void;
    onScrollEnd?: () => void;
    onSearchTermChanged?: (value: string) => void;
    // number of height/ width or css string
    height: number | string;
    width: number | string;
}

/**
 * Displays a drop down menu with the tegut style.
 * It needs bootstrap to work.
 * @param props
 * @returns
 */
export const DropDownMenu: React.FunctionComponent<DropDownMenuProps> = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleMouseEvent);
        document.addEventListener("wheel", handleMouseEvent);

        const expandedContent = document.getElementById("expanded-content");
        expandedContent.addEventListener("scrollend", () =>
            handleScrollEndEvent(expandedContent.offsetHeight, expandedContent.scrollHeight, expandedContent.scrollTop)
        );

        return () => {
            document.removeEventListener("mousedown", handleMouseEvent);
            document.removeEventListener("wheel", handleMouseEvent);

            expandedContent.removeEventListener("scrollend", () =>
                handleScrollEndEvent(expandedContent.offsetHeight, expandedContent.scrollHeight, expandedContent.scrollTop)
            );
        };
    }, []);

    useEffect(() => {
        if (!isExpanded) {
            setSearchTerm("");
        }
    }, [isExpanded]);

    // #region functions

    const handleMouseEvent = (event: MouseEvent): void => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            setIsExpanded(false);
        }
    };

    const handleScrollEndEvent = (offsetHeight: number, scrollTop: number, scrollHeight: number): void => {
        if (props.onScrollEnd && offsetHeight + scrollTop >= scrollHeight) {
            props.onScrollEnd();
        }
    };

    const setIsDropDownExpanded = (): void => {
        setIsExpanded(!isExpanded);
        return !isExpanded ? props.onOpen() : props.onClose();
    };

    /**
     * Reset the list with the checked items
     */
    const removeAllSelectedItems = (): void => {
        if (props.selectedItems.length > 0) {
            props.setSelectedItems([]);
        }

        props.onClose();
    };

    /**
     * Add or remove the item from the list depending on if it is selected or not
     * @param index
     */
    const updateSelectedItems = (item: SearchProps): void => {
        if (isChecked(item)) {
            // remove item from list
            props.setSelectedItems(props.selectedItems.filter(i => i.id !== item.id));
        } else {
            // add item to list
            props.setSelectedItems([...props.selectedItems, item]);
        }
    };

    /**
     * Check if the item was selected in the drop down menu
     * @param item
     * @returns
     */
    const isChecked = (item: SearchProps): boolean => {
        return props.selectedItems.find(i => i.id === item.id) ? true : false;
    };

    // #endregion

    return (
        <div
            ref={ref}
            className="d-flex justify-content-center align-items-start"
            style={{ position: "relative", width: props.width, height: props.height }}
        >
            <div className="d-flex flex-column align-items-start dropDownContainer" style={{ position: "absolute", zIndex: 5 }}>
                <div
                    className="d-flex align-items-center cursor-pointer w-100 px-1"
                    onClick={() => setIsDropDownExpanded()}
                    style={{ height: props.height }}
                    aria-label={isExpanded ? "Schließen" : "Erweitern"}
                >
                    <div className={`px-2 font-unitText-Bold ${isExpanded && "font-orange"}`}>{props.title}</div>
                    <button className="ml-auto btn p-0" aria-label="Erweitern">
                        <ExpandMoreIcon className="icon-accent-wrapper icon-accent-orange" />
                    </button>
                </div>

                {/* Expanded content */}
                <div
                    id="expanded-content"
                    className={`px-1 ${isExpanded ? "d-flex" : "d-none"} flex-column table-scroll-bar overflow-auto w-100`}
                    style={{ maxHeight: "50vh" }}
                >
                    {props.hasSearchBar && (
                        <div className="search-bar-wrapper" style={{ width: "100%" }}>
                            <SearchIcon className="search-bar-icon-visible" />
                            <input
                                className="search-input font-unitText-Regular font-dimGray"
                                type="text"
                                value={searchTerm}
                                onChange={event => {
                                    setSearchTerm(event.target.value);
                                    props.onSearchTermChanged(event.target.value);
                                }}
                                placeholder={props.searchPlaceholder}
                                aria-label={props.searchPlaceholder}
                            />
                        </div>
                    )}

                    {/* Reset items */}
                    <button className="d-flex my-1 btn p-0 w-100 align-items-center" onClick={() => removeAllSelectedItems()}>
                        <div className="dropDownIconSpace">
                            <CloseSharp style={{ color: Colors.dimGray, fontSize: "1.25rem" }}></CloseSharp>
                        </div>
                        <div className="dropDownText">zurücksetzen</div>
                    </button>

                    {/* Options */}
                    {props.options.map((option, index) => {
                        return (
                            <button
                                key={index}
                                className="btn p-0 d-flex mb-1 w-100 align-items-center"
                                onClick={() => updateSelectedItems(option)}
                            >
                                <div className="dropDownIconSpace">
                                    {isChecked(option) ? (
                                        <TegutBadge className="dropDownBadge" bgColor={`${Colors.seaweedGreen}`}>
                                            <CheckIcon className="dropDownBadgeIcon" style={{ color: Colors.white }} />
                                        </TegutBadge>
                                    ) : (
                                        <TegutBadge className="dropDownBadge" bgColor={`${Colors.white}`}></TegutBadge>
                                    )}
                                </div>
                                <div className="dropDownText">{option.name}</div>
                            </button>
                        );
                    })}
                    {props.isLoading && <LoadingIndicator isPositionStatic={true} />}
                </div>
            </div>
        </div>
    );
};

export default DropDownMenu;
