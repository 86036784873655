import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import { actionCreators } from "store";
import { AppState } from "store/configureStore";
import { AllReduxActions } from "types";
import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const terminationReceived = "terminationReceived";

interface TerminateTebonusProps {
    setTerminateButtonPressed: (value: boolean) => void;
    isTerminateButtonPressed: boolean;
    logout: () => void;
}

const TerminateTebonus: React.FC<TerminateTebonusProps> = props => {
    enum TerminationModals {
        none,
        termination,
        terminationSuccess,
        terminationFail,
        terminationLoading,
    }

    const dispatch = useDispatch();
    const [terminationModals, setTerminationModals] = useState<TerminationModals>(TerminationModals.none);
    const customerState = useSelector((state: AppState) => state.customerReducer);

    // isModalClosed needs to be true that the failed oder successfull pop up can be shown.
    // if this flag is not used the failed and successfull pop up will be shown again when it was closed and the page will be refreshed.
    const [isModalClosed, setIsModalClosed] = useState<boolean>(false);

    // logout the user when the termination was received
    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem(terminationReceived))) {
            props.logout();
        }
    }, []);

    useEffect(() => {
        // check if the terminate tebonus button was pressed. If this is the case open the modal and reset the flag.
        if (props.isTerminateButtonPressed) {
            setTerminationModals(TerminationModals.termination);
            props.setTerminateButtonPressed(false);
        }
    }, [props.isTerminateButtonPressed]);

    // if the customer has changed check for the last performed action. If
    useEffect(() => {
        // activate successed modal
        if (customerState.performedAction === AllReduxActions.TERMINATE_TEBONUS_CUSTOMER_SUCCESS && isModalClosed) {
            setTerminationModals(TerminationModals.terminationSuccess);
            setIsModalClosed(false);
            sessionStorage.setItem(terminationReceived, JSON.stringify(true));
            return;
        }
        // activate failed modal
        if (customerState.performedAction === AllReduxActions.TERMINATE_TEBONUS_CUSTOMER_FAIL && isModalClosed) {
            setTerminationModals(TerminationModals.terminationFail);
            setIsModalClosed(false);
            return;
        }
        // activate loading modal
        if (customerState.isDeleting) {
            setTerminationModals(TerminationModals.terminationLoading);
            return;
        }
    }, [customerState.isDeleting]);

    function onTerminationConfirmed(): void {
        logAnalyticsButton({ button_title: CommonButtonTitles.termination.confirmTerminateCustomer });

        dispatch(actionCreators.customers.deleteAccount());
        setTerminationModals(TerminationModals.none);
        setIsModalClosed(true);
    }

    function onTerminationCanceled(): void {
        logAnalyticsButton({ button_title: CommonButtonTitles.termination.cancelTerminateCustomer });

        setTerminationModals(TerminationModals.none);
        setIsModalClosed(false);
    }

    return (
        <>
            {terminationModals === TerminationModals.termination && (
                <ModalConfirmInfo
                    isTerminationModal
                    titleText="Möchten Sie Ihre tebonus Teilnahme kündigen?"
                    bodyText={
                        <>
                            <p>
                                {`Mit Klick auf "tebonus Teilnahme kündigen" beenden Sie Ihre Teilnahme am tebonus Programm. Bitte beachten Sie,
                dass Sie sich mit unserer Kündigungsbestätigung nicht mehr auf der tebonus Website oder in Ihre tebonus App
                einloggen und die mit tebonus verbunden Dienste nutzen können. Ab dann können Sie auch Ihre tebonus Karte/n
                nicht mehr einsetzen.`}
                            </p>
                            <p>
                                Sofern Sie mit Ihrem tegut…- Konto weitere tegut…&shy; Services aktiviert haben, können Sie
                                diese wie gewohnt nutzen.
                            </p>
                        </>
                    }
                    button1Text="tebonus Teilnahme kündigen"
                    button2Text="Abbrechen"
                    button1function={onTerminationConfirmed}
                    button2function={onTerminationCanceled}
                ></ModalConfirmInfo>
            )}

            {/* Termination received (successful)*/}
            {terminationModals === TerminationModals.terminationSuccess && (
                <ModalConfirmInfo
                    titleText="Kündigung erhalten"
                    bodyText={
                        <p>
                            Wir haben Ihre Kündigung für das tebonus Programm erhalten. Eine schriftliche Bestätigung Ihrer
                            Kündigung erhalten Sie an die uns von Ihnen überlasse E-Mail-Adresse. Alle aufgrund Ihrer Kündigung
                            nicht mehr benötigten Daten werden von uns gelöscht, sofern diese keinen gesetzlichen
                            Aufbewahrungsfristen unterliegen.
                        </p>
                    }
                    button1Text="Schließen"
                    button1function={() => {
                        setTerminationModals(TerminationModals.none);
                        setIsModalClosed(false);
                        // logout and delete all informations
                        props.logout();
                    }}
                ></ModalConfirmInfo>
            )}

            {/* Termination failed */}
            {terminationModals === TerminationModals.terminationFail && (
                <ModalConfirmInfo
                    titleText="Etwas ist schiefgelaufen"
                    bodyText={
                        <p>
                            Beim Absenden Ihrer Kündigung ist leider etwas schiefgelaufen. Bitte versuchen Sie es zu einem
                            späteren Zeitpunkt noch einmal oder wenden sich an unsere Kundenbetreuung.
                        </p>
                    }
                    button1Text="Kontaktformular öffnen"
                    button1function={() => window.open("https://www.tegut.com/kontakt.html", "_blank", "noopener, noreferrer")}
                    button2Text="Abbrechen"
                    button2function={() => {
                        setTerminationModals(TerminationModals.none);
                        setIsModalClosed(false);
                    }}
                ></ModalConfirmInfo>
            )}

            {/* Loading */}
            {terminationModals === TerminationModals.terminationLoading && (
                <ModalConfirmInfo showLoadingIndicator={true}></ModalConfirmInfo>
            )}
        </>
    );
};

export default TerminateTebonus;
