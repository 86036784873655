import { isSubMenuItemHidden } from "utils/CardSubMenu";
import { useAppSelector } from "utils/hooks";
import { AppState } from "store/configureStore";
import { CardOverviewState } from "store/CardOverview";
import { Colors } from "../../styles";
import { TebonusLogo } from "../_graphics";
import * as NavLinks from "../../navigation";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import React, { FunctionComponent } from "react";
import CloseSharp from "@material-ui/icons/CloseSharp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink, Link } from "react-router-dom";

dayjs.extend(utc);

// Separate navigation bar which will be shown only on small screens.
// A separate navigation bar was used, because of the large difference to the desktop version.

interface NavBarMobileProps {
    login: () => void;
    logout: () => void;
    isAuthenticated: boolean;
    isCustomerRegistrationConfirmed?: boolean;
    customerRegistrationDate?: string;
    isConfirmationMailSent?: boolean;
}

enum SubmenuType {
    MYTEBONUS = 0,
    MYACCOUNT = 1,
    USEFUL = 2,
}

const NavBarMobile: FunctionComponent<NavBarMobileProps> = props => {
    // to open and close the menu a state function is used. Because NavLink does not reload the whole page by clicking.
    // So the navigation bar would not collapse.
    const [isMenuCollapsed, setIsMenuCollapsed] = React.useState<boolean>(true);
    const [openSubmenu, setOpenSubmenu] = React.useState<SubmenuType>(undefined);

    const cardOverviewState = useAppSelector((state: AppState) => state.cardOverviewReducer) as CardOverviewState;

    const closeMenu = (): void => {
        setIsMenuCollapsed(true);
        setOpenSubmenu(undefined);
    };
    const openMenu = (): void => {
        setIsMenuCollapsed(false);
        setOpenSubmenu(undefined);
    };

    // 26 hours after registration date. (registrationDate is in UTC)
    const registrationDateOffset = dayjs.utc(props.customerRegistrationDate).add(26, "hours");

    // currentDate in UTC format, only the format is UTC, the actual timestamp is in local date.
    // (UTC format necessary for the comparison).
    const isCurrentDateAfterRegistrationDate = dayjs().utcOffset(0, true).isAfter(registrationDateOffset);
    const isRenderIndicatorBubble = isCurrentDateAfterRegistrationDate && !props.isConfirmationMailSent;

    /**
     * returns the account nav link items without "RegistrationConfirmed" item, if the email has been confirmed
     * otherwise returns all account nav links.
     * @type {*} */
    const filteredAccountSubMenu = NavLinks.linksSubMenu_Account.filter(item =>
        props.isCustomerRegistrationConfirmed ? item.title !== "Registrierungsbestätigung" : item
    );

    const submenuClickEvent = (menu: SubmenuType): void => {
        if (menu === openSubmenu) {
            setOpenSubmenu(undefined);
        } else {
            setOpenSubmenu(menu);
        }
    };

    const renderNavbarMobileHeader = (submenuType: SubmenuType): React.JSX.Element => {
        return (
            <div
                tabIndex={0}
                onKeyUp={e => {
                    if (e.key === "Enter") {
                        submenuClickEvent(submenuType);
                    }
                }}
                aria-label="Erweitern"
            >
                <div
                    className="nav-link nav-link-title cursor-pointer"
                    onClick={() => {
                        submenuClickEvent(submenuType);
                    }}
                >
                    {NavLinks.linksMenu[submenuType].title}
                    <ExpandMoreIcon style={{ transform: openSubmenu === submenuType ? "rotate(180deg)" : "" }} />
                </div>
            </div>
        );
    };

    return (
        //    {/* d-sm-none: hide on screens wider than sm */}
        <nav className="sticky-top navbar bg-yellow py-3 d-lg-none">
            <div className="container-lg">
                {/* Logo */}
                <Link to="/" aria-label="zur tebonus Startseite">
                    <TebonusLogo width="100%" height={30} />
                </Link>

                {props.isAuthenticated && (
                    <>
                        {/* Toggle button */}
                        <button className="navbar-toggler p-0" type="button" onClick={openMenu} aria-label="Menü öffnen">
                            <div style={{ color: Colors.white }}>
                                <MenuIcon fontSize="large" />
                            </div>
                        </button>

                        {/* submenu which is collapsed*/}
                        <div className={`fixed-top bg-white shadow ${isMenuCollapsed ? "collapse" : "show"}`}>
                            <div className="container-xl w-100 my-0">
                                {/* Close button for opened menu */}
                                <button
                                    className="navbar-toggler ml-auto"
                                    type="button"
                                    onClick={closeMenu}
                                    aria-label="Menü schließen"
                                >
                                    <div className="nav-link-title">
                                        <CloseSharp />
                                    </div>
                                </button>
                            </div>

                            <div className="container w-75">
                                <ul className="nav flex-column w-100" id="collapse-parent-nav-main">
                                    {/* Mein tebonus*/}
                                    <li className="nav-item nav-border">
                                        {renderNavbarMobileHeader(SubmenuType.MYTEBONUS)}
                                        {openSubmenu === SubmenuType.MYTEBONUS && (
                                            <div>
                                                {/* map over list and display each link if objects are in the list */}
                                                {NavLinks.linksSubMenu_MyTebonus.length > 0 && (
                                                    <ul className="nav nav-sub flex-column w-100">
                                                        {NavLinks.linksSubMenu_MyTebonus.map((item, i) => (
                                                            <li
                                                                className="nav-item"
                                                                key={i}
                                                                hidden={isSubMenuItemHidden(
                                                                    item.title,
                                                                    item.hiddenInMenu,
                                                                    cardOverviewState.cards
                                                                )}
                                                            >
                                                                <NavLink
                                                                    to={item.htmlLink}
                                                                    onClick={closeMenu}
                                                                    className={`nav-link nav-link-sub-title ${
                                                                        location.pathname == "/ArticleFinder" &&
                                                                        item.htmlLink == "/meintebonus/Lieblingsprodukte"
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                >
                                                                    <span>{item.title}</span>
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </li>

                                    {/* Mein tegut...Konto*/}
                                    <li className="nav-item nav-border">
                                        {renderNavbarMobileHeader(SubmenuType.MYACCOUNT)}
                                        {openSubmenu === SubmenuType.MYACCOUNT && (
                                            <div>
                                                {/* submenu: Map over the list and display the menu if the list is not empty */}
                                                {filteredAccountSubMenu.length > 0 && (
                                                    <ul className="nav nav-sub flex-column">
                                                        {filteredAccountSubMenu.map((item, i) => (
                                                            <li className="nav-item" key={i} hidden={item.hiddenInMenu}>
                                                                <NavLink
                                                                    to={item.htmlLink}
                                                                    onClick={closeMenu}
                                                                    className="nav-link nav-link-sub-title"
                                                                >
                                                                    {item.title}
                                                                    {/* only render the RegistrationConfirmation  if 26 hours has passed since registration and no manual confirmation email has been triggered by the user. */}
                                                                    {item.title === "Registrierungsbestätigung" &&
                                                                        isRenderIndicatorBubble &&
                                                                        item.icon}
                                                                </NavLink>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </li>

                                    {/*Nützliches*/}
                                    <li className="nav-item nav-border ">
                                        {renderNavbarMobileHeader(SubmenuType.USEFUL)}
                                        {openSubmenu === SubmenuType.USEFUL && (
                                            <div>
                                                {/* submenu: Map over the list and display the menu if the list is not empty */}
                                                {NavLinks.linksSubMenu_Usefull.length > 0 && (
                                                    <ul className="nav nav-sub flex-column">
                                                        {NavLinks.linksSubMenu_Usefull.map((item, i) => (
                                                            <li className="nav-item" key={i} hidden={item.hiddenInMenu}>
                                                                {item.externalLink ? (
                                                                    <a
                                                                        href={item.htmlLink}
                                                                        className="nav-link nav-link-sub-title"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        onClick={() => closeMenu()}
                                                                    >
                                                                        {item.title}
                                                                    </a>
                                                                ) : (
                                                                    <NavLink
                                                                        to={item.htmlLink}
                                                                        onClick={closeMenu}
                                                                        className="nav-link nav-link-sub-title"
                                                                    >
                                                                        {item.title}
                                                                    </NavLink>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        )}
                                    </li>
                                </ul>

                                {/* Login/ Logout button */}
                                <div className="row nav-border w-100 vh-25 mx-auto">
                                    <div className="col my-auto text-center">
                                        {!props.isAuthenticated ? (
                                            <button
                                                className="btn btn-outline-orange btn-navigationBar"
                                                onClick={() => props.login()}
                                            >
                                                Login
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-outline-orange btn-navigationBar"
                                                onClick={() => props.logout()}
                                            >
                                                Logout
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavBarMobile;
