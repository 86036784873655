import ProductTile, { ProductStatus } from "components/Shared/ProductTile";
import { Promotion } from "types";
import { filterActivePromotions } from "store/MonthPromotions";
import { areTherePromotions } from "./utils";
import React, { FunctionComponent, ReactElement } from "react";

interface WeeklyHitsTilesProps {
    areWeekPromotionsLoading: boolean;
    weekPromotions: Promotion[];
}

export const WeeklyHitsTiles: FunctionComponent<WeeklyHitsTilesProps> = (props): ReactElement => {
    const NUMBER_EMPTY_TILES = 10;

    const filteredWeeklyHits = filterActivePromotions(props.weekPromotions);

    return (
        <div className="scrollScoreContainer-md table-scroll-bar overflow-y-md">
            <div className="d-flex justify-content-center flex-row flex-wrap">
                {props.areWeekPromotionsLoading &&
                    Array.from(Array(NUMBER_EMPTY_TILES).keys()).map(item => (
                        <ProductTile productStatus={ProductStatus.isEmpty} key={item} />
                    ))}
                {areTherePromotions(props.weekPromotions) ? (
                    filteredWeeklyHits.promotions.map((item: Promotion) => (
                        <ProductTile
                            productStatus={ProductStatus.isIdle}
                            promotionTitle={item.advantageDescription}
                            image={item.image}
                            advantage={item.advantage}
                            advantageAmount={item.advantageAmount}
                            key={item.tegutPromotionIdentifier}
                            showSelectionBadge={false}
                        />
                    ))
                ) : (
                    <div className="font-unitText-Regular font-dimGray text-center">
                        Derzeit sind keine Wochen-Hits eingestellt.
                    </div>
                )}
            </div>
        </div>
    );
};
