import { AppThunkAction } from "store";
import {
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    ErrorStatusCode,
    GetPromotionsResponse,
    Promotion,
    PromotionType,
    ReduxBaseType,
} from "types";
import { wasUpdatedThisWeek } from "utils/CheckLastUpdate";
import RequestStatus from "utils/RequestStatus";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";

const GET_WEEK_PROMOTIONS = "GET_WEEK_PROMOTIONS";
const GET_WEEK_PROMOTIONS_SUCCESS = "GET_WEEK_PROMOTIONS_SUCCESS";
const GET_WEEK_PROMOTIONS_FAIL = "GET_WEEK_PROMOTIONS_FAIL";

const IMAGE_MAX_SIZE = 200000;

export interface WeekPromotionsState extends BaseType, ReduxBaseType {
    weekPromotions: Promotion[];
    visitedWeeklyHitsDate: number;
}

export interface GetWeekPromotionsAction {
    type: typeof GET_WEEK_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetWeekPromotionsSuccessAction {
    type: typeof GET_WEEK_PROMOTIONS_SUCCESS;
    payload: AxiosResponse<GetPromotionsResponse>;
}

interface GetWeekPromotionsFailAction {
    type: typeof GET_WEEK_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

export type KnownAction =
    | BaseKnownAction
    | GetWeekPromotionsAction
    | GetWeekPromotionsSuccessAction
    | GetWeekPromotionsFailAction;

const INITIAL_STATE: WeekPromotionsState = {
    weekPromotions: [],
    visitedWeeklyHitsDate: 0,
    isLoading: false,
    lastUpdate: 0,
    errorCode: ErrorStatusCode.noError,
};

export const reducer: Reducer<WeekPromotionsState> = (state = INITIAL_STATE, action: KnownAction) => {
    switch (action.type) {
        case GET_WEEK_PROMOTIONS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS,
            };
        }
        case GET_WEEK_PROMOTIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS_FAIL,
            };
        }

        case GET_WEEK_PROMOTIONS_SUCCESS: {
            const { promotions } = action.payload.data;
            promotions.forEach(element => (element.image?.length > IMAGE_MAX_SIZE ? (element.image = "") : element));
            return {
                ...state,
                weekPromotions: promotions,
                lastUpdate: dayjs().unix(),
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_WEEK_PROMOTIONS_SUCCESS,
            };
        }

        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
};

export const actionCreators = {
    ...baseActionCreators,
    requestGetWeekPromotions: (): AppThunkAction<GetWeekPromotionsAction> => async (dispatch, getState) => {
        const { isLoading, lastUpdate, weekPromotions } = getState().weekPromotionReducer;

        if (!isLoading && (!wasUpdatedThisWeek(lastUpdate) || weekPromotions.length === 0)) {
            dispatch(<GetWeekPromotionsAction>{
                type: GET_WEEK_PROMOTIONS,
                payload: {
                    request: {
                        method: "GET",
                        url: `/GetPromotions/${PromotionType.Week}`,
                    },
                },
            });
        }
    },
};
