import { DateSelector } from "components/ScoreOverview/DateSelector";
import { ScoreTransactionsTable } from "components/ScoreOverview/ScoreTransactionsTable.tsx";
import { TotalScore } from "components/ScoreOverview/TotalScore";
import PageBase from "components/Shared/PageBase";
import { actionCreators } from "store";
import { CustomerScoreState } from "store/CustomerScore";
import { AppState } from "store/configureStore";
import { CustomerScoreTransaction, ErrorStatusCode } from "types";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import dayjs from "dayjs";

const ScoreOverview: FunctionComponent = (): ReactElement => {
    const dispatch = useAppDispatch();
    const customerScoreState: CustomerScoreState = useAppSelector((state: AppState) => state.customerScoreReducer);

    // Has all transactions which occoured in the selected month
    const [displayTransactionsPerMonth, setDisplayTransactionsPerMonth] = useState<CustomerScoreTransaction[]>([]);

    const [selectedYear, setSelectedYear] = useState<number>(Number.parseInt(dayjs().format("YYYY")));
    const [selectedMonth, setSelectedMonth] = useState<string>(dayjs().format("MMMM"));

    const [openedCols, setOpenedCols] = useState([]);
    const [hasLoadingError, setHasLoadingError] = useState(false);

    // onMount
    useEffect(() => {
        dispatch(actionCreators.customerScore.requestGetCustomerScore());
        dispatch(actionCreators.customerScore.requestGetCustomerScoreTransactions());
        dispatch(actionCreators.markets.getMarketsInfoByID([]));
    }, []);

    // dispatch data if the month or year selection has changed
    useEffect(() => {
        setOpenedCols([]);
        refreshDisplayedTransactions();
    }, [selectedMonth, selectedYear]);

    // Reload the monthly transaction if an error occured. If an error occured the state from setHasLoadingError becomes true.
    useEffect(() => {
        if (customerScoreState.errorCode !== ErrorStatusCode.noError && !customerScoreState.areTransactionsLoading) {
            setHasLoadingError(true);
        } else {
            setHasLoadingError(false);
        }

        refreshDisplayedTransactions();
    }, [customerScoreState]);

    const refreshDisplayedTransactions = (): void => {
        setDisplayTransactionsPerMonth([]);

        if (customerScoreState.scoreTransactions) {
            setDisplayTransactionsPerMonth(
                customerScoreState.scoreTransactions
                    .filter(
                        (t: CustomerScoreTransaction) =>
                            new Date(t.transactionDateUtc).getFullYear() === selectedYear &&
                            new Date(t.transactionDateUtc).getMonth() === dayjs.months().indexOf(selectedMonth)
                    )
                    .sort((a, b) => new Date(b.transactionDateUtc).getTime() - new Date(a.transactionDateUtc).getTime())
            );
        }
    };

    return (
        <PageBase title="Punkteübersicht">
            <div className="container-md content-in-tile">
                <TotalScore isLoading={customerScoreState.isLoading} currentScore={customerScoreState.currentScore} />
                <DateSelector
                    scoreTransactions={customerScoreState.scoreTransactions}
                    selectedYear={selectedYear}
                    selectedMonth={selectedMonth}
                    setSelectedYear={year => setSelectedYear(year)}
                    setSelectedMonth={month => setSelectedMonth(month)}
                />
                <ScoreTransactionsTable
                    displayTransactionsPerMonth={displayTransactionsPerMonth}
                    hasLoadingError={hasLoadingError}
                    areTransactionsLoading={customerScoreState.areTransactionsLoading}
                    openedCols={openedCols}
                    setOpenedCols={setOpenedCols}
                />
            </div>
        </PageBase>
    );
};

export default ScoreOverview;
