import ContentPanel from "components/News/ContentPanel";
import DashboardNewsItem from "components/News/DashboardNewsItem";
import { actionCreators } from "store";
import { NewsSlide, SliderDirection } from "types";
import { logAnalyticsSliderSwipe } from "utils/FirebaseAnalytics";
import { useAppDispatch } from "utils/hooks";
import FlyerContentBanner from "../../assets/images/Angebote_Content.png";
import FlyerLinkDescription from "../../components/News/FlyerLinkDescription";
import { DataCarousel, DataCarouselProps } from "../../components/Shared/DataCarousel";
import { TebonusArrowSize, TebonusArrowType } from "../../components/_graphics";
import { AppState } from "../../store/configureStore";
import Slider, { SwipeDirection } from "react-slick";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";

const INITIAL_NEWS_SLIDER_POSITION = 0;
const ANIMATION_SPEED = 500;
const CliCK_SPEED = ANIMATION_SPEED + 15;

const NewsDashboard: FunctionComponent = (): ReactElement => {
    const newsState = useSelector((state: AppState) => state.newsReducer);
    const newsSlider = React.createRef<Slider>();
    const [slides, setSlides] = useState<NewsSlide[]>([]);
    const [positionState, setPositionState] = useState<number>(INITIAL_NEWS_SLIDER_POSITION);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionCreators.news.requestGetNews());
        initSlides();
    }, []);

    useEffect(() => {
        initSlides();
    }, [newsState.isLoading]);

    const initSlides = (): void => {
        setSlides([
            {
                contentPanel: {
                    link: "https://www.tegut.com/angebote-produkte/angebote.html?utm_source=tbapp&utm_medium=banner&utm_campaign=&utm_term=Flugblatt&utm_content=#c66663",
                    title: "Jede Woche aufs Neue sparen!",
                    subHeader: <FlyerLinkDescription />,
                    image: FlyerContentBanner,
                },
            },
            ...newsState.news.map(n => {
                return { news: n };
            }),
        ]);
    };

    const getPrevSliderPosition = (): number => {
        return positionState === INITIAL_NEWS_SLIDER_POSITION ? slides.length - 1 : positionState - 1;
    };

    const getNextSliderPosition = (): number => {
        return positionState === slides.length - 1 ? INITIAL_NEWS_SLIDER_POSITION : positionState + 1;
    };

    const prevSliderRequested = (): void => {
        if (isButtonDisabled) {
            return;
        }

        // disable button for slide animation time to prevent double click, which breaks the view
        setIsButtonDisabled(true);
        setTimeout(setIsButtonDisabled, CliCK_SPEED, false);

        newsSlider?.current?.slickPrev();
        setPositionState(getPrevSliderPosition());

        logAnalyticsSliderSwipe({ KachelPosition: positionState, slider_direction: SliderDirection.previous });
    };

    const nextSliderRequested = (): void => {
        if (isButtonDisabled) {
            return;
        }

        // disable button for slide animation time to prevent double click, which breaks the view
        setIsButtonDisabled(true);
        setTimeout(setIsButtonDisabled, CliCK_SPEED, false);

        newsSlider?.current?.slickNext();
        setPositionState(getNextSliderPosition());

        logAnalyticsSliderSwipe({ KachelPosition: positionState, slider_direction: SliderDirection.next });
    };

    const onSwipe = (swipeDirection: SwipeDirection): void => {
        let position: number;
        let direction: SliderDirection;

        if (swipeDirection === "left") {
            position = getNextSliderPosition();
            direction = SliderDirection.next;
            newsSlider?.current?.slickNext();
        } else {
            position = getPrevSliderPosition();
            direction = SliderDirection.previous;
            newsSlider?.current?.slickPrev();
        }

        logAnalyticsSliderSwipe({ KachelPosition: positionState, slider_direction: direction });
        setPositionState(position);
    };

    // properties of the carousel
    const carouselProps = {
        arrows: false,
        infinity: true,
        customArrows: true,
        customArrowsProps: {
            arrowType: TebonusArrowType.ROUND,
            arrowSize: TebonusArrowSize.SMALL,
            className: "icon-accent-wrapper icon-accent-orange",
            prevSliderRequested,
            nextSliderRequested,
        },
        className: "w-100",
        slidesToScroll: 1,
        slidesToShowAllBreakpoints: 1,
        elementNumber: slides.length,
        tabSupportIdentifier: "news-slide-",
        animationSpeed: ANIMATION_SPEED,
        onSwipe,
    } as DataCarouselProps;

    return (
        <div className="h-100">
            {/* Only show news if there are any slides */}
            {slides && (
                <DataCarousel ref={newsSlider} {...carouselProps}>
                    {slides.map((item, index) => {
                        return item.news ? (
                            <DashboardNewsItem
                                news={item.news}
                                slideIndex={index}
                                slides={slides}
                                isActive={positionState === index}
                                key={item.news.id}
                                newsTitle={
                                    positionState === INITIAL_NEWS_SLIDER_POSITION
                                        ? "tegut... Angebote der Woche"
                                        : newsState.news[positionState - 1].title
                                }
                                position={positionState}
                            />
                        ) : (
                            item.contentPanel && (
                                <ContentPanel
                                    slideIndex={index}
                                    contentPanel={item.contentPanel}
                                    isActive={positionState === index}
                                    key={item.contentPanel.title}
                                />
                            )
                        );
                    })}
                </DataCarousel>
            )}
        </div>
    );
};

export default NewsDashboard;
