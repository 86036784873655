import dayjs from "dayjs";

const SUNDAY = 0;
const TYPO_3_UPDATE_AVAILABLE_HOUR = 4;

export function wasUpdatedToday(lastUpdate: number): boolean {
    return dayjs().isSame(dayjs.unix(lastUpdate), "date");
}

export function wasUpdatedThisWeek(lastUpdate: number): boolean {
    return dayjs().isSame(dayjs.unix(lastUpdate), "week");
}

export function isSundayAndUpdateAvailable(lastUpdate: number): boolean {
    const isSunday = dayjs().day() === SUNDAY && dayjs().hour() >= TYPO_3_UPDATE_AVAILABLE_HOUR;

    return isSunday && !wasUpdatedToday(lastUpdate);
}

export function wasUpdatedThisMonth(lastupdate: number): boolean {
    return dayjs().isSame(dayjs.unix(lastupdate), "month");
}

export function isNextChangeDateExceeded(nextChangeDate: string): boolean {
    const date = dayjs(nextChangeDate);
    const now = dayjs();

    return date && date.diff(now, "days", true) <= 0;
}
