import { PromotionProductCarousel } from "components/Promotions/Dashboard/PromotionProductCarousel";
import { DashboardPromotionsHeader } from "components/Promotions/Dashboard/DashboardPromotionsHeader";
import { MonthPromotionState } from "store/MonthPromotions";
import { DashboardPromotionsFooter } from "components/Promotions/Dashboard/DashboardPromotionsFooter";
import { useAppDispatch } from "utils/hooks";
import { actionCreators } from "store";
import { AppState } from "../../store/configureStore";
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";

const ProductPromotion: FunctionComponent = (): ReactElement => {
    const promotions: MonthPromotionState = useSelector((state: AppState) => state.monthPromotionReducer);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionCreators.monthPromotion.requestGetMonthPromotions());
    }, []);

    /**
     * Checks if the promotions have been selected.
     * @returns true if the promotions have already been selected. Otherwise false will be returned
     */
    function arePromotionsLocked(): boolean {
        return promotions.arePromotionsLocked;
    }

    return (
        <div className="d-flex flex-column h-100">
            <DashboardPromotionsHeader arePromotionsLocked={arePromotionsLocked()} isInitial={!promotions.nextChangeDate} />

            <PromotionProductCarousel
                arePromotionsLocked={arePromotionsLocked()}
                isLoading={promotions.isLoading}
                monthPromotions={promotions.monthPromotions}
            />

            <DashboardPromotionsFooter arePromotionsLocked={arePromotionsLocked()} nextChangeDate={promotions.nextChangeDate} />
        </div>
    );
};

export default ProductPromotion;
