import { useAppSelector } from "utils/hooks";
import { CardOverviewState } from "store/CardOverview";
import { AppState } from "store/configureStore";
import { isSubMenuItemHidden } from "utils/CardSubMenu";
import { TebonusLogoBackground } from "../../components/_graphics";
import * as NavLinks from "../../navigation";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import React from "react";
import { Link, NavLink } from "react-router-dom";

dayjs.extend(utc);

interface SubMenuProps {
    isCustomerRegistrationConfirmed?: boolean;
    customerRegistrationDate?: string;
    isConfirmationMailSent?: boolean;
}

const SubMenu: React.FC<SubMenuProps> = props => {
    const INDEX_MY_TEBONUS = 0;
    const INDEX_MY_ACCOUNT = 1;
    const INDEX_USEFUL = 2;

    const cardOverviewState = useAppSelector((state: AppState) => state.cardOverviewReducer) as CardOverviewState;

    // 26 hours after registration date. (registrationDate is in UTC)
    const registrationDateOffset = dayjs.utc(props.customerRegistrationDate).add(26, "hours");

    // currentDate in UTC format, only the format is UTC, the actual timestamp is in local date.
    // (UTC format necessary for the comparison).
    const isCurrentDateAfterRegistrationDate = dayjs().utcOffset(0, true).isAfter(registrationDateOffset);
    const isRenderIndicatorBubble = isCurrentDateAfterRegistrationDate && !props.isConfirmationMailSent;

    /**
     * returns the account nav link items without "RegistrationConfirmed" item, if the email has been confirmed
     * otherwise returns all account nav links.
     * @type {*} */
    const filteredAccountSubMenu = NavLinks.linksSubMenu_Account.filter(item =>
        props.isCustomerRegistrationConfirmed ? item.title !== "Registrierungsbestätigung" : item
    );

    const renderUsefulLink = (item: NavLinks.LinkInfo): React.JSX.Element => {
        if (item.externalLink) {
            return (
                <a
                    href={item.htmlLink}
                    className="nav-link-submenu nav-link-sub-title"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {item.title}
                </a>
            );
        }

        return (
            <NavLink to={item.htmlLink} className="nav-link-submenu nav-link-sub-title">
                {item.title}
            </NavLink>
        );
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="row grid-gutter-x">
                <div className="col-12">
                    <div className="firstRowTile">
                        <Link to="/" aria-label="zur tebonus Startseite">
                            <TebonusLogoBackground showLogo={false} showTebonus={true} height="100%" width="100%" />
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row grid-gutter-y pb-0 h-100">
                <div className="col-12 grid-gutter-y pb-0">
                    <div className="tile-dashboard overflow-hidden text-break text-wrap">
                        <ul className="nav d-flex flex-column mt-n2">
                            {/* mein Tebonus */}
                            <li className="nav-item">
                                <div className="nav-link-title">{NavLinks.linksMenu[INDEX_MY_TEBONUS].title}</div>

                                <ul className="nav d-flex flex-column">
                                    {NavLinks.linksSubMenu_MyTebonus.map((item, i) => (
                                        <li
                                            className="nav-item"
                                            key={i}
                                            hidden={isSubMenuItemHidden(item.title, item.hiddenInMenu, cardOverviewState.cards)}
                                        >
                                            <NavLink
                                                to={item.htmlLink}
                                                className={`nav-link-submenu nav-link-sub-title ${
                                                    location.pathname == "/ArticleFinder" &&
                                                    item.htmlLink == "/meintebonus/Lieblingsprodukte"
                                                        ? "active"
                                                        : ""
                                                }`}
                                            >
                                                {item.title}
                                            </NavLink>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {/* Mein Tegut Konto */}
                            <li className="nav-item">
                                <div className="nav-link-title">{NavLinks.linksMenu[INDEX_MY_ACCOUNT].title}</div>

                                <ul className="nav d-flex flex-column">
                                    {filteredAccountSubMenu.map((item, i) => (
                                        <div key={i} className="d-flex align-items-center">
                                            <li className="nav-item" key={i} hidden={item.hiddenInMenu}>
                                                <NavLink to={item.htmlLink} className="nav-link-submenu nav-link-sub-title">
                                                    {item.title}
                                                </NavLink>
                                            </li>
                                            {/* only render the RegistrationConfirmation  if 26 hours has passed since registration and no manual confirmation email has been triggered by the user. */}
                                            {item.title === "Registrierungsbestätigung" && isRenderIndicatorBubble && item.icon}
                                        </div>
                                    ))}
                                </ul>
                            </li>
                            {/* Nützliches */}
                            <li className="nav-item">
                                <div className="nav-link-title">{NavLinks.linksMenu[INDEX_USEFUL].title}</div>

                                <ul className="nav d-flex flex-column">
                                    {NavLinks.linksSubMenu_Usefull.map((item, i) => (
                                        <li className="nav-item" key={i} hidden={item.hiddenInMenu}>
                                            {renderUsefulLink(item)}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubMenu;
