import {
    Article,
    ArticleSearchSortingOrder,
    BaseType,
    ErrorStatusCode,
    KeyValueArrayPair,
    ReduxBaseType,
    AxiosRequestError,
} from "../types";
import RequestStatus from "../utils/RequestStatus";
import { getAppSetting } from "../utils/AppSettings";
import { AppThunkAction } from ".";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

const SEARCH_ARTICLES = "pekuma/articles/SEARCH_ARTICLES";
const SEARCH_ARTICLES_SUCCESS = "pekuma/articles/SEARCH_ARTICLES_SUCCESS";
const SEARCH_ARTICLES_FAIL = "pekuma/articles/SEARCH_ARTICLES_FAIL";

const LOAD_NEXT_PAGE = "pekuma/articles/LOAD_NEXT_PAGE";
const LOAD_NEXT_PAGE_SUCCESS = "pekuma/articles/LOAD_NEXT_PAGE_SUCCESS";
const LOAD_NEXT_PAGE_FAIL = "pekuma/articles/LOAD_NEXT_PAGE_FAIL";

const RESET_SEARCH_ARTICLES = "pekuma/articles/RESET_SEARCH_ARTICLES";

const FIND_ARTICLE_BRANDS = "pekuma/articles/FIND_ARTICLE_BRANDS";
const FIND_ARTICLE_BRANDS_SUCCESS = "pekuma/articles/FIND_ARTICLE_BRANDS_SUCCESS";
const FIND_ARTICLE_BRANDS_FAIL = "pekuma/articles/FIND_ARTICLE_BRANDS_FAIL";

const LOAD_NEXT_BRANDS = "pekuma/articles/LOAD_NEXT_BRANDS";
const LOAD_NEXT_BRANDS_SUCCESS = "pekuma/articles/LOAD_NEXT_BRANDS_SUCCESS";
const LOAD_NEXT_BRANDS_FAIL = "pekuma/articles/LOAD_NEXT_BRANDS_FAIL";

interface FindArticleBrandsRequest {
    articleTerm?: string;
    tegutCategoryIdentifier?: string;
    filteredBrandsTerm?: string;
    isTebonusable: boolean;
    isDiscountable: boolean;
    isActive: boolean;
    pageSize?: number;
    pageIndex?: number;
}

interface FindArticleBrandsResponse {
    brands: string[];
}

interface SearchArticlesAction {
    type: typeof SEARCH_ARTICLES;
    searchTerm: string;
    searchTags: KeyValueArrayPair[];
    payload: {
        request: AxiosRequestConfig;
    };
}

interface SearchArticlesSuccessAction {
    type: typeof SEARCH_ARTICLES_SUCCESS;
    payload: AxiosResponse<Article[]>;
}

interface SearchArticlesFailAction {
    type: typeof SEARCH_ARTICLES_FAIL;
    error: AxiosRequestError;
}

interface LoadNextPageAction {
    type: typeof LOAD_NEXT_PAGE;
    pageIndex: number;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface LoadNextPageSuccessAction {
    type: typeof LOAD_NEXT_PAGE_SUCCESS;
    payload: AxiosResponse<Article[]>;
}

interface LoadNextPageFailAction {
    type: typeof LOAD_NEXT_PAGE_FAIL;
    error: AxiosRequestError;
}

interface ResetSearchResultAction {
    type: typeof RESET_SEARCH_ARTICLES;
}

interface FindArticleBrandsAction {
    type: typeof FIND_ARTICLE_BRANDS;
    payload: {
        request: AxiosRequestConfig<FindArticleBrandsRequest>;
    };
}

interface FindArticleBrandsSuccessAction {
    type: typeof FIND_ARTICLE_BRANDS_SUCCESS;
    payload: AxiosResponse<FindArticleBrandsResponse>;
}

interface FindArticleBrandsFailAction {
    type: typeof FIND_ARTICLE_BRANDS_FAIL;
    error: AxiosRequestError;
}

interface LoadNextBrandsAction {
    type: typeof LOAD_NEXT_BRANDS;
    payload: {
        request: AxiosRequestConfig<FindArticleBrandsRequest>;
    };
}

interface LoadNextBrandsSuccessAction {
    type: typeof LOAD_NEXT_BRANDS_SUCCESS;
    payload: AxiosResponse<FindArticleBrandsResponse>;
}

interface LoadNextBrandsFailAction {
    type: typeof LOAD_NEXT_BRANDS_FAIL;
    error: AxiosRequestError;
}

export interface ArticleSearchState extends BaseType, ReduxBaseType {
    searchTerm: string;
    searchTags: KeyValueArrayPair[];
    currentPage: number;
    searchResults: Article[];
    brands: string[];
    brandsSearchTerm: string;
    filteredBrandsSearchTerm: string;
    categoryId: string;
    currentBrandsPage: number;
}

export type KnownAction =
    | SearchArticlesAction
    | SearchArticlesSuccessAction
    | SearchArticlesFailAction
    | LoadNextPageAction
    | LoadNextPageSuccessAction
    | LoadNextPageFailAction
    | ResetSearchResultAction
    | FindArticleBrandsAction
    | FindArticleBrandsSuccessAction
    | FindArticleBrandsFailAction
    | LoadNextBrandsAction
    | LoadNextBrandsSuccessAction
    | LoadNextBrandsFailAction;

export type SuccessAction = SearchArticlesSuccessAction | LoadNextPageSuccessAction;

export type ErrorAction = SearchArticlesFailAction | LoadNextPageFailAction;

const initialResults = [];

const INITIAL_STATE: ArticleSearchState = {
    searchTerm: "",
    searchTags: [],
    currentPage: 0,
    searchResults: initialResults,
    brands: [],
    brandsSearchTerm: "",
    filteredBrandsSearchTerm: "",
    categoryId: "",
    currentBrandsPage: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    dontPersist: true,
};

// Reducer
export const reducer: Reducer<ArticleSearchState> = (state = INITIAL_STATE, action: KnownAction): ArticleSearchState => {
    switch (action.type) {
        case SEARCH_ARTICLES: {
            const { searchTags, searchTerm } = action;

            return {
                ...state,
                isLoading: true,
                searchTerm,
                searchTags,
                currentPage: 0,
                searchResults: initialResults,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case SEARCH_ARTICLES_FAIL: {
            if (RequestStatus.isCancelled(action.error)) {
                return state;
            }
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                dontPersist: true,
            };
        }
        case SEARCH_ARTICLES_SUCCESS: {
            const searchResults = action.payload.data;
            return {
                ...state,
                isLoading: false,
                searchResults,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE: {
            return {
                ...state,
                isLoading: true,
                currentPage: action.pageIndex,
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE_FAIL: {
            if (RequestStatus.isCancelled(action.error)) {
                return state;
            }

            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                dontPersist: true,
            };
        }
        case LOAD_NEXT_PAGE_SUCCESS: {
            const searchResults = action.payload.data;
            return {
                ...state,
                isLoading: false,
                searchResults: [...state.searchResults, ...searchResults],
                errorCode: ErrorStatusCode.noError,
                dontPersist: true,
            };
        }
        case RESET_SEARCH_ARTICLES: {
            return {
                ...state,
                searchResults: initialResults,
            };
        }
        case FIND_ARTICLE_BRANDS: {
            const { articleTerm, filteredBrandsTerm, tegutCategoryIdentifier, pageIndex } = action.payload.request.data;
            return {
                ...state,
                currentBrandsPage: pageIndex,
                brandsSearchTerm: articleTerm,
                filteredBrandsSearchTerm: filteredBrandsTerm,
                categoryId: tegutCategoryIdentifier,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
            };
        }
        case FIND_ARTICLE_BRANDS_SUCCESS: {
            return {
                ...state,
                brands: action.payload.data.brands,
                isLoading: false,
            };
        }
        case FIND_ARTICLE_BRANDS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
            };
        }
        case LOAD_NEXT_BRANDS: {
            const { pageIndex } = action.payload.request.data;
            return {
                ...state,
                currentBrandsPage: pageIndex,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
            };
        }
        case LOAD_NEXT_BRANDS_SUCCESS: {
            const { brands } = action.payload.data;
            return {
                ...state,
                brands: [...state.brands, ...brands],
                isLoading: false,
            };
        }
        case LOAD_NEXT_BRANDS_FAIL: {
            return {
                ...state,
                currentBrandsPage: state.currentBrandsPage - 1,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    searchArticle:
        (searchTerm: string, searchTags: KeyValueArrayPair[]): AppThunkAction<KnownAction, Promise<void>> =>
        dispatch => {
            return dispatch(<SearchArticlesAction>{
                type: SEARCH_ARTICLES,
                searchTerm,
                searchTags,
                payload: {
                    request: {
                        method: "POST",
                        url: `${REACT_APP_DATASERVICE_URL}/FindArticles`,
                        data: {
                            terms: searchTerm ? [searchTerm] : [],
                            tags: searchTags,
                            pageIndex: 0,
                            pageSize: 25,
                            orderByName: ArticleSearchSortingOrder.ASC,
                            isTebonusable: true,
                            isDiscountable: true,
                            isActive: true,
                        },
                    },
                },
            });
        },
    loadNextPage: (): AppThunkAction<KnownAction, Promise<void>> => (dispatch, getState) => {
        const { articleSearchReducer } = getState();
        const { searchTerm, searchTags } = articleSearchReducer;
        const pageIndex = articleSearchReducer.currentPage + 1;

        return dispatch(<LoadNextPageAction>{
            type: LOAD_NEXT_PAGE,
            pageIndex,
            payload: {
                request: {
                    method: "POST",
                    url: `${REACT_APP_DATASERVICE_URL}/FindArticles`,
                    data: {
                        terms: searchTerm ? [searchTerm] : [],
                        tags: searchTags,
                        pageIndex,
                        pageSize: 25,
                        orderByName: ArticleSearchSortingOrder.ASC,
                        isTebonusable: true,
                        isDiscountable: true,
                        isActive: true,
                    },
                },
            },
        });
    },
    resetSearchResult: (): AppThunkAction<KnownAction, Promise<void>> => dispatch => {
        return dispatch(<ResetSearchResultAction>{
            type: RESET_SEARCH_ARTICLES,
        });
    },
    findArticleBrands: (
        articleTerm?: string,
        tegutCategoryIdentifier?: string,
        filteredBrandsTerm?: string
    ): FindArticleBrandsAction =>
        <FindArticleBrandsAction>{
            type: FIND_ARTICLE_BRANDS,
            payload: {
                request: {
                    method: "POST",
                    url: `${REACT_APP_DATASERVICE_URL}/FindArticleBrands`,
                    data: {
                        articleTerm,
                        tegutCategoryIdentifier,
                        filteredBrandsTerm,
                        isTebonusable: true,
                        isDiscountable: true,
                        isActive: true,
                        pageIndex: 0,
                    },
                },
            },
        },
    loadNextBrands: (): AppThunkAction<LoadNextBrandsAction, void> => async (dispatch, getState) => {
        const { articleSearchReducer } = getState();
        const { currentBrandsPage, categoryId, brandsSearchTerm, filteredBrandsSearchTerm, isLoading } = articleSearchReducer;
        if (!isLoading) {
            dispatch(<LoadNextBrandsAction>{
                type: LOAD_NEXT_BRANDS,
                payload: {
                    request: {
                        method: "POST",
                        url: `${REACT_APP_DATASERVICE_URL}/FindArticleBrands`,
                        data: {
                            articleTerm: brandsSearchTerm,
                            filteredBrandsTerm: filteredBrandsSearchTerm,
                            tegutCategoryIdentifier: categoryId,
                            isTebonusable: true,
                            isDiscountable: true,
                            isActive: true,
                            pageIndex: currentBrandsPage + 1,
                        },
                    },
                },
            });
        }
    },
};

export type ArticleSearchActions = typeof actionCreators;
