import { MultiplierBaseProps } from "types";
import { calculateTimeUntilNewChoice } from "utils/DateTimeUtils";
import React, { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";

interface MultiplierDashboardFooterProps extends MultiplierBaseProps {
    nextChangeDate: string;
}

const MultiplierDashboardFooter: FunctionComponent<MultiplierDashboardFooterProps> = (props): ReactElement => {
    function getFooterText(): string {
        if (props.isInitial) {
            return "0 von 3 ausgewählt";
        }
        if (!props.isEditable) {
            const days = calculateTimeUntilNewChoice(props.nextChangeDate);
            return `Neue Auswahl ${days > 1 ? `in ${days} Tagen` : "morgen"} möglich`;
        }
        return "0 von 3 geändert";
    }

    function getButtonText(): string {
        if (props.isLoading) {
            return "Produkte auswählen";
        }

        if (!props.isInitial && props.isEditable) {
            return "Produkte ändern";
        }

        if (props.isInitial || props.isEditable) {
            return "Produkte auswählen";
        }

        return "Produkte ansehen";
    }

    return (
        <div className="d-flex flex-column mt-auto align-items-center">
            {/* notice text and button */}
            <div className="d-flex justify-content-center dashboard-tile-footer pb-2 pt-4">{getFooterText()}</div>
            <Link to="/meintebonus/Lieblingsprodukte" tabIndex={-1}>
                <button
                    tabIndex={0}
                    className={!props.isEditable ? "btn btn-outline-orange btn-select" : "btn btn-orange btn-select"}
                >
                    {getButtonText()}
                </button>
            </Link>
        </div>
    );
};

export default MultiplierDashboardFooter;
