import { AxiosRequestError, ReduxBaseType, BaseType, ErrorStatusCode, FaqList, FaqRequest, AllReduxActions } from "types";
import RequestStatus from "utils/RequestStatus";
import { compareOrderPosition } from "utils/SortHelper";
import { AppThunkAction } from "store";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const GET_FAQS = "Pekuma/faqs/GET_FAQ";
const GET_FAQS_SUCCESS = "Pekuma/faqs/GET_FAQ_SUCCESS";
const GET_FAQS_FAIL = "Pekuma/faqs/GET_FAQ_FAIL";

interface GetFaqsResponse {
    faqs: FaqList[];
    lastUpdate: number;
}

export interface GetFaqsAction {
    type: typeof GET_FAQS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetFaqsSuccessAction {
    type: typeof GET_FAQS_SUCCESS;
    payload: AxiosResponse<GetFaqsResponse>;
}

interface GetFaqsFailAction {
    type: typeof GET_FAQS_FAIL;
    error: AxiosRequestError;
}

export interface FaqsState extends BaseType, ReduxBaseType {
    faqs: FaqList[];
    lastUpdate: number;
}

export type KnownAction = BaseKnownAction | GetFaqsAction | GetFaqsFailAction | GetFaqsSuccessAction;

export type ErrorAction = GetFaqsFailAction;

export const INITIAL_STATE: FaqsState = {
    faqs: [],
    lastUpdate: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    performedAction: null,
};

// Reducer
export const reducer: Reducer<FaqsState> = (state = INITIAL_STATE, action: KnownAction): FaqsState => {
    switch (action.type) {
        case GET_FAQS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_FAQS,
            };
        }
        case GET_FAQS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_FAQS_FAIL,
            };
        }
        case GET_FAQS_SUCCESS: {
            const { faqs, lastUpdate } = action.payload.data;

            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                faqs: faqs ? faqs.sort((a, b) => compareOrderPosition(a.key, b.key)) : [],
                lastUpdate,
                performedAction: AllReduxActions.GET_FAQS_SUCCESS,
            };
        }
        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    ...baseActionCreators,
    requestGetAllFaqs:
        (filter: FaqRequest): AppThunkAction<GetFaqsAction, void> =>
        async (dispatch, getState) => {
            const { lastUpdate, isLoading } = getState().faqsReducer as FaqsState;

            if (lastUpdate === 0 && !isLoading) {
                dispatch(<GetFaqsAction>{
                    type: GET_FAQS,
                    payload: {
                        request: {
                            method: "GET",
                            url: "/GetAllFaqs",
                            params: filter,
                        },
                    },
                });
            }
        },
};

export type FaqActions = typeof actionCreators;
