import { TebonusSingleHeart } from "components/_graphics";
import { TegutBadge } from "components/_tegut/TegutBadge";
import { Colors } from "styles";
import { MultiplierBaseProps } from "types";
import React, { FunctionComponent, ReactElement } from "react";

const MultiplierDashboardHeader: FunctionComponent<MultiplierBaseProps> = (props): ReactElement => {
    function renderDescriptionText(): React.JSX.Element {
        if (props.isInitial) {
            return (
                <>
                    Legen Sie Ihre Lieblingsprodukte fest, mit denen Sie{" "}
                    <span className="font-unitText-Bold">diesen Monat</span> mehrfach punkten:{" "}
                    <span className="font-unitText-Bold">20-fach, 10-fach und 5-fach</span>.
                </>
            );
        } else if (props.isEditable) {
            return (
                <>
                    Es ist wieder soweit! Sie können <span className="font-unitText-Bold">neue Lieblingsprodukte wählen</span>{" "}
                    oder Ihre derzeitigen Produkte beibehalten.
                </>
            );
        }
        return (
            <>
                Sie haben Ihre persönlichen Lieblingsprodukte <span className="font-unitText-Bold">erfolgreich ausgewählt</span>
                . Viel Freude beim Mehrfachpunkten!
            </>
        );
    }

    return (
        <>
            <div className="d-flex flex-md-row flex-column justify-content-center justify-content-lg-start align-items-center mb-2 mb-md-1">
                <TegutBadge bgColor={Colors.orange} className="dashboard-icon-small">
                    <TebonusSingleHeart preserveAspectRatio={true} color={Colors.white} />
                </TegutBadge>
                <span className="dashboard-tile-title pl-1 ml-md-2">Lieblingsprodukte</span>
            </div>
            <div className="text-justify text-center dashboard-tile-subheader mb-3 mb-md-0">{renderDescriptionText()}</div>
        </>
    );
};

export default MultiplierDashboardHeader;
