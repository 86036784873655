import CustomLeavePagePrompt from "components/_overlays/CustomLeavePagePrompt";
import React, { FunctionComponent, ReactElement } from "react";
import { useBlocker } from "react-router-dom";

interface LeavePageModalProps {
    shownWhen: boolean;
    discardButtonTitle: string;
    returnButtonTitle: string;
    ignoringMessagePath?: string;
    handleLeavePage?: Function;
}

export const LEAVE_PAGE_FROM_BROWSER_EVENT_TEXT =
    "Sind Sie sich sicher, dass sie die Seite verlassen möchten? Nicht gespeicherte Änderungen gehen verloren.";

const LeavePageModal: FunctionComponent<LeavePageModalProps> = (props): ReactElement => {
    const LEAVE_PAGE_MODAL_TITLE = "Möchten Sie Ihre Änderungen verwerfen?";
    const LEAVE_PAGE_MODAL_DESCRIPTION =
        "Sie haben Ihre Auswahl noch nicht gespeichert. Wenn Sie die Seite verlassen, werden Ihre Änderungen verworfen.";
    const LEAVE_PAGE_MODAL_POSITIVE_TEXT = "Änderung verwerfen";
    const LEAVE_PAGE_MODAL_NEGATIVE_TEXT = "Zur Auswahl zurückkehren";

    // Block navigating elsewhere when data has been entered into the input
    const blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            props.shownWhen &&
            currentLocation.pathname !== nextLocation.pathname &&
            nextLocation.pathname !== props.ignoringMessagePath
        );
    });

    const handleUserAction = (leavePage: boolean): void => {
        if (leavePage) {
            if (props.handleLeavePage) {
                props.handleLeavePage();
            }

            blocker.proceed();
        } else {
            // stay on page; set blocker status to unblocked
            blocker.reset();
        }
    };

    return (
        <>
            {blocker.state === "blocked" && (
                <CustomLeavePagePrompt
                    handleUserAction={handleUserAction}
                    title={LEAVE_PAGE_MODAL_TITLE}
                    description={LEAVE_PAGE_MODAL_DESCRIPTION}
                    buttonPositiveText={LEAVE_PAGE_MODAL_POSITIVE_TEXT}
                    buttonNegativeText={LEAVE_PAGE_MODAL_NEGATIVE_TEXT}
                    discardButtonLogInfos={{
                        parameters: {
                            button_title: props.discardButtonTitle,
                        },
                    }}
                    returnButtonLogInfos={{
                        parameters: {
                            button_title: props.returnButtonTitle,
                        },
                    }}
                />
            )}
        </>
    );
};

export default LeavePageModal;
