import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";
import { SearchProps } from "types";
import React, { FunctionComponent, ReactElement } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

interface SearchBarProps {
    searchTerm: string;
    searchTagCategory?: SearchProps;
    onSearchTermChanged: (text: string) => void;
    onCancelButtonPressed: () => void;
}

const SearchBar: FunctionComponent<SearchBarProps> = (props): ReactElement => {
    function handleSearchSubmit(e): void {
        e.preventDefault();
    }

    /**
     * Dismisses the mobile virtual keyboard when the search input field is submitted.
     *
     * @param {*} event;
     */
    function onSearchTriggered(e): void {
        if (e.key === "Enter") {
            e.target.blur();
        }
    }

    function onSearchBoxClicked(): void {
        logAnalyticsButton({
            button_title: CommonButtonTitles.multiplier.onSearchBoxClicked,
        });
    }

    /**
     * Returns either the searchTerm, searchTagCategory or an empty string.
     * @returns
     */
    const getFormInputValue = (): string => {
        if (props.searchTerm !== "") {
            return props.searchTerm;
        }
        if (props.searchTagCategory && props.searchTagCategory?.name !== "") {
            return props.searchTagCategory.name;
        }
        return "";
    };

    return (
        <div className="search-bar-container">
            <form className="search-bar-wrapper" onSubmit={handleSearchSubmit} onKeyUp={onSearchTriggered}>
                <SearchIcon className="search-bar-icon-visible" />
                <input
                    className="search-input font-unitText-Regular font-dimGray"
                    type="text"
                    value={getFormInputValue()}
                    onClick={() => onSearchBoxClicked()}
                    onChange={event => props.onSearchTermChanged(event.target.value)}
                    placeholder="Nach Artikeln suchen"
                    aria-label="Nach Artikeln suchen"
                />
                <CloseIcon
                    onClick={props.onCancelButtonPressed}
                    className={
                        !props.searchTagCategory?.id && props.searchTerm === ""
                            ? "search-bar-icon-invisible"
                            : "search-bar-icon-visible close-icon"
                    }
                />
            </form>
        </div>
    );
};

export default SearchBar;
