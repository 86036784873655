import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import { AppState } from "store/configureStore";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { actionCreators } from "store";
import { IAuthState } from "store/Auth";
import { CommonButtonTitles, logAnalyticsButton, setAnalyticsConsent } from "utils/FirebaseAnalytics";
import { CustomerState } from "store/Customers";
import LogoDesktop from "../../assets/images/tegut_Logo_Claim.svg";
import React, { FunctionComponent } from "react";
import { NavLink, useLocation } from "react-router-dom";

const TrackingConsentModal: FunctionComponent = () => {
    const location = useLocation();
    const customerState: CustomerState = useAppSelector((state: AppState) => state.customerReducer);
    const authState: IAuthState = useAppSelector((state: AppState) => state.authReducer);

    const dispatch = useAppDispatch();

    const setTrackingConsent = (isConfirmed: boolean): void => {
        dispatch(actionCreators.customers.updateTrackingConsent(isConfirmed));
        setAnalyticsConsent(isConfirmed);

        if (isConfirmed) {
            logAnalyticsButton({ button_title: CommonButtonTitles.consent.modalConsent });
        }

        if (!customerState.isTrackingConsentSet) {
            dispatch(actionCreators.customers.setTrackingConsentSet());
        }
    };

    const isDialogVisible = (): boolean => {
        return (
            authState.isAuthenticated &&
            customerState.customer &&
            customerState.isTrackingConsentAccepted == null &&
            !location.pathname.includes("/Impressum") &&
            !location.pathname.includes("/Datenschutz")
        );
    };

    if (!isDialogVisible()) {
        return null;
    }

    return (
        <ModalConfirmInfo
            img={<img src={LogoDesktop} width="80" height="auto" loading="lazy" className="img-fluid" alt="tegut Logo" />}
            titleText="Anonyme Nutzeranalyse erlauben?"
            titleClassNames="font-plumPie"
            bodyText={
                <>
                    <p>
                        Wir wollen das tebonus Angebot noch besser machen. Daher möchten wir anonyme Informationen über die
                        Web-Nutzung sammeln. Wir nutzen diese Informationen ausschließlich zur Optimierung unseres Angebotes und
                        nicht zu Werbezwecken. Mit Ihrer Zustimmung zur anonymen Nutzeranalyse helfen Sie uns das tebonus
                        Programm weiter zu optimieren.
                    </p>
                    <p>
                        Sie können Ihre Zustimmung jederzeit und mit nur einem Klick in den Privatsphäre-Einstellungen
                        rückgängig machen.
                    </p>
                </>
            }
            button1Text="Erlauben"
            button1function={() => {
                setTrackingConsent(true);
            }}
            button2Text="Nicht erlauben"
            button2function={() => {
                setTrackingConsent(false);
            }}
            modalFooter={
                <div className="w-100 d-flex justify-content-center border-top pt-2">
                    <u>
                        <NavLink to="/Impressum" className="nav-link-footer font-dimGray px-2">
                            Impressum
                        </NavLink>
                    </u>
                    <span>|</span>
                    <u>
                        <NavLink to="/Datenschutz" className="nav-link-footer font-dimGray px-2">
                            Datenschutz
                        </NavLink>
                    </u>
                </div>
            }
            isDisplayTextLeft={true}
        ></ModalConfirmInfo>
    );
};

export default TrackingConsentModal;
