import dayjs from "dayjs";

/**
 * calculates a date for when the multipliers can be chosen again.
 * @export
 * @param {string} date the date to calculate.
 * @return {*}  {number} the new calculated multipliers date.
 */
export function calculateTimeUntilNewChoice(date: string): number {
    if (!date) {
        return null;
    }
    const parsedDate = dayjs(date);

    return Math.ceil(parsedDate.diff(dayjs(), "days", true));
}

export function getCurrentWeek(): number {
    const currentDate = new Date();

    currentDate.setUTCDate(currentDate.getUTCDate() + 4 - (currentDate.getUTCDay() || 7));

    const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));

    // eslint-disable-next-line
    // @ts-ignore
    const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

    return weekNumber;
}
