import LoadingIndicator from "components/LoadingIndicator";
import { CustomerScoreTransaction } from "types";
import { EmptyTable } from "./EmptyTable";
import { TransactionsPerMonth, TransactionsPerMonthProps } from "./TransactionsPerMonth";
import { ErrorTable } from "./ErrorTable";
import React, { FunctionComponent, ReactElement } from "react";

interface ScoreTransactionsTableProps extends TransactionsPerMonthProps {
    areTransactionsLoading: boolean;
    hasLoadingError: boolean;
    displayTransactionsPerMonth: CustomerScoreTransaction[];
}

export const ScoreTransactionsTable: FunctionComponent<ScoreTransactionsTableProps> = (props): ReactElement => {
    const transactionsAvailable = (): boolean => {
        return props.displayTransactionsPerMonth && props.displayTransactionsPerMonth.length !== 0 && isNotLoadingOrError();
    };

    const transactionsNotAvailable = (): boolean => {
        return props.displayTransactionsPerMonth.length === 0 && isNotLoadingOrError();
    };

    const isNotLoadingOrError = (): boolean => {
        return !props.areTransactionsLoading && !props.hasLoadingError;
    };

    const hasError = (): boolean => {
        return props.hasLoadingError && !props.areTransactionsLoading;
    };

    return (
        <div className="row justify-content-center my-4 scrollScoreContainer-md overflow-y-md table-scroll-bar">
            {transactionsAvailable() && (
                <TransactionsPerMonth
                    displayTransactionsPerMonth={props.displayTransactionsPerMonth}
                    openedCols={props.openedCols}
                    setOpenedCols={props.setOpenedCols}
                />
            )}
            {transactionsNotAvailable() && <EmptyTable />}
            {props.areTransactionsLoading && (
                <div className="w-100 d-flex justify-content-center">
                    <LoadingIndicator />
                </div>
            )}
            {hasError() && <ErrorTable />}
        </div>
    );
};
