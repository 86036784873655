import { Colors } from "../../../styles";
import React, { FunctionComponent } from "react";

// Tebonus logo with two big hearts in the background

interface TebonusBackgroundProps {
    showLogo?: boolean;
    showTebonus?: boolean;
    // Size can be given in % or in px
    height: string;
    width: string;
}

const TebonusBackground: FunctionComponent<TebonusBackgroundProps> = ({
    showLogo = true,
    showTebonus = true,
    height,
    width,
}) => (
    <svg width={width} height={height} viewBox="0 0 454.367 297.432">
        <g transform="translate(40.701 0)">
            <g opacity={0.502} transform="matrix(0.998, -0.07, 0.07, 0.998, 84.005, 31.37)">
                <g opacity={0.602} transform="matrix(0.999, 0.035, -0.035, 0.999, 9.314, 0)">
                    <path
                        fill={Colors.orange}
                        d="M171.872,85.054c14.495,5.778,17.4,1.749,31.6-1.578,31.488-7.375,69.159.237,91.651,24.78,40.715,44.427-3.719,91.382-44.332,115.792-26.327,15.824-54.98,27.047-84.365,34.8-47.253,12.469-94.717,15.5-125.932-29.236C13.56,191.019,2.44,138.258.12,92.2-1.184,66.251,7.993,22.828,33.5,10.333,80.776-12.83,125.57,4.1,148.767,49.606c5.845,11.466,11.609,28.242,23.105,35.449"
                        transform="translate(0 0)"
                    />
                </g>
            </g>
            <g opacity={0.494} transform="matrix(0.998, -0.07, 0.07, 0.998, -40.701, 53.897)">
                <g opacity={0.6} transform="translate(0 0)">
                    <path
                        fill={Colors.orange}
                        d="M103.708,34.406c7.293,0,9.817-5.5,16.071-12.493,13.875-15.51,36.538-25.4,57.451-20.764,37.855,8.39,31.764,49.537,19.368,77.392a208.122,208.122,0,0,1-31.623,49.478c-20.3,24.011-44.2,43.123-77.163,31.067-28.44-10.4-53.772-34.109-71.992-57.529C5.556,88.362-5.634,62.087,3.2,46.089,19.568,16.433,49.427,8.826,78.446,24.248c7.314,3.887,16.543,10.6,25.262,10.157"
                    />
                </g>
            </g>
            {showLogo && (
                <g transform="translate(0 0)">
                    <g transform="translate(0 0)">
                        <path fill="none" d="M0,0H82.4V47.995H0Z" />
                        <g clipPath="url(#a)">
                            <path
                                fill={Colors.orange}
                                d="M76.855.161a1.135,1.135,0,0,0-.9-.128L.969,13.091a1.128,1.128,0,0,0-.806.426,1.1,1.1,0,0,0-.131.893l5.1,28.582a1.111,1.111,0,0,0,.431.8,1.138,1.138,0,0,0,.9.129L81.454,30.857a1.126,1.126,0,0,0,.805-.426,1.1,1.1,0,0,0,.13-.893L77.286.956a1.108,1.108,0,0,0-.431-.8"
                                transform="translate(-0.023 0)"
                            />
                        </g>
                        <path
                            fill={Colors.white}
                            d="M3.784,1.558a1.813,1.813,0,0,0-.7-1.294A1.823,1.823,0,0,0,1.631.052L1.556.065A1.809,1.809,0,0,0,.26.756,1.8,1.8,0,0,0,.054,2.207l.013.075a1.82,1.82,0,0,0,.7,1.295,1.824,1.824,0,0,0,1.457.211L2.3,3.775a1.808,1.808,0,0,0,1.3-.69A1.8,1.8,0,0,0,3.8,1.632l-.013-.075"
                            transform="translate(66.678 15.648)"
                        />
                        <path
                            fill={Colors.white}
                            d="M3.784,1.558a1.816,1.816,0,0,0-.7-1.295A1.825,1.825,0,0,0,1.631.052L1.556.065A1.808,1.808,0,0,0,.26.756,1.806,1.806,0,0,0,.054,2.207l.013.075a1.82,1.82,0,0,0,.7,1.295,1.823,1.823,0,0,0,1.457.211L2.3,3.775a1.808,1.808,0,0,0,1.3-.69A1.8,1.8,0,0,0,3.8,1.632l-.013-.075"
                            transform="translate(60.653 16.698)"
                        />
                        <path
                            fill={Colors.white}
                            d="M3.8,1.632a1.8,1.8,0,0,1-.206,1.452,1.81,1.81,0,0,1-1.3.69l-.075.013A1.821,1.821,0,0,1,.765,3.577a1.817,1.817,0,0,1-.7-1.295L.054,2.207A1.806,1.806,0,0,1,.26.755a1.81,1.81,0,0,1,1.3-.69L1.631.052A1.823,1.823,0,0,1,3.087.263a1.816,1.816,0,0,1,.7,1.295l.013.075"
                            transform="translate(54.628 17.746)"
                        />
                        <path
                            fill={Colors.white}
                            d="M1.376,5.67l1.088,6.093c.369,2.066,1.7,2.487,3.557,2.163a4.571,4.571,0,0,0,1.273-.4.92.92,0,0,0,.456-1.145l-.318-.792a.417.417,0,0,0-.569-.227,1.882,1.882,0,0,1-.5.174c-.534.093-.849-.07-1.015-1l-.964-5.4,1.153-.2a.86.86,0,0,0,.7-.992l-.1-.569a.869.869,0,0,0-1-.695l-1.153.2L3.573.592A.714.714,0,0,0,2.638.037L1.309.486A1.014,1.014,0,0,0,.653,1.622l.32,1.792-.256.044a.861.861,0,0,0-.7.992l.1.569a.869.869,0,0,0,1,.695l.256-.045"
                            transform="translate(44.334 8.726)"
                        />
                        <path
                            fill={Colors.white}
                            d="M7.509,10.035l.109.242a1.092,1.092,0,0,0,1.193.64l.747-.13a.859.859,0,0,0,.7-.992L8.64.707A.87.87,0,0,0,7.634.013L6.333.24a.859.859,0,0,0-.7.992L6.905,8.379A2.349,2.349,0,0,1,5.464,9.3c-.64.112-1.088-.05-1.276-1.1L3.024,1.685a.868.868,0,0,0-1-.694l-1.3.226a.86.86,0,0,0-.7.992L1.278,9.293a2.861,2.861,0,0,0,3.589,2.593,3.572,3.572,0,0,0,2.642-1.851"
                            transform="translate(33.915 12.781)"
                        />
                        <path
                            fill={Colors.white}
                            d="M4.574,15.857h0a4.081,4.081,0,0,1-2.294-.564,2.361,2.361,0,0,1-1.008-1.614,2.1,2.1,0,0,1,.221-1.367,2.921,2.921,0,0,1,.9-1.011A1.993,1.993,0,0,1,1.081,9.777a2.425,2.425,0,0,1,.833-2.363A3.161,3.161,0,0,1,.082,4.8,3.355,3.355,0,0,1,.64,1.975,4.934,4.934,0,0,1,3.716.491,6.236,6.236,0,0,1,4.78.4a5.427,5.427,0,0,1,.779.054L8.1.011A.744.744,0,0,1,8.227,0a.767.767,0,0,1,.752.619l.008.042a1.123,1.123,0,0,1-.5,1.131l-.359.215a3.77,3.77,0,0,1,.6,1.5,3.009,3.009,0,0,1-.55,2.682A5.321,5.321,0,0,1,5.045,7.564,9.853,9.853,0,0,1,3.881,7.7a1.418,1.418,0,0,0-.177.857.524.524,0,0,0,.58.424,1.284,1.284,0,0,0,.219-.02L6.531,8.61A6.848,6.848,0,0,1,7.7,8.5a3.45,3.45,0,0,1,2.074.581,2.933,2.933,0,0,1,1.108,1.943,3.267,3.267,0,0,1-.753,3.028A6.763,6.763,0,0,1,6.32,15.69,10.3,10.3,0,0,1,4.574,15.857Zm2.234-4.866a5.467,5.467,0,0,0-.918.1l-2.019.346a3.38,3.38,0,0,0-.072,1.3c.113.631.385,1.051,1.347,1.051a5.318,5.318,0,0,0,.905-.091c1.342-.234,2.112-.935,1.96-1.786C7.9,11.256,7.547,10.991,6.809,10.991ZM4.645,2.52a2.9,2.9,0,0,0-.492.048c-1.014.177-1.331.683-1.131,1.805a2.078,2.078,0,0,0,.38.987,1.05,1.05,0,0,0,.859.35,2.621,2.621,0,0,0,.445-.042A1.465,1.465,0,0,0,5.79,5.1a1.685,1.685,0,0,0,.1-1.2C5.732,3.024,5.479,2.52,4.645,2.52Z"
                            transform="translate(23.418 14.516)"
                        />
                        <path
                            fill={Colors.white}
                            d="M4.939,11.459h0A4.126,4.126,0,0,1,1.8,10.273,7.071,7.071,0,0,1,.189,6.518a6.476,6.476,0,0,1,.439-4.54A4.24,4.24,0,0,1,3.757.115,7.237,7.237,0,0,1,4.988,0,3.538,3.538,0,0,1,7.865,1.24,8.085,8.085,0,0,1,9.351,5.055L9.43,5.5a.424.424,0,0,1-.331.492L3.633,6.94C4,8.495,4.457,9.046,5.386,9.046a3.754,3.754,0,0,0,.637-.064A3.474,3.474,0,0,0,7.63,8.245a.921.921,0,0,1,.588-.208.988.988,0,0,1,.678.27l.469.527a.827.827,0,0,1,.231.618.784.784,0,0,1-.284.565A5.761,5.761,0,0,1,6.5,11.311,9.217,9.217,0,0,1,4.939,11.459ZM4.617,2.213a2.161,2.161,0,0,0-.368.035,1.073,1.073,0,0,0-.947.664,4.38,4.38,0,0,0-.03,2.005L6.09,4.426C5.706,2.544,5.309,2.213,4.617,2.213Z"
                            transform="translate(12.9 16.716)"
                        />
                        <path
                            fill={Colors.white}
                            d="M1.376,5.67l1.088,6.093c.369,2.066,1.7,2.487,3.557,2.164a4.613,4.613,0,0,0,1.273-.4.921.921,0,0,0,.456-1.145l-.318-.793a.418.418,0,0,0-.569-.227,1.884,1.884,0,0,1-.5.174c-.534.093-.849-.069-1.015-1l-.963-5.4,1.153-.2a.86.86,0,0,0,.7-.992l-.1-.569a.87.87,0,0,0-1-.7l-1.153.2L3.573.592A.714.714,0,0,0,2.637.038L1.309.486A1.013,1.013,0,0,0,.653,1.622l.32,1.792-.256.045a.859.859,0,0,0-.7.992l.1.569a.869.869,0,0,0,1,.7l.256-.045"
                            transform="translate(4.981 15.566)"
                        />
                    </g>
                </g>
            )}
            {showTebonus && (
                <g transform="translate(5.688 139.622)">
                    <path
                        fill={Colors.orange}
                        d="M43.736,91.536a3.633,3.633,0,0,1-3.7-3.7v-2.5a3.633,3.633,0,0,1,3.7-3.7h1.11V73.772A4.522,4.522,0,0,1,45.865,70.9a3.748,3.748,0,0,1,2.59-1.48l5.921-.925a3.052,3.052,0,0,1,3.517,3.052V81.636h5a3.633,3.633,0,0,1,3.7,3.7v2.5a3.633,3.633,0,0,1-3.7,3.7h-5v23.686q0,3.053.878,4.024a3.374,3.374,0,0,0,2.637.972,7.188,7.188,0,0,0,1.2-.093,4.718,4.718,0,0,0,1.019-.277,1.7,1.7,0,0,1,1.389.232,1.877,1.877,0,0,1,.832,1.155l.74,3.609a3.826,3.826,0,0,1-.508,2.775,3.931,3.931,0,0,1-2.267,1.759,16.337,16.337,0,0,1-2.544.555,23.183,23.183,0,0,1-3.1.185,25.012,25.012,0,0,1-5.506-.555,10,10,0,0,1-4.162-1.944,9.317,9.317,0,0,1-2.684-3.654,14.485,14.485,0,0,1-.972-5.691V91.536Z"
                        transform="translate(-40.036 -52.651)"
                    />
                    <path
                        fill={Colors.orange}
                        d="M93.209,121.389a22.14,22.14,0,0,1-5.689,2.5,25.418,25.418,0,0,1-7.079.925q-11.845,0-17.394-5.876T57.5,99.739a41.968,41.968,0,0,1,1.342-11.382,19.534,19.534,0,0,1,3.885-7.633,14.874,14.874,0,0,1,6.244-4.256A24.351,24.351,0,0,1,77.3,75.128q10.638,0,15.22,6.059t4.579,19.108v1.942a1.862,1.862,0,0,1-.508,1.3,1.626,1.626,0,0,1-1.248.557H71.651a26.392,26.392,0,0,0,.74,5.274,7.916,7.916,0,0,0,1.574,3.19,5.093,5.093,0,0,0,2.544,1.574,14.064,14.064,0,0,0,3.655.417,13.2,13.2,0,0,0,3.979-.555,20.1,20.1,0,0,0,3.33-1.389,3.972,3.972,0,0,1,2.867-.417,3.6,3.6,0,0,1,2.405,1.62l1.574,2.59a3.548,3.548,0,0,1,.508,2.775,3.236,3.236,0,0,1-1.619,2.22M83.864,95.205q-.094-2.867-.323-4.857a9.262,9.262,0,0,0-.925-3.285,4.417,4.417,0,0,0-1.852-1.9,6.542,6.542,0,0,0-3.007-.6,6.985,6.985,0,0,0-2.682.462,3.746,3.746,0,0,0-1.8,1.665,10.991,10.991,0,0,0-1.112,3.285,32.2,32.2,0,0,0-.508,5.227Z"
                        transform="translate(-26.132 -47.347)"
                    />
                    <path
                        fill={Colors.white}
                        d="M103.886,134.6c-5.96,0-10.828-3.278-13.808-6.655l-.895,3.774a2.564,2.564,0,0,1-2.482,1.888h-.2a1.993,1.993,0,0,1-1.987-1.987V64.761A2.205,2.205,0,0,1,86.7,62.575h1.588a2.205,2.205,0,0,1,2.186,2.186v27.32c3.278-3.478,8.146-6.955,13.808-6.955,9.44,0,16.1,6.657,16.1,24.737,0,17.088-5.763,24.737-16.492,24.737m-1.092-5.166c7.451,0,11.324-4.37,11.324-19.073,0-15.9-3.576-20.069-10.729-20.069-5.463,0-9.436,3.377-12.914,6.557v26.724c3.478,3.478,7.55,5.861,12.319,5.861"
                        transform="translate(-4.617 -57.343)"
                    />
                    <path
                        fill={Colors.white}
                        d="M127.38,124.6c-11.028,0-18.975-7.054-18.975-24.638,0-17.683,7.749-24.836,18.777-24.836,10.927,0,19.073,7.054,19.073,24.737,0,17.584-7.748,24.737-18.876,24.737m0-4.769c8.247,0,12.617-4.868,12.617-19.768,0-15-4.471-20.167-12.716-20.167s-12.617,4.868-12.617,19.867,4.471,20.069,12.716,20.069"
                        transform="translate(14.408 -47.346)"
                    />
                    <path
                        fill={Colors.white}
                        d="M135.92,123.61a2.2,2.2,0,0,1-2.184-2.186V78.111a1.992,1.992,0,0,1,1.985-1.989h.2a2.436,2.436,0,0,1,2.384,1.989l.993,4.868c4.67-4.572,9.637-7.85,15.5-7.85,6.756,0,11.723,3.975,11.723,12.22v34.074a2.205,2.205,0,0,1-2.186,2.186h-1.59a2.206,2.206,0,0,1-2.186-2.186V88.939c0-6.359-2.285-8.545-7.151-8.545-4.769,0-9.438,3.081-13.71,6.857v34.173a2.205,2.205,0,0,1-2.186,2.186Z"
                        transform="translate(34.579 -47.346)"
                    />
                    <path
                        fill={Colors.white}
                        d="M169.538,124.163c-6.855,0-12.021-4.074-12.021-12.517V77.868a2.2,2.2,0,0,1,2.184-2.186h1.491a2.205,2.205,0,0,1,2.186,2.186v32.188c0,6.458,2.583,8.841,7.351,8.841s9.339-2.782,13.711-6.359V77.868a2.2,2.2,0,0,1,2.184-2.186h1.59a2.2,2.2,0,0,1,2.184,2.186v43.214a2.016,2.016,0,0,1-1.985,2.087h-.2a2.565,2.565,0,0,1-2.484-1.987l-.993-4.372c-4.469,4.272-9.337,7.352-15.2,7.352"
                        transform="translate(53.517 -46.905)"
                    />
                    <path
                        fill={Colors.white}
                        d="M194.091,124.6a25.231,25.231,0,0,1-13.51-3.875,2.336,2.336,0,0,1-.7-3.081l.3-.5a2.156,2.156,0,0,1,2.982-.794,20.051,20.051,0,0,0,11.026,3.278c7.054,0,9.935-3.179,9.935-8.346,0-5.761-2.583-7.451-10.729-10.034-7.052-2.184-12.319-5.463-12.319-14.006,0-6.657,4.57-12.122,14.008-12.122a24.616,24.616,0,0,1,11.028,2.285,2.233,2.233,0,0,1,.993,2.881l-.3.7a2.3,2.3,0,0,1-2.98,1.094,19.137,19.137,0,0,0-8.644-1.888c-5.364,0-8.444,2.186-8.444,6.954,0,5.166,2.386,6.557,9.041,8.743,7.949,2.583,14.108,5.265,14.108,15.1,0,9.24-5.863,13.611-15.8,13.611"
                        transform="translate(71.086 -47.346)"
                    />
                    <path
                        fill={Colors.orange}
                        d="M214.861,68.245c1.484.93,1.755.178,3.188-.16a9.765,9.765,0,0,1,9.248,2.5c4.108,4.482-.374,9.221-4.473,11.683a32.715,32.715,0,0,1-8.513,3.512c-4.769,1.257-9.558,1.563-12.709-2.95-2.716-3.9-3.837-9.221-4.072-13.868-.133-2.617.794-7,3.368-8.261,4.771-2.335,9.291-.627,11.631,3.965.589,1.157,1.171,2.849,2.332,3.576"
                        transform="translate(85.37 -59.662)"
                    />
                </g>
            )}
        </g>
    </svg>
);

export default TebonusBackground;
