import { MonthPromotionState } from "store/MonthPromotions";

/**
 * Fetches the current promotion status
 * @returns true if the user has never selected promotions before, false otherwise
 */
export function isInitial(promotions: MonthPromotionState): boolean {
    return !promotions.nextChangeDate;
}

/**
 * Checks if the promotion has been selected and have been locked.
 * @returns true if the promotions are locked.
 */
export function arePromotionsLocked(promotions: MonthPromotionState): boolean {
    return promotions.arePromotionsLocked;
}
