import { TegutBadge } from "components/_tegut/TegutBadge";
import { ArticleSearchState } from "store/ArticleSearch";
import { AppState } from "store/configureStore";
import { Colors } from "styles";
import { SearchProps } from "types";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";
import { useFocusTrap } from "utils/useFocusTrap";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CloseSharp from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";

interface FilterModalMobileProps {
    setShowModal: Function;

    selectedBrands: SearchProps[];
    setSelectedBrands: (value: SearchProps[]) => void;

    categoryOptions: SearchProps[];
    selectedCategories: SearchProps[];
    setSelectedCategories: (value: SearchProps[]) => void;
}

// Filter categories which can be selected by the user
enum PossibleFilters {
    none,
    brand,
    category,
}

const FilterModalMobile: FunctionComponent<FilterModalMobileProps> = props => {
    const [expandedSelection, setExpandedSelection] = useState(PossibleFilters.none);
    const [tmpSelectedBrands, setTmpSelectedBrands] = useState<SearchProps[]>([]);
    const [tmpSelectedCategories, setTmpSelectedCategories] = useState<SearchProps[]>([]);

    const articleSearchState: ArticleSearchState = useSelector((state: AppState) => state.articleSearchReducer);

    const dialogRef = useFocusTrap();

    useEffect(() => {
        // check which brands have been already selected and update the tmp list
        setTmpSelectedBrands([...tmpSelectedBrands, ...props.selectedBrands]);
        setTmpSelectedCategories([...tmpSelectedCategories, ...props.selectedCategories]);

        // modal-body handler
        toggleModalOpenClass(true);

        if (dialogRef && dialogRef.current) {
            dialogRef.current.focus();
        }

        return () => {
            toggleModalOpenClass(false);
        };
    }, []);

    /**
     * close the modal
     */
    const closeModal = (): void => {
        // maybee not necessary
        setTmpSelectedBrands([]);
        setTmpSelectedCategories([]);
        props.setShowModal(false);
    };

    /**
     * Check which filter is expanded and do the corresponding action
     * 1. Close it when the filter is already open
     * 2. Open/ expand the filter if it was not expanded and close the other filters
     * @param toCheck
     */
    const checkAndSetExpandedSelection = (toCheck: PossibleFilters): void => {
        // Close it if it is already opened
        if (toCheck === expandedSelection) {
            setExpandedSelection(PossibleFilters.none);
        } else {
            // Expand the filter selection and close the other options
            setExpandedSelection(toCheck);
        }
    };

    /**
     * Check if the item was selected in the drop down menu
     * @param item
     * @returns
     */
    const isChecked = (selectedItems: SearchProps[], item: SearchProps): boolean => {
        return selectedItems.find(i => i.id === item.id) ? true : false;
    };

    /**
     * Add or remove the item from the selected items list depending on if it is selected or not
     * @param selectedItems list with all selected items
     * @param item which should be added or removed from the list depending on selection state
     * @param setSelectedItems is the function which is used to add or remove the item
     */
    const updateSelectedItems = (
        selectedItems: SearchProps[],
        item: SearchProps,
        setSelectedItems: (value: SearchProps[]) => void
    ): void => {
        if (isChecked(selectedItems, item)) {
            // remove item from list
            setSelectedItems(selectedItems.filter(i => i.id !== item.id));
        } else {
            // add item to list
            setSelectedItems([...selectedItems, item]);
        }
    };

    /**
     * Save the selected filters and close the modal
     */
    const submitFilterSelection = (): void => {
        props.setSelectedBrands(tmpSelectedBrands);
        props.setSelectedCategories(tmpSelectedCategories);
        props.setShowModal(false);
    };

    /**
     * Render the selection button which can be expanded
     * @param title
     * @param selectionType
     * @returns
     */
    const showSelectionButton = (title: string, selectionType: PossibleFilters): React.JSX.Element => {
        const isExpanded = expandedSelection === selectionType;
        return (
            <button
                className="btn p-0 pl-2 my-1 d-flex align-items-center w-100"
                onClick={() => checkAndSetExpandedSelection(selectionType)}
                aria-label={isExpanded ? "Schließen" : "Erweitern"}
            >
                <div className={`modal-body-text text-center font-unitText-Bold ${isExpanded && "font-orange"}`}>{title}</div>
                <div
                    className={`ml-auto icon-accent-wrapper icon-accent-orange
                            ${isExpanded ? "rotateArrow-90" : "rotateArrow-0"}`}
                >
                    <NavigateNextIcon />
                </div>
            </button>
        );
    };

    /**
     * Shows all options in a vertical list with selection badges
     * @param allOptions
     * @param tmpSelectedItems
     * @param setTmpSelectedItems
     * @returns
     */
    const showExpandedSelection = (
        allOptions: SearchProps[],
        tmpSelectedItems: SearchProps[],
        setTmpSelectedItems: (value: SearchProps[]) => void
    ): React.JSX.Element => {
        return (
            <div className="table-scroll-bar overflow-auto w-100 px-1" style={{ maxHeight: "50vh" }}>
                {/* Reset items */}
                <button className="d-flex my-1 btn p-0 w-100 align-items-center" onClick={() => setTmpSelectedItems([])}>
                    <div className="dropDownIconSpace">
                        <CloseSharp style={{ color: Colors.dimGray, fontSize: "1.25rem" }}></CloseSharp>
                    </div>
                    <div className="dropDownText">zurücksetzen</div>
                </button>

                {allOptions?.map((option, index) => {
                    return (
                        <button
                            key={index}
                            className="btn p-0 d-flex mb-1 w-100 align-items-center"
                            onClick={() => updateSelectedItems(tmpSelectedItems, option, setTmpSelectedItems)}
                        >
                            <div className="dropDownIconSpace">
                                {isChecked(tmpSelectedItems, option) ? (
                                    <TegutBadge className="dropDownBadge" bgColor={`${Colors.seaweedGreen}`}>
                                        <CheckIcon className="dropDownBadgeIcon" style={{ color: Colors.white }} />
                                    </TegutBadge>
                                ) : (
                                    <TegutBadge className="dropDownBadge" bgColor={`${Colors.white}`}></TegutBadge>
                                )}
                            </div>
                            <div className="dropDownText">{option.name}</div>
                        </button>
                    );
                })}
            </div>
        );
    };

    /**
     * Compares to objects and returns true if the elemets are equal. Order of the elements will not be considered
     * @param a
     * @param b
     * @returns
     */
    const isEqual = (a: SearchProps[], b: SearchProps[]): boolean => {
        // if the length differs the two objects can not be equal. Used to increase the performance
        if (a.length !== b.length) {
            return false;
        }
        // the objects needs to be sorted because json.stringify is considering the order of the elements
        a.sort((v1, v2) => (v1.id > v2.id ? 1 : -1));
        b.sort((v1, v2) => (v1.id > v2.id ? 1 : -1));
        return JSON.stringify(a) === JSON.stringify(b);
    };

    /**
     * Returns true if the selection of the filters have been changed.
     * @returns
     */
    const hasFilterChanged = (): boolean => {
        if (!isEqual(tmpSelectedBrands, props.selectedBrands) || !isEqual(tmpSelectedCategories, props.selectedCategories)) {
            return true;
        }
        return false;
    };

    return (
        // It is only shown on small screens
        <div className="center-on-container d-md-none">
            {/* div which creates a dark background over the whole screen which is not clickable */}
            <div className="modal-bg-disabled"></div>

            <div tabIndex={-1} role="dialog" className="modal-position" ref={dialogRef}>
                <div className="modalFilterTile d-flex flex-column align-items-center">
                    {/* Close button */}
                    <button
                        className="btn p-0 font-dimGray"
                        style={{ position: "absolute", top: 10, right: 10 }}
                        onClick={() => closeModal()}
                        aria-label="Schließen"
                    >
                        <CloseSharp />
                    </button>
                    {/* Title */}
                    <div className="modal-title text-center">Suchergebnisse Filtern</div>
                    {/* Brands expand button */}
                    {showSelectionButton("Marke", PossibleFilters.brand)}
                    {/* Show all brands */}
                    {PossibleFilters.brand === expandedSelection &&
                        showExpandedSelection(
                            articleSearchState.brands.map(item => {
                                return { id: item, name: item };
                            }),
                            tmpSelectedBrands,
                            setTmpSelectedBrands
                        )}

                    {/* Categories expand button */}
                    {showSelectionButton("Warengruppe", PossibleFilters.category)}
                    {PossibleFilters.category === expandedSelection &&
                        showExpandedSelection(props.categoryOptions, tmpSelectedCategories, setTmpSelectedCategories)}

                    <button
                        className="btn btn-orange btn-select mt-4"
                        disabled={!hasFilterChanged()}
                        onClick={() => submitFilterSelection()}
                    >
                        Filter anwenden
                    </button>
                </div>
            </div>
        </div>
    );
};
export default FilterModalMobile;
