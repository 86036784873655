import LogoTegut from "../../../assets/images/tegut_Logo_Claim.svg";
import { linksFooter, linksSocialMedia } from "./links";
import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";

/**
 * Footer which is always located on the bottom of the page.
 * When the screen size is reduced, the divs will be reorderd and resized
 */
interface FooterProps {
    setTerminateButton: (value: boolean) => void;
    isAuthenticated: boolean;
}

export const Footer: FunctionComponent<FooterProps> = props => {
    return (
        <div className="container-fluid footer">
            <div className="container-xl">
                <div className="row d-flex align-items-lg-center">
                    {/* logo which will be hidden on large screen */}
                    <div className="col-xl-2 col-lg-2 col-md-7 col-sm-6 col-5 align-self-start align-self-lg-center order-1 order-lg-1 d-lg-none">
                        <a
                            href="https://www.tegut.com/"
                            className="navbar-brand"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="zur tegut… Startseite"
                        >
                            <img
                                src={LogoTegut}
                                width="175px"
                                height="auto"
                                loading="lazy"
                                className="img-fluid"
                                alt="zur tegut… Startseite"
                            />
                        </a>
                    </div>

                    {/* Cookie button maybe needed later */}
                    <div className="col-xl-3 col-lg-2 col-md-7 col-sm-6 col-5 offset-lg-2 order-3 order-lg-2">
                        {/* <a href="/Coockies" type="button" className="btn btn-outline-white btn-footer">
                            Cookie-Einstellungen
                        </a> */}
                    </div>

                    {/* social Media links */}
                    <div className="col-xl-2 col-lg-4 col-md-5 col-sm-6 col-7 order-4 order-lg-3 px-0">
                        {linksSocialMedia.length > 0 && (
                            // Order of the footer links and social media links will be swapped on a small screen. Also the alignment will be adjusted
                            <ul className="nav d-flex justify-content-lg-center justify-content-between">
                                {linksSocialMedia.map((item, i) => (
                                    <li className="nav-item" key={i}>
                                        <a
                                            href={item.htmlLink}
                                            className="nav-link-footer"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    {/* footer links*/}
                    <div className="col-xl-5 col-lg-4 col-md-5 col-sm-6 col-7 order-2 order-lg-4 d-flex justify-content-lg-end pb-0 px-0">
                        <ul className="nav d-flex flex-row justify-content-lg-end">
                            {linksFooter.map((item, index) => {
                                return (
                                    <li key={index} className="nav-item width-50-lg-auto">
                                        {/* use <a> when the link is external, <NavLink> is only used for internal navigation */}
                                        {item.externalLink && (
                                            <a
                                                href={item.htmlLink}
                                                className="nav-link-footer"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {item.title}
                                            </a>
                                        )}
                                        {!item.externalLink && (
                                            <NavLink to={item.htmlLink} className="nav-link-footer">
                                                {item.title}
                                            </NavLink>
                                        )}
                                    </li>
                                );
                            })}
                            {/* tebonus termination */}
                            {props.isAuthenticated && (
                                <a
                                    onKeyUp={e => {
                                        if (e.key === "Enter") {
                                            props.setTerminateButton(true);
                                        }
                                    }}
                                    tabIndex={0}
                                    className="nav-link-footer cursor-pointer width-100-lg-auto"
                                    onClick={() => props.setTerminateButton(true)}
                                >
                                    tebonus Teilnahme kündigen
                                </a>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
