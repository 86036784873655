import { Colors } from "styles";
import React from "react";

interface TebonusPhysicalCardIconProps {
    size: number;
}

const TebonusPhysicalCardIcon: React.FC<TebonusPhysicalCardIconProps> = props => (
    <svg width={props.size * 1.45} height={props.size} viewBox="0 0 31.18 21.15">
        <g fill="none" stroke={Colors.white} strokeMiterlimit="10" strokeWidth="1.5">
            <rect height="19.62" rx="1.66" fill={Colors.orange} width="29.86" x=".75" y=".75" />

            <path d="m.62 6.87h30.01" />

            <path d="m16.4 15.47 10.52-.25" />
        </g>
    </svg>
);

export default TebonusPhysicalCardIcon;
