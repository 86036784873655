import PageTitle from "components/Shared/PageTitle";
import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import React, { FunctionComponent } from "react";

interface PageBaseProps {
    title: string;
    id?: string;
    icon?: React.JSX.Element;
    prevPageLink?: string;
    modals?: React.JSX.Element;
    isContentLoading?: boolean;
    contentLoadingText?: string;
    showLoadingIndicator?: boolean;
    children: React.ReactNode;
}

const PageBase: FunctionComponent<PageBaseProps> = props => {
    return (
        <div className="row-eq " id={props.id}>
            {props.modals}
            {/* Header with title and back button */}
            <div className="col-12 grid-gutter-y no-padding-mobile">
                {/* Show loading modal when the content is loading */}
                {props.isContentLoading && <ModalConfirmInfo bodyText={props.contentLoadingText} showLoadingIndicator={true} />}
                <div className="tile">
                    {/* Content title */}
                    <PageTitle
                        title={props.title}
                        prevPageLink={props.prevPageLink ? props.prevPageLink : "/"}
                        icon={props.icon}
                    />
                    {/* content-in-tile is only active on mobile version. It adds padding on the left and right. */}
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export default PageBase;
