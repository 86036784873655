import { News, NewsSlide } from "types";
import { CommonButtonTitles, logAnalyticsSelectContent } from "utils/FirebaseAnalytics";
import React, { FunctionComponent, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

interface DashboardNewsItemProps {
    news: News;
    slideIndex: number;
    slides: NewsSlide[];
    isActive: boolean;
    newsTitle: string;
    position: number;
}

const DashboardNewsItem: FunctionComponent<DashboardNewsItemProps> = (props): ReactElement => {
    const singleNews = props.news;
    const imgPathWideScreen = singleNews.imagePathWeb;
    const imgPathSmallScreen = singleNews.imagePath;

    const navigate = useNavigate();

    const navigateToNewsItem = (newsId: number, newsIndex: number): void => {
        logAnalyticsSelectContent({
            content_type: CommonButtonTitles.news.newsSlide,
            item_id: props.newsTitle,
            position: props.position,
        });

        const path = `/meintebonus/News`;
        navigate(path, {
            state: {
                newsId,
                newsIndex: newsIndex - props.slides.filter((s, sIndex) => !!s.contentPanel && sIndex < newsIndex).length,
            },
        });
    };

    return (
        <div
            className="d-flex align-items-center justify-content-center h-100 dashboard-news-carousel"
            key={singleNews.id}
            id={`news-slide-${props.slideIndex}`}
            onClick={() => navigateToNewsItem(singleNews.id, props.slideIndex)}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    navigateToNewsItem(singleNews.id, props.slideIndex);
                }
            }}
        >
            {imgPathSmallScreen && (
                <div className="d-md-none d-flex w-100" style={{ maxHeight: "574px" }}>
                    <img src={imgPathSmallScreen} width="100%" />
                </div>
            )}

            {imgPathWideScreen && (
                <div className="d-none d-md-block firstRowTile" style={{ zIndex: props.isActive ? "1" : "0" }}>
                    <img src={imgPathWideScreen} height="100%" />
                </div>
            )}
        </div>
    );
};

export default DashboardNewsItem;
