import { NewsContentPanel } from "types";
import React, { FunctionComponent, ReactElement } from "react";

interface NewsContentPanelProps {
    slideIndex: number;
    contentPanel: NewsContentPanel;
    isActive: boolean;
}

const ContentPanel: FunctionComponent<NewsContentPanelProps> = (props): ReactElement => {
    return (
        <div className="d-flex justify-content-center">
            <a
                key={`panel-${props.slideIndex}`}
                className="d-flex align-items-center justify-content-center h-100 dashboard-news-carousel link-no-hover"
                id={`news-slide-${props.slideIndex}`}
                href={props.contentPanel.link}
                target="_blank"
                rel="noopener noreferrer"
            >
                {props.contentPanel.image && (
                    <>
                        <div className="d-md-none d-flex w-100" style={{ maxHeight: "574px" }}>
                            <img src={props.contentPanel.image} width="100%" />
                        </div>
                        <div className="d-none d-md-block firstRowTile w-100" style={{ zIndex: props.isActive ? "1" : "0" }}>
                            <img src={props.contentPanel.image} height="100%" />
                        </div>
                    </>
                )}
            </a>
        </div>
    );
};

export default ContentPanel;
