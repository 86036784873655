import PageBase from "components/Shared/PageBase";
import React, { FunctionComponent, ReactElement, useEffect } from "react";

interface IdentityPageProps {
    baseUrl: string;
    useDefaultUrlParameters: boolean;
    pageTitle: string;
    buttonText: string;
    pageTextPreButton: string;
    pageTextPostButton: string;
}

const IdentityPage: FunctionComponent<IdentityPageProps> = (props): ReactElement => {
    let fetchUrl = "";
    if (props.useDefaultUrlParameters) {
        const searchPrams = new URLSearchParams({ customerSource: "tebonus", TebonusSource: "Web" });
        fetchUrl = `${props.baseUrl}?${searchPrams}`;
    } else {
        fetchUrl = props.baseUrl;
    }

    useEffect(() => {
        window.open(fetchUrl, "_blank");
    }, []);

    return (
        <PageBase title={props.pageTitle}>
            <div className="content-in-tile">
                <div className="row justify-content-center">
                    <div className="mt-5 px-3">
                        {props.pageTextPreButton}
                        <a href={fetchUrl} target="_blank" rel="noopener noreferrer" className="font-orange">
                            {props.buttonText}
                        </a>
                        {props.pageTextPostButton}
                    </div>
                </div>
            </div>
        </PageBase>
    );
};

export default IdentityPage;
