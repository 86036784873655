import {
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    CardOverviewInfo,
    CardState,
    CardType,
    ErrorStatusCode,
    ReduxBaseType,
} from "types";
import RequestStatus from "utils/RequestStatus";
import { AppThunkAction } from "store";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";

export const GET_CARDOVERVIEW_CARDS = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS";
export const GET_CARDOVERVIEW_CARDS_SUCCESS = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS_SUCCESS";
export const GET_CARDOVERVIEW_CARDS_FAIL = "pekuma/cardoverview/GET_CARDOVERVIEW_CARDS_FAIL";
export const CLEAR_ALL_CARDOVERVIEW_CARDS = "pekuma/cardoverview/CLEAR_ALL";
export const SEND_CARD_LOCK = "pekuma/cardoverview/SEND_CARD_LOCK";
export const SEND_CARD_LOCK_SUCCESS = "pekuma/cardoverview/SEND_CARD_LOCK_SUCCESS";
export const SEND_CARD_LOCK_FAIL = "pekuma/cardoverview/SEND_CARD_LOCK_FAIL";

interface SendCardLockRequest {
    cardNumber: string;
    orderNewCard: boolean;
}

interface GetCardsResponse {
    cards: CardOverviewInfo[];
}

export interface GetCardOverviewCardsAction {
    type: typeof GET_CARDOVERVIEW_CARDS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCardOverviewCardsSuccessAction {
    type: typeof GET_CARDOVERVIEW_CARDS_SUCCESS;
    payload: AxiosResponse<GetCardsResponse>;
}

interface GetCardOverviewCardsFailAction {
    type: typeof GET_CARDOVERVIEW_CARDS_FAIL;
    error: AxiosRequestError;
}

interface SendCardLockAction {
    type: typeof SEND_CARD_LOCK;
}

interface SendCardLockSuccessAction {
    type: typeof SEND_CARD_LOCK_SUCCESS;
}

interface SendCardLockFailAction {
    type: typeof SEND_CARD_LOCK_FAIL;
    error: AxiosRequestError;
}

interface ClearAllAction {
    type: typeof CLEAR_ALL_CARDOVERVIEW_CARDS;
}

export interface CardOverviewState extends BaseType, ReduxBaseType {
    cards: CardOverviewInfo[];
    lastModified: number;
}

export type KnownAction =
    | GetCardOverviewCardsAction
    | GetCardOverviewCardsFailAction
    | GetCardOverviewCardsSuccessAction
    | SendCardLockAction
    | SendCardLockSuccessAction
    | SendCardLockFailAction
    | ClearAllAction;

export type ErrorAction = GetCardOverviewCardsFailAction;

export const INITIAL_STATE: CardOverviewState = {
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    cards: [],
    lastUpdate: 0,
    lastModified: 0,
    performedAction: null,
};

/**
 * Goes through all cards and checks if there is a physical card which has been ordered. Returns true if this is the case.
 * Otherwise false will be returned
 * @param allCards
 * @returns
 */
export const isPhysicalCardOrdered = (allCards: CardOverviewInfo[]): boolean => {
    return allCards.some(card => card.cardType === CardType.physical && card.state === CardState.ordered);
};

// Reducer
export const reducer: Reducer<CardOverviewState> = (state = INITIAL_STATE, action: KnownAction): CardOverviewState => {
    switch (action.type) {
        case GET_CARDOVERVIEW_CARDS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CARDOVERVIEW_CARDS,
            };
        }
        case GET_CARDOVERVIEW_CARDS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CARDOVERVIEW_CARDS_FAIL,
            };
        }

        case GET_CARDOVERVIEW_CARDS_SUCCESS: {
            const { cards } = action.payload.data;
            return {
                ...state,
                cards,
                lastUpdate: dayjs().unix(),
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CARDOVERVIEW_CARDS_SUCCESS,
            };
        }

        case SEND_CARD_LOCK: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SEND_CARD_LOCK,
            };
        }

        case SEND_CARD_LOCK_SUCCESS: {
            return {
                ...state,
                lastModified: dayjs().unix(),
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SEND_CARD_LOCK_SUCCESS,
            };
        }

        case SEND_CARD_LOCK_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.SEND_CARD_LOCK_FAIL,
            };
        }

        case CLEAR_ALL_CARDOVERVIEW_CARDS: {
            return {
                ...state,
                performedAction: AllReduxActions.CLEAR_ALL_CARDOVERVIEW_CARDS,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    requestGetCardOverview: (): AppThunkAction<GetCardOverviewCardsAction> => async (dispatch, getState) => {
        const { isLoading, lastUpdate, lastModified, cards } = getState().cardOverviewReducer as CardOverviewState;

        if (!isLoading && (lastUpdate < lastModified || cards.length === 0)) {
            dispatch(<GetCardOverviewCardsAction>{
                type: GET_CARDOVERVIEW_CARDS,
                payload: {
                    request: {
                        method: "GET",
                        url: "/GetCardOverviewCards",
                    },
                },
            });
        }
    },
    sendCardLock: (request: SendCardLockRequest): SendCardLockAction =>
        <SendCardLockAction>{
            type: SEND_CARD_LOCK,
            payload: {
                request: {
                    method: "POST",
                    url: "/SendCardLock",
                    data: request,
                },
            },
        },
    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_CARDOVERVIEW_CARDS,
        },
};

export type CardOverviewActions = typeof actionCreators;
