import { useState, useEffect } from "react";

export function useCurrentWidth(): number {
    const [width, setWidth] = useState(getWidth());

    useEffect(() => {
        const resizeListener = (): void => {
            setWidth(getWidth());
        };

        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);

    return width;
}

function getWidth (): number {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}
