import PageBase from "components/Shared/PageBase";
import { AppState } from "store/configureStore";
import { NewsState } from "store/News";
import { ErrorStatusCode } from "types";
import { logAnalyticsScreenView, RouteMaps } from "utils/FirebaseAnalytics";
import { useAppDispatch } from "utils/hooks";
import { actionCreators } from "store";
import NewsItem from "../../components/News/NewsItem";
import Pagination from "../../components/Shared/Pagination";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";

interface LocationProps {
    newsId: number;
    newsIndex: number;
}

const TILE_POSITION = "KachelPosition";

const NewsDetails: FunctionComponent = (): ReactElement => {
    const newsState: NewsState = useSelector((state: AppState) => state.newsReducer);
    const [currentNewsIndex, setCurrentNewsIndex] = useState<number>();
    const [currentNewsId, setCurrentNewsId] = useState<number>();
    const dispatch = useAppDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(actionCreators.news.requestGetNews());
    }, []);

    /**
     * render the clicked news item, if the user is coming from the dashboard, else render the first news Item.
     * */
    useEffect(() => {
        if (!location.state) {
            setCurrentNewsIndex(0);
            return setCurrentNewsId(newsState?.news[currentNewsIndex]?.id);
        }
        const { newsId, newsIndex } = location.state as LocationProps;
        setCurrentNewsId(newsId);
        setCurrentNewsIndex(newsIndex);
    }, []);

    /**
     * * PAGINATION-UseEffect
     * go to previous or next news, based on the news Index.
     * */
    useEffect(() => {
        setCurrentNewsId(newsState?.news[currentNewsIndex]?.id);

        if (currentNewsIndex !== undefined) {
            logAnalyticsScreenView({ [TILE_POSITION]: currentNewsIndex + 1 }, RouteMaps.News);
        }
    }, [currentNewsIndex]);

    const hasNextNews = (): boolean => {
        return currentNewsIndex < newsState.news.length - 1;
    };

    const hasPrevNews = (): boolean => {
        return currentNewsIndex > 0;
    };

    const getNextNews = (): void => {
        setCurrentNewsIndex(prevState => {
            if (prevState >= newsState.news.length - 1) {
                return 0;
            }
            return prevState + 1;
        });
    };

    const getPrevNews = (): void => {
        setCurrentNewsIndex(prevState => {
            if (prevState <= 0) {
                return newsState.news.length - 1;
            }
            return prevState - 1;
        });
    };

    const isFetchingNewsFail = (): boolean => {
        return newsState.errorCode !== ErrorStatusCode.noError || !newsState.news || newsState.news?.length === 0;
    };

    return (
        <PageBase
            title="News"
            isContentLoading={newsState.isLoading}
            contentLoadingText="News werden geladen."
            showLoadingIndicator={true}
        >
            {isFetchingNewsFail() ? (
                <div className="m-5 text-center">
                    <strong>News konnten nicht geladen werden.</strong>
                </div>
            ) : (
                <>
                    <NewsItem currentNewsId={currentNewsId} news={newsState.news} />
                    <Pagination
                        getPrevNews={getPrevNews}
                        getNextNews={getNextNews}
                        hasPrevNews={hasPrevNews()}
                        hasNextNews={hasNextNews()}
                    />
                </>
            )}
        </PageBase>
    );
};

export default NewsDetails;
