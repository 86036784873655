//TODO: REMOVE THIS AND CHECK THIS LINE AGAIN AFTER upgrade to react 18
/* eslint-disable react/no-deprecated */

import App from "./pages/App";
import { store, persistor } from "./store/configureStore";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createRoot } from "react-dom/client";

// adding created bootstrap library. Own statements are included
import "../src/styles/scss/main.css";

const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>
);
