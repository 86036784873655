import LandingPage from "pages/LandingPage";
import { AppState } from "store/configureStore";
import React, { ReactElement } from "react";
import { useSelector } from "react-redux";

type PrivateRouteProps = {
    component: React.JSX.Element;
};

/**
 *  Require authentication for private routes
 * - If authenticated: Renders the given component.
 * - If NOT authenticated: Redirects the user to Landing page,
 * @param {PrivateRouteProps} { component: {@link React.JSX.Element} }
 * @return {*}  {ReactElement}
 */
const PrivateRoute = ({ component }: PrivateRouteProps): ReactElement => {
    const authState = useSelector((state: AppState) => state.authReducer);
    return authState.isAuthenticated ? component : <LandingPage />;
};

export default PrivateRoute;
