import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TebonusStarIcon: React.FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 30.73 29.44">
            <path
                fill="white"
                stroke={props.htmlColor}
                strokeMiterlimit={10}
                strokeWidth={1.5}
                d="m29.9 11.31c-.19-.59-.82-1.3-2.73-1.3h-7.43l-2.3-7.07c-.59-1.81-1.44-2.19-2.08-2.19s-1.49.38-2.08 2.19l-2.28 7.06h-7.45c-1.9 0-2.53.71-2.73 1.3s-.09 1.55 1.45 2.7l6 4.37-2.27 7.04c-.59 1.81-.11 2.63.4 3a1.46 1.46 0 0 0 .92.29 3.62 3.62 0 0 0 2.05-.84l6-4.37 6 4.37c1.54 1.12 2.46.92 3 .55s1-1.18.39-3l-2.3-7.07 6-4.37c1.54-1.12 1.63-2.06 1.44-2.66z"
            />
        </SvgIcon>
    );
};

export default TebonusStarIcon;
