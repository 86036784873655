import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import PinterestIcon from "@material-ui/icons/Pinterest";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";

export const linksSocialMedia = [
    {
        title: <FacebookIcon style={{ fontSize: "1.5rem" }} />,
        htmlLink: "https://www.facebook.com/tegut",
    },
    {
        title: <InstagramIcon style={{ fontSize: "1.5rem" }} />,
        htmlLink: "https://www.instagram.com/tegut/",
    },
    {
        title: <PinterestIcon style={{ fontSize: "1.5rem" }} />,
        htmlLink: "https://www.pinterest.de/tegut_gutelebensmittel/",
    },
    {
        title: <YouTubeIcon style={{ fontSize: "1.5rem" }} />,
        htmlLink: "https://www.youtube.com/user/TEGUT",
    },
    {
        title: <TwitterIcon style={{ fontSize: "1.5rem" }} />,
        htmlLink: "https://twitter.com/tegut",
    },
];
export const linksFooter = [
    {
        title: "Kontakt",
        htmlLink: "https://www.tegut.com/kontakt.html",
        externalLink: true,
    },
    {
        title: "Impressum",
        htmlLink: "/Impressum",
        externalLink: false,
    },
    {
        title: "Teilnahmebedingungen",
        htmlLink: "/Teilnahmebedingungen",
        externalLink: false,
    },
    {
        title: "Datenschutz",
        htmlLink: "/Datenschutz",
        externalLink: false,
    },
    {
        title: "Sitemap",
        htmlLink: "https://www.tegut.com/sitemap.html",
        externalLink: true,
    },
];
