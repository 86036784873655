import RequestStatus from "utils/RequestStatus";
import { AppThunkAction } from "store";
import { wasUpdatedThisMonth } from "utils/CheckLastUpdate";
import { AllReduxActions, AxiosRequestError, BaseType, Documents, ErrorStatusCode, ReduxBaseType } from "../types";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";

// Actions
const GET_DOCUMENTS = "pekuma/documents/GET_DOCUMENTS";
const GET_DOCUMENTS_SUCCESS = "pekuma/documents/GET_DOCUMENTS_SUCCESS";
const GET_DOCUMENTS_FAIL = "pekuma/documents/GET_DOCUMENTS_FAIL";

interface GetDocumentsResponse {
    documents: Documents[];
    lastUpdate: number;
}

export interface GetDocumentsAction {
    type: typeof GET_DOCUMENTS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetDocumentsSuccessAction {
    type: typeof GET_DOCUMENTS_SUCCESS;
    payload: AxiosResponse<GetDocumentsResponse>;
}

interface GetDocumentsFailAction {
    type: typeof GET_DOCUMENTS_FAIL;
    error: AxiosRequestError;
}

export interface DocumentState extends BaseType, ReduxBaseType {
    documents: Documents[];
}

export type KnownAction = BaseKnownAction | GetDocumentsAction | GetDocumentsSuccessAction | GetDocumentsFailAction;

export type SuccessAction = GetDocumentsSuccessAction;

export type ErrorAction = GetDocumentsFailAction;

export const INITIAL_STATE: DocumentState = {
    documents: [],
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdate: 0,
    performedAction: null,
};

// Reducer
export const reducer: Reducer<DocumentState> = (state = INITIAL_STATE, action: KnownAction): DocumentState => {
    switch (action.type) {
        case GET_DOCUMENTS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_DOCUMENTS,
            };
        }
        case GET_DOCUMENTS_SUCCESS: {
            const { lastUpdate, documents } = action.payload.data;
            return {
                ...state,
                isLoading: false,
                documents,
                errorCode: ErrorStatusCode.noError,
                lastUpdate,
                performedAction: AllReduxActions.GET_DOCUMENTS_SUCCESS,
            };
        }
        case GET_DOCUMENTS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_DOCUMENTS_FAIL,
            };
        }
        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
};

export const actionCreators = {
    ...baseActionCreators,
    requestLegalDocuments: (): AppThunkAction<GetDocumentsAction> => async (dispatch, getState) => {
        const { lastUpdate, isLoading } = getState().documentsReducer as DocumentState;

        if (!wasUpdatedThisMonth(lastUpdate) && !isLoading) {
            dispatch(<GetDocumentsAction>{
                type: GET_DOCUMENTS,
                payload: {
                    request: {
                        method: "GET",
                        url: "/GetLegalDocuments",
                        params: {
                            appType: "tebonus",
                            sendAgbsAsPdf: false,
                            documentSource: 1, // 1 is for web and 2 is for app
                        },
                    },
                },
            });
        }
    },
};

export type DocumentActions = typeof actionCreators;
