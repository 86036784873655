import { ExpandedPurchaseProps } from "./types";
import React, { FunctionComponent, ReactElement } from "react";

export const ExpandedPurchaseMobile: FunctionComponent<ExpandedPurchaseProps> = (props): ReactElement => {
    return (
        <>
            <tr className="font-unitText-Bold d-md-none">
                <td colSpan={4} className="paddingNoBorderTable">
                    {`${props.transaction.reason} um ${props.receiptDate}`}
                </td>
            </tr>
            {props.storeName && (
                <tr className="font-unitText-Bold d-md-none">
                    <td colSpan={4} className="paddingNoBorderTable">
                        {`tegut… Markt ${props.storeName}`}
                    </td>
                </tr>
            )}
            <tr className="font-unitText-Bold noPaddingNoBorderTable d-md-none">
                <td colSpan={2}>Bonussumme:</td>
                <td colSpan={2}>{props.transaction.totalPrice}€</td>
            </tr>
            <tr className="paddingNoBorderTable d-md-none">
                <td colSpan={2}>Punktefähige Summe*:</td>
                <td colSpan={2}>{props.transaction.totalDiscountablePrice}€</td>
            </tr>
            {/* space */}
            <tr className="font-unitText-Bold noPaddingNoBorderTable d-md-none">
                <td colSpan={2}>Gesammelte Punkte:</td>
                <td colSpan={2}>{props.transaction.totalPointsEarned}</td>
            </tr>
            <tr className="noPaddingNoBorderTable d-md-none">
                <td colSpan={2}>Basispunkte:</td>
                <td colSpan={2}>{props.transaction.basePointsEarned}</td>
            </tr>
            <tr className="noPaddingNoBorderTable d-md-none">
                <td colSpan={2}>Bonuspunkte:</td>
                <td colSpan={2}>{props.transaction.bonusPoints}</td>
            </tr>

            {/* Create a row for each incentive */}
            {props.transaction.incentiveDetails.map((incentive, i) => {
                return (
                    <tr key={i} className="paddingNoBorderTable d-md-none">
                        <td colSpan={2}>
                            <div className="truncate-2">{incentive.name}</div>
                        </td>
                        <td colSpan={2}>{incentive.points}</td>
                    </tr>
                );
            })}

            <tr className="paddingNoBorderTable d-md-none">
                <td colSpan={2}>Eingelöste Punkte:</td>
                <td colSpan={2}>{props.transaction.totalPointsSpent}</td>
            </tr>
            {/* space */}
            <tr className="noPaddingNoBorderTable font-unitText-Bold d-md-none">
                <td colSpan={2}>Punktestand nach Einkauf:</td>
                <td colSpan={2}>{props.transaction.totalPointsAfterTransaction}</td>
            </tr>
            <tr className="paddingNoBorderTable d-md-none">
                <td colSpan={2}>Punktestand vor Einkauf:</td>
                <td colSpan={2}>{props.transaction.totalPointsBeforeTransaction}</td>
            </tr>
            {/* space */}
            <tr className="paddingNoBorderTable d-md-none">
                <td colSpan={4} className="conditionText">
                    {props.exceptionsText}
                </td>
            </tr>
        </>
    );
};
