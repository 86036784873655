import React, { FunctionComponent } from "react";

interface StepNavigationProps {
    className?: string;
    numberOfItems: number;
    selectedIndex: number;
    jumpTo: Function;
}

/**
 * simple indicator and navigator for lists to display its current index and jump to another
 * @param props
 * @returns
 */
export const StepNavigation: FunctionComponent<StepNavigationProps> = props => {
    const renderIndicators = (): React.ReactNode => {
        const indicators: React.ReactNode = [];

        for (let index = 0; index < props.numberOfItems; index++) {
            indicators[index] = (
                <span
                    key={index}
                    style={{
                        backgroundColor: props.selectedIndex === index ? "#fff" : "rgba(255, 255, 255, 0.5)",
                        height: 10,
                        width: 10,
                        borderRadius: 5,
                        marginRight: index != props.numberOfItems - 1 ? 8 : 0,
                        cursor: "pointer",
                        transition: "all 400ms ease-in-out",
                    }}
                    tabIndex={0}
                    onKeyUp={e => {
                        if (e.key === "Enter") {
                            props.jumpTo(index);
                        }
                    }}
                    onClick={() => {
                        props.jumpTo(index);
                    }}
                ></span>
            );
        }

        return indicators;
    };

    return (
        <div
            className={props.className}
            style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
        >
            {renderIndicators()}
        </div>
    );
};

export default StepNavigation;
