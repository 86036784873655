import { TebonusBagIcon, TebonusSingleHeart, TebonusStarIcon } from "components/_graphics";
import ArticleMultipliers from "pages/ArticleMultipliers";
import ChangeEmail from "pages/ChangeEmail";
import ChangePassword from "pages/ChangePassword";
import ChangePersonalData from "pages/ChangePersonalData";
import FaqsDetails from "pages/Faqs";
import Flyer from "pages/Flyer";
import MyTebonus from "pages/MyTebonus";
import NewsDetails from "pages/News";
import PhysicalCard from "pages/PhysicalCard";
import PrivacySettings from "pages/PrivacySettings";
import PromotionsPage from "pages/Promotions";
import RegistrationConfirmation from "pages/RegistrationConfirmation";
import ScoreOverview from "pages/ScoreOverview";
import WeeklyHits from "pages/WeeklyHits";
import { Colors } from "styles";
import React from "react";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// All navigation links are set in this document. All menus collect the data from here and display it.
// All list except the linksMenu (first one) can be adjusted from the size while the menus will be adjusted automatically.

// Size of the icon which is next to the link in the mobile menu
const menuIconSize = 25 * 0.7;

export type LinkInfo = {
    title: string;
    htmlLink: string;
    component: React.JSX.Element;
    icon?: React.JSX.Element;
    color?: string;
    externalLink?: boolean;
    hiddenInMenu?: boolean;
};

// Not dynamic of the size in the menus
export const linksMenu: LinkInfo[] = [
    {
        title: "Dashboard",
        htmlLink: "/",
        component: <MyTebonus />,
    },
    {
        title: "Mein tegut… Konto",
        htmlLink: "/Konto",
        component: <MyTebonus />,
    },
    {
        title: "Nützliches",
        htmlLink: "/Nuetzliches",
        component: <MyTebonus />,
    },
];

// Following const list are maintained fully automatically in all menus
export const linksSubMenu_MyTebonus: LinkInfo[] = [
    {
        title: "Startseite",
        htmlLink: "/",
        icon: <TebonusSingleHeart preserveAspectRatio={true} size={menuIconSize} color="white" />,
        component: <MyTebonus />,
    },
    {
        title: "Punkteübersicht",
        htmlLink: "/meintebonus/Punkteuebersicht",
        icon: <TebonusSingleHeart preserveAspectRatio={true} size={menuIconSize} color="white" />,
        component: <ScoreOverview />,
    },
    {
        title: "Lieblingsprodukte",
        htmlLink: "/meintebonus/Lieblingsprodukte",
        icon: <TebonusSingleHeart preserveAspectRatio={true} size={menuIconSize} color="white" />,
        component: <ArticleMultipliers />,
    },
    {
        title: "Aktionsprodukte",
        htmlLink: "/meintebonus/Aktionsprodukte",
        icon: <TebonusBagIcon htmlColor="orange" />,
        component: <PromotionsPage />,
    },
    {
        title: "Wochen-Hits",
        htmlLink: "/meintebonus/Wochen-Hits",
        icon: <TebonusStarIcon htmlColor="white" />,
        component: <WeeklyHits />,
    },
    {
        title: "Aktuelle News",
        htmlLink: "/meintebonus/News",
        component: <NewsDetails />,
    },
    {
        title: "Prospekte",
        htmlLink: "/meintebonus/prospekt",
        component: <Flyer />,
        hiddenInMenu: true,
    },
    {
        title: "tebonus Karte",
        htmlLink: "/meintebonus/Karte",
        component: <PhysicalCard />,
    },
];

export const linksSubMenu_Account: LinkInfo[] = [
    {
        title: "Persönliche Daten",
        htmlLink: "/Konto/PersoenlicheDaten",
        component: <ChangePersonalData />,
    },
    {
        title: "Passwort ändern",
        htmlLink: "/Konto/PasswortAendern",
        component: <ChangePassword />,
    },
    {
        title: "E-Mail Adresse ändern",
        htmlLink: "/Konto/EMailAdresseAendern",
        component: <ChangeEmail />,
    },
    {
        title: "Registrierungsbestätigung",
        htmlLink: "/Konto/Registrierungsbestaetigung",
        icon: <FiberManualRecord htmlColor={Colors.orange} fontSize="small" />,
        component: <RegistrationConfirmation />,
    },
    {
        title: "Privatsphäre-Einstellungen",
        htmlLink: "/Konto/PrivatsphaereEinstellungen",
        component: <PrivacySettings />,
    },
];

export const linksSubMenu_Usefull: LinkInfo[] = [
    {
        title: "Kontakt",
        htmlLink: "https://www.tegut.com/kontakt.html",
        component: null,
        externalLink: true,
    },
    {
        title: "FAQ",
        htmlLink: "/Nuetzliches/FAQ",
        component: <FaqsDetails />,
    },
];
