import { AppThunkAction } from "store";
import { wasUpdatedThisWeek } from "utils/CheckLastUpdate";
import { AllReduxActions, AxiosRequestError, BaseType, ErrorStatusCode, News, ReduxBaseType } from "../types";
import RequestStatus from "../utils/RequestStatus";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";

const GET_NEWS = "pekuma/news/GET_NEWS";
const GET_NEWS_SUCCESS = "pekuma/news/GET_NEWS_SUCCESS";
const GET_NEWS_FAIL = "pekuma/news/GET_NEWS_FAIL";

interface GetNewsResponse {
    news: News[];
}

export interface GetNewsAction {
    type: typeof GET_NEWS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetNewsSuccessAction {
    type: typeof GET_NEWS_SUCCESS;
    payload: AxiosResponse<GetNewsResponse>;
}

interface GetNewsFailAction {
    type: typeof GET_NEWS_FAIL;
    error: AxiosRequestError;
}

export interface NewsState extends BaseType, ReduxBaseType {
    news: News[];
}

export type KnownAction = BaseKnownAction | GetNewsAction | GetNewsFailAction | GetNewsSuccessAction;

export type ErrorAction = GetNewsFailAction;

export const INITIAL_STATE: NewsState = {
    news: [],
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdate: 0,
    performedAction: null,
};

// Reducer
export const reducer: Reducer<NewsState> = (state = INITIAL_STATE, action: KnownAction): NewsState => {
    switch (action.type) {
        case GET_NEWS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_NEWS,
            };
        }
        case GET_NEWS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_NEWS_FAIL,
            };
        }
        case GET_NEWS_SUCCESS: {
            const { news } = action.payload.data;
            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                news: news ? news.sort((a, b) => a.position - b.position) : [],
                lastUpdate: dayjs().unix(),
                performedAction: AllReduxActions.GET_NEWS_SUCCESS,
            };
        }
        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.CLEAR_NEWS_LOADING_STATE,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    ...baseActionCreators,
    requestGetNews: (): AppThunkAction<GetNewsAction> => async (dispatch, getState) => {
        const { lastUpdate, news, isLoading } = getState().newsReducer as NewsState;

        if (!isLoading && (!wasUpdatedThisWeek(lastUpdate) || news.length === 0)) {
            dispatch(<GetNewsAction>{
                type: GET_NEWS,
                payload: {
                    request: {
                        method: "GET",
                        url: "/GetNews",
                    },
                },
            });
        }
    },
};

export type NewsActions = typeof actionCreators;
