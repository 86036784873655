import { AppThunkAction } from "store";
import { ArticleCategory, AxiosRequestError, BaseType, ErrorStatusCode, ReduxBaseType } from "types";
import { getAppSetting } from "utils/AppSettings";
import { wasUpdatedThisMonth } from "utils/CheckLastUpdate";
import RequestStatus from "utils/RequestStatus";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const REACT_APP_DATASERVICE_URL = getAppSetting("REACT_APP_DATASERVICE_URL");

const GET_ARTICLE_CATEGORIES = "tebonus/articles/GET_ARTICLE_CATEGORIES";
const GET_ARTICLE_CATEGORIES_SUCCESS = "tebonus/articles/GET_ARTICLE_CATEGORIES_SUCCESS";
const GET_ARTICLE_CATEGORIES_FAIL = "tebonus/articles/GET_ARTICLE_CATEGORIES_FAIL";

interface GetArticleResponse {
    categories: ArticleCategory[];
    lastUpdate: number;
}

interface GetArticleCategoriesAction {
    type: typeof GET_ARTICLE_CATEGORIES;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetArticleCategoriesSuccessAction {
    type: typeof GET_ARTICLE_CATEGORIES_SUCCESS;
    payload: AxiosResponse<GetArticleResponse>;
}

interface GetArticleCategoriesFailAction {
    type: typeof GET_ARTICLE_CATEGORIES_FAIL;
    error: AxiosRequestError;
}

export interface ArticleCategoriesState extends BaseType, ReduxBaseType {
    categories: ArticleCategory[];
    lastUpdate: number;
}

export type KnownAction =
    | BaseKnownAction
    | GetArticleCategoriesAction
    | GetArticleCategoriesSuccessAction
    | GetArticleCategoriesFailAction;

export type ErrorAction = GetArticleCategoriesFailAction;

const INITIAL_STATE: ArticleCategoriesState = {
    categories: [],
    lastUpdate: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
};

export const reducer: Reducer<ArticleCategoriesState> = (
    state = INITIAL_STATE,
    action: KnownAction
): ArticleCategoriesState => {
    switch (action.type) {
        case GET_ARTICLE_CATEGORIES: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
            };
        }

        case GET_ARTICLE_CATEGORIES_SUCCESS: {
            const { categories } = action.payload.data;

            return {
                ...state,
                isLoading: false,
                categories,
                lastUpdate: Date.now(),
                errorCode: ErrorStatusCode.noError,
            };
        }

        case GET_ARTICLE_CATEGORIES_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
            };
        }

        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }

        default:
            return state;
    }
};

export const actionCreators = {
    ...baseActionCreators,
    requestGetArticleCategories: (): AppThunkAction<GetArticleCategoriesAction> => async (dispatch, getState) => {
        const { isLoading, lastUpdate, categories } = getState().articleCategoriesReducer as ArticleCategoriesState;
        if (!isLoading && (!wasUpdatedThisMonth(lastUpdate) || categories.length === 0)) {
            dispatch(<GetArticleCategoriesAction>{
                type: GET_ARTICLE_CATEGORIES,
                payload: {
                    request: {
                        method: "GET",
                        url: `${REACT_APP_DATASERVICE_URL}/GetOnlineArticleCategories/true`,
                    },
                },
            });
        }
    },
};

export type ArticleCategoryActions = typeof actionCreators;
