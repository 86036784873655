import LoadingIndicator from "components/LoadingIndicator";
import { isLoadingCustomerScore } from "utils/LoadingCustomerScore";
import React, { FunctionComponent, ReactElement } from "react";

interface TotalScoreProps {
    isLoading: boolean;
    currentScore: number;
}

export const TotalScore: FunctionComponent<TotalScoreProps> = (props): ReactElement => {
    return (
        <div className="row justify-content-center text-center font-subheading">
            <div className="col-8">
                <div className="font-subheading-regular d-flex justify-content-center">
                    <div className="mr-2">Ihr Punktestand:</div>
                    {isLoadingCustomerScore(props.currentScore, props.isLoading) ? (
                        <div className="ml-2" style={{ fontSize: "1rem" }}>
                            <LoadingIndicator isPositionStatic={true} />
                        </div>
                    ) : (
                        <strong>{props.currentScore}</strong>
                    )}
                </div>
            </div>
        </div>
    );
};
