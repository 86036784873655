import "dayjs/locale/de";
import { CommonButtonTitles, logAnalyticsButton, logAnalyticsScreenView } from "utils/FirebaseAnalytics";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import TerminateTebonus, { terminationReceived } from "components/terminateTebonus";
import PrivacyPolicy from "pages/PrivacyPolicy";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ArticleMultiplierState } from "store/ArticleMultipliers";
import { IAuthState } from "store/Auth";
import { CardOverviewState, isPhysicalCardOrdered } from "store/CardOverview";
import { CustomerScoreState } from "store/CustomerScore";
import { CustomerState } from "store/Customers";
import { DocumentState } from "store/Documents";
import { NewsState } from "store/News";
import { MonthPromotionState } from "store/MonthPromotions";
import { isTokenTebonusTerminated } from "utils/AuthHelper";
import { CARD_NAV_TITLE, hasPhysicalCard } from "utils/CardSubMenu";
import PrivateRoute from "navigation/PrivateRoute";
import ScrollToTop from "components/ScrollToTop";
import { WeekPromotionsState } from "store/WeekPromotions";
import GoToAppAlert from "../components/GoToApp";
import NavBar from "../components/NavBar";
import NavBarMobile from "../components/NavBar/NavBarMobile";
import TrackingConsentModal from "../components/Shared/TrackingConsentModal";
import TokenRefresh from "../components/TokenRefresh";
import ModalConfirmInfo from "../components/_overlays/ConfirmationModal";
import OnboardingModal from "../components/_overlays/OnboardingModal";
import Footer from "../components/_tegut/TegutFooter";
import SubMenu from "../dashboard/Menu";
import * as NavLinks from "../navigation";
import { actionCreators } from "../store";
import { AppState } from "../store/configureStore";
import { AllReduxActions, ErrorStatusCode } from "../types";
import ArticleFinder from "./ArticleFinder";
import Imprint from "./Imprint";
import ParticipationCondition from "./ParticipationCondition";
import SigninOidc from "./SignIn";
import Update from "./Update";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";
import { Navigate, Route, RouterProvider, Routes, createBrowserRouter, useLocation } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.locale("de");

function App(): React.JSX.Element {
    const router = createBrowserRouter([{ path: "*", Component: Root }]);
    return <RouterProvider router={router} />;
}

const Root: FunctionComponent = (): ReactElement => {
    const authState: IAuthState = useAppSelector((state: AppState) => state.authReducer);
    const dispatch = useAppDispatch();
    const location = useLocation();

    // List of all failed api call actions. This list is used to know which calls have to be repeated
    const [failedActions, setFailedActions] = useState<AllReduxActions[]>([]);
    const [isTerminateButtonPressed, setTerminateButtonPressed] = useState<boolean>(false);

    const sessionStorageKey = "showRegistrationCardModal";
    const sessionStorageValue = JSON.parse(sessionStorage.getItem(sessionStorageKey));
    const [showRegistrationCardModal, setShowRegistrationCardModal] = useState<boolean>(sessionStorageValue);

    // Reload data if the fetch was not successffull and display corresponding error modal
    const monthPromotionState: MonthPromotionState = useAppSelector((state: AppState) => state.monthPromotionReducer);
    const weekPromotionsState: WeekPromotionsState = useAppSelector((state: AppState) => state.weekPromotionReducer);
    const articleMultiplierState: ArticleMultiplierState = useAppSelector((state: AppState) => state.multiplierReducer);
    const customerState: CustomerState = useAppSelector((state: AppState) => state.customerReducer);
    const customerScoreState: CustomerScoreState = useAppSelector((state: AppState) => state.customerScoreReducer);
    const newsState: NewsState = useAppSelector((state: AppState) => state.newsReducer);
    const documentsState: DocumentState = useAppSelector((state: AppState) => state.documentsReducer);
    const cardOverviewState: CardOverviewState = useAppSelector((state: AppState) => state.cardOverviewReducer);

    // state with the corresponding action
    // List of all states which are should be checked when a state has changed
    const StateActions = [
        articleMultiplierState,
        customerState,
        customerScoreState,
        monthPromotionState,
        weekPromotionsState,
        newsState,
        documentsState,
        cardOverviewState,
    ];

    // Redux actions which should be opened in an error modal to be reloaded when it was not possible to load them.
    // Activate or set actions are excluded because these have a separate error modal
    const actionsToReload = [
        AllReduxActions.GET_MONTH_PROMOTIONS_FAIL,
        AllReduxActions.GET_WEEK_PROMOTIONS_FAIL,
        AllReduxActions.GET_ARTICLE_MULTIPLIERS_FAIL,
        AllReduxActions.GET_CUSTOMER_INFO_FAIL,
        AllReduxActions.GET_CUSTOMER_SCORE_FAIL,
        AllReduxActions.GET_NEWS_FAIL,
        AllReduxActions.GET_DOCUMENTS_FAIL,
        AllReduxActions.GET_CARDOVERVIEW_CARDS_FAIL,
    ];

    // #region useEffects

    useEffect(() => {
        window.addEventListener("beforeunload", handleLoadingStates);

        return () => window.removeEventListener("beforeunload", handleLoadingStates);
    }, []);

    // Log Analytics Screen View
    useEffect(() => {
        if (authState.isAuthenticated && customerState.isTrackingConsentSet && !showOnboardingModal()) {
            logAnalyticsScreenView();
        }
    }, [location.pathname]);

    // Check in all states if an error has occoured and which action was performed to trigger it. Set the setFailedAction state accordingly with only unique values.
    useEffect(
        () => {
            StateActions.map(item => {
                // Add the failed action to the list when an error occoured and it was one of the desired actions which should be handled by the error pop up
                if (
                    item.errorCode !== ErrorStatusCode.noError &&
                    actionsToReload.includes(item.performedAction) &&
                    !failedActions.includes(item.performedAction) &&
                    authState.isAuthenticated
                ) {
                    setFailedActions(failedActions.concat(item.performedAction));
                }
            });
        },
        StateActions.map(item => item.errorCode)
    );

    if (sessionStorageValue == null) {
        sessionStorage.setItem(sessionStorageKey, "true");
        setShowRegistrationCardModal(true);
    }

    useEffect(() => {
        dispatch(actionCreators.documents.clearLoadingState());
        dispatch(actionCreators.documents.requestLegalDocuments());

        if (!authState.isAuthenticated) {
            // reset/ set the inital values if the user is not authenticated
            setFailedActions([]);
            dispatch(actionCreators.news.clearLoadingState());
            dispatch(actionCreators.faqs.clearLoadingState());
            dispatch(actionCreators.customers.clearAll());
            dispatch(actionCreators.customerScore.clearAll());
            dispatch(actionCreators.articleMultipliers.clearAll());
            dispatch(actionCreators.cardOverview.clearAll());
        }

        if (authState.isAuthenticated) {
            setFailedActions([]);
            // fetch the whole data
            dispatch(actionCreators.customers.getCustomerInfo());

            /**
             * The cards will be dispatched with a delay because if the user creates a new account the engine needs a moment
             * to create and to provide the information with the physical card at the endpoint. This information about the
             * physical card is needed to show the onboarding pop up that a physical card was sent to the customer.
             */
            setTimeout(() => {
                dispatch(actionCreators.cardOverview.requestGetCardOverview());
            }, 3000);
        }
    }, [authState.isAuthenticated]);

    useEffect(() => {
        if (customerState.performedAction === AllReduxActions.SET_TRACKING_CONSENT_SUCCESS) {
            dispatch(actionCreators.customers.getCustomerInfo());
        }
    }, [customerState.performedAction]);

    useEffect(() => {
        const { tokenResponse } = authState;

        if (tokenResponse && tokenResponse.accessToken && isTokenTebonusTerminated(tokenResponse.accessToken)) {
            sessionStorage.clear();
            localStorage.clear();
            dispatch(actionCreators.auth.logout());
        }
    }, [authState.tokenResponse]);

    // #endregion

    // #region functions

    const handleLoadingStates = (): void => {
        const isLoadingStates = StateActions.map(state => state.isLoading);
        if (isLoadingStates.includes(true)) {
            clearLoadingStates();
        }
    };

    const clearLoadingStates = (): void => {
        dispatch(actionCreators.news.clearLoadingState());
        dispatch(actionCreators.faqs.clearLoadingState());
        dispatch(actionCreators.articleMultipliers.clearLoadingState());
        dispatch(actionCreators.customers.clearLoadingState());
        dispatch(actionCreators.customerScore.clearLoadingState());
        dispatch(actionCreators.monthPromotion.clearLoadingState());
        dispatch(actionCreators.weekPromotion.clearLoadingState());
        dispatch(actionCreators.articleCategories.clearLoadingState());
        dispatch(actionCreators.documents.clearLoadingState());
    };

    /**
     * determines wether the onboarding-modal should be shown
     * @returns boolean-value if modal should be shown
     */
    const showOnboardingModal = (): boolean => {
        const registrationDate = dayjs.utc(customerState.registrationDate);

        if (!registrationDate.isValid()) {
            return null;
        }

        // 1 hour ago.
        const dateFrom = dayjs.utc().subtract(1, "hour");

        // now
        const dateTo = dayjs.utc();

        // true if the registrationDate is in between now and 1 hour ago.
        const isDateBetween = registrationDate.isBetween(dateFrom, dateTo, null, "[]");

        return (
            isDateBetween &&
            isPhysicalCardOrdered(cardOverviewState.cards) &&
            showRegistrationCardModal &&
            customerState.isTrackingConsentSet
        );
    };

    /**
     * Renders a modal that is displayed to the user if an timeout error occured during fetching the data
     * (returned by the redux store).
     * In case of timeout, month or week promotions shall be loaded again.
     * @returns a modal that is shown to the user in case of errors
     */
    function renderTimeoutErrorModal(): ReactElement {
        return (
            failedActions.length > 0 && (
                <ModalConfirmInfo
                    titleText="Etwas ist schiefgelaufen"
                    bodyText="Ihre Aktion konnte leider nicht bearbeitet werden. Bitte sorgen Sie für eine stabile Internetverbindung."
                    button1Text="Erneut versuchen"
                    button1function={() => {
                        // reload failed actions
                        failedActions.map(action => {
                            switch (action) {
                                case AllReduxActions.GET_MONTH_PROMOTIONS_FAIL:
                                    dispatch(actionCreators.monthPromotion.requestGetMonthPromotions());
                                    break;
                                case AllReduxActions.GET_WEEK_PROMOTIONS_FAIL:
                                    dispatch(actionCreators.weekPromotion.requestGetWeekPromotions());
                                    break;
                                case AllReduxActions.GET_ARTICLE_MULTIPLIERS_FAIL:
                                    dispatch(actionCreators.articleMultipliers.requestGetArticleMultipliers());
                                    break;
                                case AllReduxActions.GET_CUSTOMER_INFO_FAIL:
                                    dispatch(actionCreators.customers.getCustomerInfo());
                                    break;
                                case AllReduxActions.GET_CUSTOMER_SCORE_FAIL:
                                    dispatch(actionCreators.customerScore.requestGetCustomerScore());
                                    break;
                                case AllReduxActions.GET_NEWS_FAIL:
                                    dispatch(actionCreators.news.requestGetNews());
                                    break;
                                case AllReduxActions.GET_DOCUMENTS_FAIL:
                                    dispatch(actionCreators.documents.requestLegalDocuments());
                                    break;
                                case AllReduxActions.GET_CARDOVERVIEW_CARDS_FAIL:
                                    dispatch(actionCreators.cardOverview.requestGetCardOverview());
                                    break;
                            }
                        });
                        setFailedActions([]);
                    }}
                    button2Text="Abbrechen"
                    button2function={() => setFailedActions([])}
                />
            )
        );
    }

    const setTerminateButton = (isPressed: boolean): void => {
        setTerminateButtonPressed(isPressed);

        logAnalyticsButton({
            button_title: CommonButtonTitles.termination.terminateCustomer,
        });
    };

    const onLogoutButtonClicked = (): void => {
        logAnalyticsButton({
            button_title: CommonButtonTitles.authorization.logout,
        });

        logout();
    };

    const logout = (): void => {
        sessionStorage.clear();

        // clear whole storage when the user terminated tebonus
        if (JSON.parse(sessionStorage.getItem(terminationReceived))) {
            localStorage.clear();
        }

        dispatch(actionCreators.auth.logout());

        <Navigate to="/" />;
    };

    const login = (): void => {
        dispatch(actionCreators.auth.redirect());
    };

    const isRouteEnabled = (navTitle: string): boolean => {
        if (navTitle === CARD_NAV_TITLE) {
            return hasPhysicalCard(cardOverviewState.cards);
        }

        return true;
    };

    // #endregion

    return (
        <>
            <ScrollToTop />
            <TokenRefresh />
            <GoToAppAlert />
            <NavBar login={login} logout={onLogoutButtonClicked} isAuthenticated={authState.isAuthenticated} />
            <NavBarMobile
                login={login}
                logout={onLogoutButtonClicked}
                isAuthenticated={authState.isAuthenticated}
                isCustomerRegistrationConfirmed={customerState.isEmailConfirmed}
                customerRegistrationDate={customerState.registrationDate}
                isConfirmationMailSent={customerState.isConfirmationSentSuccessfully}
            />

            {showOnboardingModal() && (
                <OnboardingModal
                    submitButtonAction={() => {
                        sessionStorage.setItem(sessionStorageKey, "false");
                        setShowRegistrationCardModal(false);
                    }}
                />
            )}

            <div className="container-xl pt-2 container-fluid-mobile mt-lg-2">
                {/* m-0 prevents the row to become larger than the screen */}
                <div className="row m-0">
                    {/* Menu on the left side which will be hidden if the screen is small */}
                    {authState.isAuthenticated && (
                        <div className="d-none d-lg-block col-lg-2 grid-gutter-y">
                            <SubMenu
                                isCustomerRegistrationConfirmed={customerState.isEmailConfirmed}
                                customerRegistrationDate={customerState.registrationDate}
                                isConfirmationMailSent={customerState.isConfirmationSentSuccessfully}
                            />
                        </div>
                    )}
                    {/* Dashboard were the content will be changed */}
                    <div className={`${authState.isAuthenticated ? "col-lg-10" : ""} col-12 p-lg-0 p-0`}>
                        {/* display error modals. Which will be centered on the detail tiles*/}
                        {renderTimeoutErrorModal()}
                        <TerminateTebonus
                            isTerminateButtonPressed={isTerminateButtonPressed}
                            setTerminateButtonPressed={setTerminateButtonPressed}
                            logout={logout}
                        ></TerminateTebonus>
                        <Routes>
                            <Route path="/" element={<PrivateRoute component={NavLinks.linksMenu[0].component} />} />
                            {/* Menu Titles */}
                            {NavLinks.linksMenu.map((item, i) => (
                                <Route key={i} path={item.htmlLink} element={<PrivateRoute component={item.component} />} />
                            ))}
                            {/* Dashboard */}
                            {NavLinks.linksSubMenu_MyTebonus.map(
                                (item, i) =>
                                    isRouteEnabled(item.title) && (
                                        <Route
                                            key={i}
                                            path={item.htmlLink}
                                            element={<PrivateRoute component={item.component} />}
                                        />
                                    )
                            )}
                            {/* Mein tegut... Konto */}
                            {NavLinks.linksSubMenu_Account.map((item, i) => (
                                <Route key={i} path={item.htmlLink} element={<PrivateRoute component={item.component} />} />
                            ))}
                            {/* Nützliches */}
                            {NavLinks.linksSubMenu_Usefull.map((item, i) => (
                                <Route key={i} path={item.htmlLink} element={<PrivateRoute component={item.component} />} />
                            ))}
                            <Route path="/ArticleFinder" element={<PrivateRoute component={<ArticleFinder />} />} />
                            <Route path="/Impressum" element={<Imprint />} />
                            <Route path="/Datenschutz" element={<PrivacyPolicy />} />
                            <Route path="/Teilnahmebedingungen" element={<ParticipationCondition />} />
                            <Route path="/Update" element={<Update />} />
                            {/* Necessary for login */}
                            <Route path="/signin-oidc" element={<SigninOidc />} />
                            {/* Redirect to home when the page could not be found */}
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </div>
                </div>
            </div>
            <Footer setTerminateButton={setTerminateButton} isAuthenticated={authState.isAuthenticated} />
            <TrackingConsentModal />
        </>
    );
};

export default App;
