import { DashboardWeeklyHitsHeader } from "components/WeeklyHits/Dashboard/DashboardWeeklyHitsHeader";
import { WeeklyHitsCarousel } from "components/WeeklyHits/Dashboard/WeeklyHitsCarousel";
import { actionCreators } from "store";
import { WeekPromotionsState } from "store/WeekPromotions";
import { useAppDispatch } from "utils/hooks";
import * as NavLinks from "../../navigation";
import { AppState } from "../../store/configureStore";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

const WeeklyHits: React.FunctionComponent = (): React.ReactElement => {
    const weeklyHits: WeekPromotionsState = useSelector((state: AppState) => state.weekPromotionReducer);
    const tegutWeeklyHitsName = "Wochen-Hits";
    const WEEKLY_HITS_LINK = NavLinks.linksSubMenu_MyTebonus.find(p => p.title === tegutWeeklyHitsName).htmlLink;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionCreators.weekPromotion.requestGetWeekPromotions());
    }, []);

    return (
        <div className="container-lg">
            <div className="row align-items-center">
                <DashboardWeeklyHitsHeader weekPromotions={weeklyHits.weekPromotions} weeklyHitsLink={WEEKLY_HITS_LINK} />
                <WeeklyHitsCarousel
                    isLoading={weeklyHits.isLoading}
                    weekPromotions={weeklyHits.weekPromotions}
                    weeklyHitsLink={WEEKLY_HITS_LINK}
                />
            </div>
        </div>
    );
};

export default WeeklyHits;
