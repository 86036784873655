import { AppState } from "store/configureStore";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { IAuthState, actionCreators } from "../../store/Auth";
import React, { FunctionComponent, useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";

const SigninOidc: FunctionComponent = (): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const authState: IAuthState = useAppSelector((state: AppState) => state.authReducer);

    useEffect(() => {
        const userAuthentication = async (): Promise<void> => {
            await dispatch(actionCreators.userAuthentication());

            // load the desired landing page after the user logged in
            navigate(authState.desiredLandingURL);
        };

        userAuthentication();
    });

    return <></>;
};

export default SigninOidc;
