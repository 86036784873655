import ProductTile, { ProductStatus } from "components/Shared/ProductTile";
import { filterActiveAndActivatedPromotions, filterActivePromotions } from "store/MonthPromotions";
import { ActivePromotionList, Promotion } from "types";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";

interface PromotionProductsProps {
    arePromotionsLocked: boolean;
    areMonthPromotionsLoading: boolean;
    monthPromotions: Promotion[];
    getPromotionStatus: (p: Promotion) => ProductStatus;
    onSelectButton: (p: Promotion) => void;
}

export const PromotionProducts: FunctionComponent<PromotionProductsProps> = (props): ReactElement => {
    const NUMBER_EMPTY_TILES = 10;

    const [filteredMonthPromotions, setFilteredPromotions] = useState<ActivePromotionList>();

    useEffect(() => {
        if (props.arePromotionsLocked) {
            const filteredMonthPromotions = filterActiveAndActivatedPromotions(props.monthPromotions);
            setFilteredPromotions(filteredMonthPromotions);
        } else {
            const filteredMonthPromotions = filterActivePromotions(props.monthPromotions);
            setFilteredPromotions(filteredMonthPromotions);
        }
    }, [props.arePromotionsLocked]);

    return (
        <>
            {props.areMonthPromotionsLoading ? (
                Array.from(Array(NUMBER_EMPTY_TILES).keys()).map(item => (
                    <ProductTile productStatus={ProductStatus.isEmpty} key={item} />
                ))
            ) : (
                <>
                    {filteredMonthPromotions &&
                        filteredMonthPromotions.promotions.map(item => (
                            <ProductTile
                                promotionTitle={item.advantageDescription}
                                image={item.image}
                                advantageAmount={item.advantageAmount}
                                advantage={item.advantage}
                                showSelectionBadge={true}
                                onSelectButton={() => props.onSelectButton(item)}
                                productStatus={props.getPromotionStatus(item)}
                                id={item.tegutPromotionIdentifier}
                                key={item.tegutPromotionIdentifier}
                            />
                        ))}
                </>
            )}
        </>
    );
};
