import { AppThunkAction } from "store";
import { hasToBeUpdated } from "utils/MultiplierPromotionUtils";
import {
    ActivePromotionList,
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    ErrorStatusCode,
    GetPromotionsResponse,
    Promotion,
    PromotionType,
    ReduxBaseType,
} from "../types";
import RequestStatus from "../utils/RequestStatus";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import dayjs from "dayjs";
import { Reducer } from "redux";

const GET_MONTH_PROMOTIONS = "GET_MONTH_PROMOTIONS";
const GET_MONTH_PROMOTIONS_SUCCESS = "GET_MONTH_PROMOTIONS_SUCCESS";
const GET_MONTH_PROMOTIONS_FAIL = "GET_MONTH_PROMOTIONS_FAIL";

const ACTIVATE_PROMOTIONS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER";
const ACTIVATE_PROMOTIONS_SUCCESS = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_SUCCESS";
const ACTIVATE_PROMOTIONS_FAIL = "ACTIVATE_PROMOTIONS_FOR_CUSTOMER_FAIL";

const VALIDATE_MULTIPLIER_COLLISIONS = "VALIDATE_MULTIPLIER_COLLISIONS";
const VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS = "VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS";
const VALIDATE_MULTIPLIER_COLLISIONS_FAIL = "VALIDATE_MULTIPLIER_COLLISIONS_FAIL";

const CLEAR_MULTIPLIER_COLLISIONS = "CLEAR_MULTIPLIER_COLLISIONS";

const IMAGE_MAX_SIZE = 200000;

interface ValidateMultiplierCollisionsRequest {
    promotionIdentifiers: string[];
}

interface ValidateMultiplierCollisionsResponse {
    isCollisionFound: boolean;
    promotionIdentifiers: string[];
}

export interface GetMonthPromotionsAction {
    type: typeof GET_MONTH_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetMonthPromotionsSuccessAction {
    type: typeof GET_MONTH_PROMOTIONS_SUCCESS;
    payload: AxiosResponse<GetPromotionsResponse>;
}

interface GetMonthPromotionsFailAction {
    type: typeof GET_MONTH_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

interface ValidateMultiplierCollisionsAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ValidateMultiplierCollisionsSuccessAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS;
    payload: AxiosResponse<ValidateMultiplierCollisionsResponse>;
}

interface ValidateMultiplierCollisionsFailAction {
    type: typeof VALIDATE_MULTIPLIER_COLLISIONS_FAIL;
    error: AxiosRequestError;
}

interface ActivatePromotionsAction {
    type: typeof ACTIVATE_PROMOTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface ActivatePromotionsSuccessAction {
    type: typeof ACTIVATE_PROMOTIONS_SUCCESS;
}

interface ActivatePromotionsFailAction {
    type: typeof ACTIVATE_PROMOTIONS_FAIL;
    error: AxiosRequestError;
}

interface ClearMultiplierCollisionsAction {
    type: typeof CLEAR_MULTIPLIER_COLLISIONS;
}

export interface MonthPromotionState extends BaseType, ReduxBaseType {
    monthPromotions: Promotion[];
    arePromotionsLocked: boolean;
    isActivating: boolean;
    nextChangeDate: string;
    areMultiplierCollisionsFound: boolean;
    collidedPromotionIdentifiers: string[];
}

export type KnownAction =
    | BaseKnownAction
    | ActivatePromotionsAction
    | ActivatePromotionsSuccessAction
    | ActivatePromotionsFailAction
    | GetMonthPromotionsAction
    | GetMonthPromotionsSuccessAction
    | GetMonthPromotionsFailAction
    | ValidateMultiplierCollisionsAction
    | ValidateMultiplierCollisionsSuccessAction
    | ValidateMultiplierCollisionsFailAction
    | ClearMultiplierCollisionsAction;

export const INITIAL_STATE: MonthPromotionState = {
    monthPromotions: [],
    arePromotionsLocked: false,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    lastUpdate: 0,
    isActivating: false,
    nextChangeDate: "",
    performedAction: null,
    areMultiplierCollisionsFound: false,
    collidedPromotionIdentifiers: [],
};

export const filterActivePromotions = (promotions: Promotion[]): ActivePromotionList => {
    if (promotions == null) {
        return {
            promotions: [],
        };
    }
    const activePromotions = promotions.filter(p => dayjs().isBetween(dayjs(p.startDate), dayjs(p.endDate)));
    return { promotions: activePromotions };
};

/**
 * Filter for active and activated Promotions.
 * Needed to display only the activated Promotions when the selection was made.
 * Gets a list of @param promotions and @returns only the active and activated Promotions
 **/
export const filterActiveAndActivatedPromotions = (promotions: Promotion[]): ActivePromotionList => {
    if (promotions == null) {
        return {
            promotions: [],
        };
    }
    const activePromotions = promotions.filter(p => dayjs().isBetween(dayjs(p.startDate), dayjs(p.endDate)) && p.isActivated);

    return { promotions: activePromotions };
};

// Reducer
export const reducer: Reducer<MonthPromotionState> = (state = INITIAL_STATE, action: KnownAction): MonthPromotionState => {
    switch (action.type) {
        case ACTIVATE_PROMOTIONS: {
            const { tegutPromotionIdentifiers } = action.payload.request.data;
            const activatedPromotions = state.monthPromotions.map(promotion => {
                return { ...promotion, isActivated: tegutPromotionIdentifiers.includes(promotion.tegutPromotionIdentifier) };
            });

            return {
                ...state,
                monthPromotions: activatedPromotions,
                isActivating: true,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS,
            };
        }
        case ACTIVATE_PROMOTIONS_FAIL: {
            const reactivatedPromotions = state.monthPromotions.map(promotion => {
                return { ...promotion, isActivated: false };
            });

            return {
                ...state,
                monthPromotions: reactivatedPromotions,
                isActivating: false,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS_FAIL,
            };
        }
        case ACTIVATE_PROMOTIONS_SUCCESS: {
            return {
                ...state,
                nextChangeDate: dayjs().add(1, "month").startOf("month").startOf("day").format(),
                arePromotionsLocked: true,
                isActivating: false,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.ACTIVATE_PROMOTIONS_SUCCESS,
            };
        }
        case GET_MONTH_PROMOTIONS: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS,
            };
        }
        case GET_MONTH_PROMOTIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS_FAIL,
            };
        }
        case GET_MONTH_PROMOTIONS_SUCCESS: {
            const { promotions, arePromotionsLocked, nextChangeDate } = action.payload.data;
            promotions.forEach(element => (element.image?.length > IMAGE_MAX_SIZE ? (element.image = "") : element));

            return {
                ...state,
                arePromotionsLocked,
                monthPromotions: promotions,
                lastUpdate: dayjs().unix(),
                nextChangeDate,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_MONTH_PROMOTIONS_SUCCESS,
            };
        }
        case VALIDATE_MULTIPLIER_COLLISIONS: {
            return {
                ...state,
                areMultiplierCollisionsFound: false,
                collidedPromotionIdentifiers: [],
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS,
            };
        }
        case VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS: {
            const { isCollisionFound, promotionIdentifiers } = action.payload.data;

            return {
                ...state,
                areMultiplierCollisionsFound: isCollisionFound,
                collidedPromotionIdentifiers: promotionIdentifiers,
                isLoading: false,
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS_SUCCESS,
            };
        }
        case VALIDATE_MULTIPLIER_COLLISIONS_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.VALIDATE_MULTIPLIER_COLLISIONS_FAIL,
            };
        }
        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CLEAR_MULTIPLIER_COLLISIONS: {
            return {
                ...state,
                areMultiplierCollisionsFound: false,
                collidedPromotionIdentifiers: [],
            };
        }
        default:
            return state;
    }
};

// Action Creators

export const actionCreators = {
    ...baseActionCreators,
    requestGetMonthPromotions: (): AppThunkAction<GetMonthPromotionsAction> => async (dispatch, getState) => {
        const { isLoading, lastUpdateMonthPromotions, nextChangeDate, monthPromotions } = getState().monthPromotionReducer;

        if (hasToBeUpdated(isLoading, lastUpdateMonthPromotions, nextChangeDate, monthPromotions.length)) {
            dispatch(<GetMonthPromotionsAction>{
                type: GET_MONTH_PROMOTIONS,
                payload: {
                    request: {
                        method: "GET",
                        url: `/GetPromotions/${PromotionType.Month}`,
                    },
                },
            });
        }
    },
    activatePromotions: (promotions: string[]): ActivatePromotionsAction =>
        <ActivatePromotionsAction>{
            type: ACTIVATE_PROMOTIONS,
            payload: {
                request: {
                    method: "POST",
                    url: "/ActivatePromotions",
                    data: {
                        tegutPromotionIdentifiers: promotions,
                    },
                },
            },
        },
    validateMultiplierCollisions: (requestData: ValidateMultiplierCollisionsRequest): ValidateMultiplierCollisionsAction =>
        <ValidateMultiplierCollisionsAction>{
            type: VALIDATE_MULTIPLIER_COLLISIONS,
            payload: {
                request: {
                    method: "POST",
                    url: "/ValidateMultiplierPromotionsCollisions",
                    data: requestData,
                },
            },
        },
    clearMultiplierCollisions: (): ClearMultiplierCollisionsAction =>
        <ClearMultiplierCollisionsAction>{
            type: CLEAR_MULTIPLIER_COLLISIONS,
        },
};

export type PromotionActions = typeof actionCreators;
