import { TebonusArrowRightSharp, TebonusArrowSize } from "components/_graphics";
import { INITIAL_SEARCH_CATEGORY_PROPS } from "pages/ArticleFinder";
import { ArticleCategoriesState } from "store/ArticleCategories";
import { AppState } from "store/configureStore";
import { ArticleCategory, SearchProps } from "types";
import { logAnalyticsButton } from "utils/FirebaseAnalytics";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface ArticleCategoriesProps {
    setSearchTagCategory: (searchTagCategory: SearchProps) => void;
    searchStarted: boolean;
    resetBreadcrumb: boolean;
    setResetBreadcrumb: (resetValue: boolean) => void;
}

const ArticleCategoriesView: FunctionComponent<ArticleCategoriesProps> = props => {
    const articleCategoriesReducer: ArticleCategoriesState = useSelector((state: AppState) => state.articleCategoriesReducer);
    const [categoryState, setCategoryState] = useState<ArticleCategory[]>(articleCategoriesReducer.categories);
    const [parentNodeIds, setParentNodeIds] = useState<string[]>([]);

    const hasPreviousPath = parentNodeIds && parentNodeIds.length > 0;

    useEffect(() => {
        if (props.resetBreadcrumb) {
            setParentNodeIds([]);
            setCategoryState(articleCategoriesReducer.categories);
            props.setResetBreadcrumb(false);
        }
    }, [props.resetBreadcrumb]);

    /**
     * every time a category is clicked, the state will be set to its child categories.
     *
     * @param {ArticleCategory[]} category the category to replace the one in categoryState.
     * @return {*} return the search results if no further child categories are present.
     */
    const handleCategoryClicked = (category: ArticleCategory): void => {
        setParentNodeIds([...parentNodeIds, category.tegutCategoryId]);

        const categoryLevel = parentNodeIds.length + 1;

        logAnalyticsButton({
            button_title: category.categoryName,
            level: categoryLevel,
        });

        if (!category.childCategories.length) {
            setCategoryState(category.childCategories);
            return props.setSearchTagCategory({
                id: category.tegutCategoryId,
                name: category.categoryName,
            });
        }

        // Reset the searchTagCategory if a breadcrumb was clicked
        props.setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
        return setCategoryState(category.childCategories);
    };

    /**
     * ParentNodIds has the whole breadcrumb path. After clicking on the breadcrumb the
     * path will be reduced and set to the elements from the root to the nodeTofidnId element
     * @param nodeToFindId
     */
    const getParentsPath = (nodeToFindId: string): void => {
        const index = parentNodeIds.indexOf(nodeToFindId);
        const newIds = parentNodeIds.slice(0, index);
        setParentNodeIds(newIds);
    };

    /**
     * search's for all the parent categories and renders the path as a breadcrumb
     * @return {*} a list of breadcrumbs.
     */
    const renderCategoriesBreadcrumb = (): React.ReactNode => {
        return parentNodeIds.map(parentNodeId => {
            if (parentNodeId == null) {
                // do nothing when no category was selected
                return null;
            }

            const parentCategory = findParentNodeRecursively(articleCategoriesReducer.categories, parentNodeId);

            const activeNode = parentNodeIds[parentNodeIds.length - 1];
            const isActive = parentNodeId === activeNode;

            return (
                <li
                    key={parentNodeId}
                    className={`category-breadcrumb-item py-1 py-md-0 cursor-pointer ${isActive && "active"}`}
                    aria-current="page"
                    onClick={() => {
                        navigateToParent(articleCategoriesReducer.categories, parentNodeId, parentCategory);

                        getParentsPath(parentNodeId);
                        if (parentCategory.childCategories) {
                            // reset search tags when it´s not the bottom of the categories
                            props.setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
                        }
                    }}
                >
                    {parentCategory?.categoryName}
                </li>
            );
        });
    };

    /**
     * searches and navigates to the top level parents and renders all its child categories.
     * if no top level parent is found it will recursively search the children node until a valid parent id is found.
     *
     * @param {ArticleCategory[]} categoryList the category list to search.
     * @param {string} parentId the parent id to find.
     * @param {ArticleCategory} [parentCategory] the parent category to search if no top level parent is found.
     * @return {*}
     */
    const navigateToParent = (categoryList: ArticleCategory[], parentId: string, parentCategory?: ArticleCategory): void => {
        const root = categoryList;
        const parentLevel = root?.some(x => x.tegutCategoryId === parentId);

        if (!parentLevel) {
            const path = findParentNodeRecursively(articleCategoriesReducer.categories, parentCategory.parentCategoryId);
            return navigateToParent(path.childCategories, parentId);
        }

        setCategoryState(root);
    };

    /**
     * searches the article category list for the parent node, if a match is found it returns the parent object.
     * if no parent node is found it will recursively search the children node until a valid parent id is found or null.
     *
     * @param {ArticleCategory[]} categoryList the list of categories to search.
     * @param {string} parentId the parent node id to find.
     * @return {*}  {ArticleCategory} returns the parent node or null.
     */
    const findParentNodeRecursively = (categoryList: ArticleCategory[], parentId: string): ArticleCategory => {
        if (categoryList == null || !categoryList.length) {
            return null;
        }

        return categoryList.reduce((found, category) => {
            // the accumulated node if any is found
            if (found) {
                return found;
            }
            // return the found parent for the next iteration
            if (category.tegutCategoryId === parentId) {
                return category;
            }
            // if cant find parent node, go one child deeper.
            if (category.childCategories && category.childCategories.length > 0) {
                return findParentNodeRecursively(category.childCategories, parentId);
            }

            return null;
        }, null);
    };

    const renderCategories = (): React.ReactNode => {
        return categoryState.map(category => {
            const hasChildCategories = category.childCategories.length > 0;
            return (
                <tr
                    key={category.tegutCategoryId}
                    className="slide-row-left cursor-pointer font-article-categories"
                    tabIndex={0}
                    onClick={() => handleCategoryClicked(category)}
                    onKeyDown={e => {
                        if (e.key == "Enter") {
                            handleCategoryClicked(category);
                        }
                    }}
                >
                    <td>{category.categoryName}</td>
                    <td className="text-right icon-accent-lightGray">
                        {hasChildCategories && <TebonusArrowRightSharp size={TebonusArrowSize.XX_SMALL} />}
                    </td>
                </tr>
            );
        });
    };

    return (
        <>
            <div className="row justify-content-center mt-2 mb-0">
                <div className="col-9 col-md-9 col-lg-9">
                    {/* BREADCRUMBS: renders only when a child category is selected */}
                    {hasPreviousPath && <ul className="category-breadcrumb">{renderCategoriesBreadcrumb()}</ul>}
                </div>
            </div>

            {!props.searchStarted && (
                <div className="row justify-content-center my-5 article-categories-container scrollScoreContainer-md overflow-y-md table-scroll-bar">
                    <div className="col-12 col-md-11 col-lg-9">
                        {!hasPreviousPath && <h3 className="font-subheading">In Kategorien stöbern</h3>}

                        {/* ARTICLE CATEGORIES */}
                        <table className="table table-hover">
                            <tbody className="font-article-categories">{renderCategories()}</tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default ArticleCategoriesView;
