import { useAppSelector } from "utils/hooks";
import { ArticleSearchState } from "store/ArticleSearch";
import { AppState } from "store/configureStore";
import LoadingIndicator from "components/LoadingIndicator";
import EmptySearchResults from "./EmptySearchResults";
import SearchResultItems from "./SearchResultItems";
import React, { FunctionComponent, ReactElement } from "react";

interface ArticleSearchResultsProps {
    currentMultiplierIndex: number;
    searchTerm: string;
    isSearchLoading: boolean;
}

const ArticleSearchResults: FunctionComponent<ArticleSearchResultsProps> = (props: ArticleSearchResultsProps): ReactElement => {
    const articleSearch: ArticleSearchState = useAppSelector((state: AppState) => state.articleSearchReducer);

    if (articleSearch.searchResults.length === 0 && articleSearch.isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {articleSearch.searchResults.length > 0 ? (
                <SearchResultItems
                    isLoading={articleSearch.isLoading || props.isSearchLoading}
                    searchResults={[...articleSearch.searchResults]}
                    currentMultiplierIndex={props.currentMultiplierIndex}
                    searchResultCounter={articleSearch.searchResults.length}
                />
            ) : (
                <EmptySearchResults searchTerm={props.searchTerm} />
            )}
        </>
    );
};

export default ArticleSearchResults;
