import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TebonusBagIcon: React.FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 22.73 30">
            <path
                fill={props.htmlColor}
                d="m19.78 7.47a.62.62 0 0 0 -.61-.56h-4v-2.07a4.1 4.1 0 0 0 -8.17 0v2.07h-4.31a.61.61 0 0 0 -.61.56l-1.31 15.45a4.46 4.46 0 0 0 4.06 4.82h11.82a4.46 4.46 0 0 0 4.46-4.45c0-.13 0-.25 0-.38zm-7.13-.59h-3.41v-1.62a2 2 0 0 1 1.7-2.26 2 2 0 0 1 1.71 2.24z"
            />
        </SvgIcon>
    );
};

export default TebonusBagIcon;
