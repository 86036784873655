import PageBase from "components/Shared/PageBase";
import logo from "assets/images/tebonus_Karte_rgb_Website.png";
import TebonusPhysicalCardIcon from "components/_graphics/TebonusPhysicalCardIcon";
import { TebonusArrowRightSharp } from "components/_graphics";
import TebonusLocked from "components/_graphics/TebonusLocked";
import { Colors } from "styles";
import PhysicalCardLine from "components/PhysicalCard/PhysicalCardLine";
import TebonusLoopIcon from "components/_graphics/TebonusLoopIcon";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { AppState } from "store/configureStore";
import { AllReduxActions, CardOverviewInfo, CardState, CardType } from "types";
import { CardOverviewState } from "store/CardOverview";
import { actionCreators } from "store";
import { OpenModal, PhysicalCardModals } from "../../components/PhysicalCard/Modals";
import { getTebonusCardStateText, isLockCardLineVisible, isOnlyCardStateVisible, isOrderNewCardLineVisible } from "./utils";
import React, { FunctionComponent, ReactElement, useEffect, useState } from "react";

const PhysicalCard: FunctionComponent = (): ReactElement => {
    const INITIAL_CARD_STATE = {
        cardNumber: "",
        cardType: CardType.unknown,
        isLockedForEarn: true,
        isLockedForSpend: true,
        state: CardState.locked,
    } as CardOverviewInfo;

    const cardOverviewState = useAppSelector((state: AppState) => state.cardOverviewReducer) as CardOverviewState;
    const [physicalCard, setPhysicalCard] = useState<CardOverviewInfo>(INITIAL_CARD_STATE);
    const [openModal, setOpenModal] = useState<OpenModal>(OpenModal.NONE);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const physicalCards = cardOverviewState.cards.filter(c => c.cardType === CardType.physical) as CardOverviewInfo[];
        const validCard = getValidPhysicalCard(physicalCards, CardState.active);
        if (validCard) {
            setPhysicalCard(validCard);
        }
    }, [cardOverviewState.cards]);

    useEffect(() => {
        dispatch(actionCreators.cardOverview.requestGetCardOverview());
    }, [cardOverviewState.performedAction === AllReduxActions.SEND_CARD_LOCK_SUCCESS]);

    function getValidPhysicalCard(physicalCards: CardOverviewInfo[], cardState: CardState): CardOverviewInfo {
        let validCard = physicalCards.find(card => card.state === cardState);
        if (!validCard) {
            if (cardState === CardState.locked) {
                return undefined;
            }

            const nextCardState = cardState === CardState.active ? CardState.ordered : CardState.locked;
            validCard = getValidPhysicalCard(physicalCards, nextCardState);
        }

        return validCard;
    }

    function lockOrderPhysicalCard(withNewCard: boolean): void {
        dispatch(
            actionCreators.cardOverview.sendCardLock({
                cardNumber: physicalCard.cardNumber,
                orderNewCard: withNewCard,
            })
        );

        setOpenModal(OpenModal.NONE);
    }

    return (
        <PageBase
            title="tebonus Karte"
            modals={
                <PhysicalCardModals
                    openModal={openModal}
                    isLoading={cardOverviewState.isLoading}
                    isCardStillActive={physicalCard.state === CardState.active}
                    lockOrderPhysicalCard={orderNewCard => lockOrderPhysicalCard(orderNewCard)}
                    closeModal={() => setOpenModal(OpenModal.NONE)}
                />
            }
        >
            <div className="d-flex flex-md-row flex-column align-items-center">
                <img className="ml-4 mr-4 mb-4" src={logo} width="30%" />
                <div style={{ width: "60%" }}>
                    {/*tebonus card*/}
                    <PhysicalCardLine
                        isVisible={true}
                        classNameAdditions={`border-top ${isOnlyCardStateVisible(physicalCard.state) ? "border-bottom" : ""}`}
                        icon={<TebonusPhysicalCardIcon size={30} />}
                        text="tebonus Karte"
                    >
                        <h5 className="mt-2">{getTebonusCardStateText(physicalCard)}</h5>
                    </PhysicalCardLine>

                    {/*lock card*/}
                    <PhysicalCardLine
                        isVisible={isLockCardLineVisible(physicalCard.state)}
                        classNameAdditions="border-top border-bottom card-line-btn"
                        icon={<TebonusLocked size={30} />}
                        text="Karte dauerhaft sperren"
                        onClick={() => setOpenModal(OpenModal.LOCK_CARD)}
                    >
                        <TebonusArrowRightSharp color={Colors.orange} />
                    </PhysicalCardLine>

                    {/*order new card*/}
                    <PhysicalCardLine
                        isVisible={isOrderNewCardLineVisible(physicalCard.state)}
                        classNameAdditions={`border-bottom ${
                            !isLockCardLineVisible(physicalCard.state) ? "border-top" : ""
                        } card-line-btn`}
                        icon={<TebonusLoopIcon size={33} className="ml-1 mr-2" />}
                        text="neue Karte bestellen"
                        onClick={() => setOpenModal(OpenModal.ORDER_NEW_CARD)}
                    >
                        <TebonusArrowRightSharp color={Colors.orange} />
                    </PhysicalCardLine>
                </div>
            </div>
        </PageBase>
    );
};

export default PhysicalCard;
