import PageBase from "components/Shared/PageBase";
import { WeeklyHitsHeader } from "components/WeeklyHits/WeeklyHitsHeader";
import { WeeklyHitsTiles } from "components/WeeklyHits/WeeklyHitsTiles";
import { actionCreators } from "store";
import { WeekPromotionsState } from "store/WeekPromotions";
import { useAppDispatch } from "utils/hooks";
import { TebonusStarIcon } from "../../components/_graphics";
import { TegutBadge } from "../../components/_tegut/TegutBadge";
import { AppState } from "../../store/configureStore";
import { Colors } from "../../styles";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";

const WeeklyHits: React.FunctionComponent = (): React.ReactElement => {
    const promotions: WeekPromotionsState = useSelector((state: AppState) => state.weekPromotionReducer);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(actionCreators.weekPromotion.requestGetWeekPromotions());
    }, []);

    return (
        <PageBase
            title="Wochen-Hits"
            icon={
                <TegutBadge bgColor={Colors.orange} size={35}>
                    <TebonusStarIcon htmlColor={Colors.white} />
                </TegutBadge>
            }
            isContentLoading={promotions.isLoading}
            contentLoadingText="Ihre Wochen-Hits werden geladen."
            showLoadingIndicator={true}
        >
            <div className="container-md content-in-tile">
                <WeeklyHitsHeader />
                <WeeklyHitsTiles weekPromotions={promotions.weekPromotions} areWeekPromotionsLoading={promotions.isLoading} />
            </div>
        </PageBase>
    );
};

export default WeeklyHits;
