import { AppState } from "store/configureStore";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import useDebounce from "utils/UseDebounce";
import { actionCreators } from "store";
import { getNumberOfMultipliersInSessionStorage, handleLeavePage } from "pages/ArticleMultipliers";
import PageBase from "components/Shared/PageBase";
import ArticleCategoriesView from "components/ArticleFinder/ArticleCategoriesView";
import { logAnalyticsFilter, logAnalyticsSearch } from "utils/FirebaseAnalytics";
import MultiplierWelcomeText from "components/MultiplierWelcomeText";
import ArticleFinderModals from "components/ArticleFinder/ArticleFinderModals";
import SearchBar from "components/ArticleFinder/SearchBar";
import ArticleSearchFilter from "components/ArticleFinder/ArticleSearchFilter";
import SelectedFilters from "components/ArticleFinder/SelectedFilters";
import ArticleSearchResults from "components/ArticleFinder/ArticleSearchResults";
import { LocationState, SearchProps } from "types";
import { LEAVE_PAGE_FROM_BROWSER_EVENT_TEXT } from "components/LeavePageModal";
import { Colors } from "../../styles";
import { TegutBadge } from "../../components/_tegut/TegutBadge";
import { TebonusSingleHeart } from "../../components/_graphics";
import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HEADING_BADGE_SIZE = 35;
const HEADING_HEART_ICON_MULTIPLIER = 0.75;

export const INITIAL_SEARCH_CATEGORY_PROPS: SearchProps = {
    id: "",
    name: "",
};

const ArticleFinder: FunctionComponent = (): ReactElement => {
    const EXECUTING_SEARCH_TYPE = "Textsuche";
    const BRANDS_FILTER_TERM = "Marken";
    const CATEGORIES_FILTER_TERM = "Warengruppe";
    const LIST_FILTER_TYPE = "list";

    const dispatch = useAppDispatch();
    const location = useLocation();
    const locationState = location.state as LocationState;
    const navigate = useNavigate();
    const articleMultipliers = useAppSelector((state: AppState) => state.multiplierReducer);
    const [searchTerm, setSearchTerm] = useState("");
    const debounceSearchTerm = useDebounce<string>(searchTerm, 500);
    const [debounceLoading, setDebounceLoading] = useState<boolean>(false);
    const [searchTagCategory, setSearchTagCategory] = useState<SearchProps>(INITIAL_SEARCH_CATEGORY_PROPS);
    const [selectedBrands, setSelectedBrands] = useState<SearchProps[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<SearchProps[]>([]);
    const [allCategories, setAllCategories] = useState<SearchProps[]>([]);

    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const [resetBreadcrumb, setResetBreadcrumb] = useState<boolean>(false);

    // #region useEffects

    useEffect(() => {
        if (getNumberOfMultipliersInSessionStorage() > 0) {
            window.addEventListener("beforeunload", handleTabClose);
            window.addEventListener("unload", handleLeavePageEvent);

            return () => {
                window.removeEventListener("beforeunload", handleTabClose);
                window.removeEventListener("unload", handleLeavePageEvent);
            };
        }
    }, []);

    // [Analytics] log selected brands
    useEffect(() => {
        if (selectedBrands.length > 0) {
            const brandNames = selectedBrands.map(brand => brand.name);

            logAnalyticsFilter({
                filter_term: BRANDS_FILTER_TERM,
                produkt: brandNames,
                filter_type: LIST_FILTER_TYPE,
            });
        }
    }, [selectedBrands]);

    // [Analytics] log selected categories
    useEffect(() => {
        if (selectedCategories.length > 0) {
            const categorynames = selectedCategories.map(cat => cat.name);

            logAnalyticsFilter({
                filter_term: CATEGORIES_FILTER_TERM,
                produkt: categorynames,
                fitler_type: LIST_FILTER_TYPE,
            });
        }
    }, [selectedCategories]);

    const searchStarted = searchTagCategory?.id || searchTerm;

    // redirect to favourite products when the location state was not set
    if (!location.state) {
        navigate("/meintebonus/Lieblingsprodukte");
    }

    // A new search will be started if the search term was changed and the debounce was triggered
    useEffect(() => {
        prepareAndFetchData();
        setDebounceLoading(false);

        if (debounceSearchTerm !== "") {
            logAnalyticsSearch({
                search_term: debounceSearchTerm,
                search_type: EXECUTING_SEARCH_TYPE,
            });
        }
    }, [debounceSearchTerm]);

    // A new search will be started if the category or brand was changed
    useEffect(() => {
        prepareAndFetchData();
    }, [searchTagCategory, selectedCategories, selectedBrands]);

    // #endregion

    // #region functions
    const handleTabClose = useCallback((event: { preventDefault: () => void; returnValue: string }) => {
        event.preventDefault();
        event.returnValue = LEAVE_PAGE_FROM_BROWSER_EVENT_TEXT;
    }, []);

    const handleLeavePageEvent = useCallback(() => {
        handleLeavePage();
    }, []);

    /**
     * Combine the search term, brand and online categorie selection to an json request and fetch the wanted data from the api.
     */
    function prepareAndFetchData(): void {
        // If no data is selected the fetch data can be skiped
        if (!searchTerm && selectedBrands.length <= 0 && selectedCategories.length <= 0 && !searchTagCategory?.id) {
            return;
        }

        const searchTags = [];
        // add brands to search tags if there are any
        if (selectedBrands.length > 0) {
            searchTags.push({ key: "Brand", value: [...selectedBrands.map(i => i.id)] });
        }

        // add the searchTagCategory tag from the search tree if it was selected
        const allSelectedCategories = searchTagCategory?.id
            ? [...selectedCategories, searchTagCategory]
            : [...selectedCategories];
        // add online categories to the search tag if at least one was selected
        if (allSelectedCategories.length > 0) {
            searchTags.push({ key: "OnlineCategory", value: allSelectedCategories.map(i => i.id) });
        }

        // Make a api request dependend on brands categories and search term
        dispatch(actionCreators.articleSearch.searchArticle(searchTerm, searchTags));
    }

    function onSearchTermChanged(text: string): void {
        setSearchTerm(text);
        dispatch(actionCreators.articleSearch.resetSearchResult());
        setDebounceLoading(true);

        // Reset states if they are not empty. They should only be resetet if neccessary. Otherwise the UseEffect is triggered to often
        searchTagCategory?.id && setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
        selectedBrands.length > 0 && setSelectedBrands([]);
        selectedCategories.length > 0 && setSelectedCategories([]);

        setResetBreadcrumb(true);
    }

    function onCancelButtonPressed(): void {
        setSearchTerm("");
        dispatch(actionCreators.articleSearch.resetSearchResult());

        // Reset states if they are not empty. They should only be resetet if neccessary. Otherwise the UseEffect is triggered to often
        searchTagCategory?.id && setSearchTagCategory(INITIAL_SEARCH_CATEGORY_PROPS);
        //  searchTagCategory?.id && setSearchTagCategory(null);
        selectedBrands.length > 0 && setSelectedBrands([]);
        selectedCategories.length > 0 && setSelectedCategories([]);
        setResetBreadcrumb(true);
    }

    /**
     * Remove the desired filter from the selected brands list.
     * @param item
     */
    const removeBrandFilter = (item: SearchProps): void => {
        setSelectedBrands(selectedBrands.filter(i => i.id !== item.id));
    };

    /**
     * Remove the desired filter from the selected brands list.
     * @param item
     */
    const removeCategoryFilter = (item: SearchProps): void => {
        setSelectedCategories(selectedCategories.filter(i => i.id !== item.id));
    };

    // #endregion

    return (
        <PageBase
            title="Ihre Lieblingsprodukte"
            icon={
                <TegutBadge bgColor={Colors.orange} size={HEADING_BADGE_SIZE}>
                    <TebonusSingleHeart
                        preserveAspectRatio={true}
                        size={HEADING_BADGE_SIZE * HEADING_HEART_ICON_MULTIPLIER}
                        color={Colors.white}
                    />
                </TegutBadge>
            }
            prevPageLink="/meintebonus/Lieblingsprodukte"
            modals={
                <ArticleFinderModals
                    isFilterModalOpen={isFilterModalOpen}
                    setShowModal={setIsFilterModalOpen}
                    setSelectedBrands={setSelectedBrands}
                    selectedBrands={selectedBrands}
                    setSelectedCategories={setSelectedCategories}
                    selectedCategories={selectedCategories}
                    categoryOptions={allCategories}
                    handleLeavePage={handleLeavePage}
                />
            }
        >
            <div className="container-md content-in-tile">
                <MultiplierWelcomeText isInitial={locationState?.isInitial} isEditable={locationState?.isEditable} />
                <SearchBar
                    searchTerm={searchTerm}
                    searchTagCategory={searchTagCategory}
                    onCancelButtonPressed={onCancelButtonPressed}
                    onSearchTermChanged={event => onSearchTermChanged(event)}
                />
                {/* ARTICLE CATEGORIES AND BREADCRUMB MENU: only renders if the search bar is not being used to search the products. */}
                <ArticleCategoriesView
                    setSearchTagCategory={searchTag => setSearchTagCategory(searchTag)}
                    searchStarted={searchStarted !== ""}
                    resetBreadcrumb={resetBreadcrumb}
                    setResetBreadcrumb={setResetBreadcrumb}
                />
                {/* Search filters are shown when the search term was filled out */}
                {searchStarted && (
                    <ArticleSearchFilter
                        searchTerm={searchTerm}
                        selectedBrands={selectedBrands}
                        setSelectedBrands={setSelectedBrands}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        allCategories={allCategories}
                        setAllCategories={setAllCategories}
                        isFilterModalOpen={isFilterModalOpen}
                        setIsFilterModalOpen={setIsFilterModalOpen}
                    />
                )}

                {searchStarted && (
                    <SelectedFilters
                        selectedBrands={selectedBrands}
                        selectedCategories={selectedCategories}
                        removeBrandFilter={brand => removeBrandFilter(brand)}
                        removeCategoryFilter={category => removeCategoryFilter(category)}
                    />
                )}

                {searchStarted && (
                    <ArticleSearchResults
                        currentMultiplierIndex={articleMultipliers.values[locationState?.index]}
                        searchTerm={searchTerm || searchTagCategory?.name}
                        isSearchLoading={debounceLoading}
                    />
                )}
            </div>
        </PageBase>
    );
};

export default ArticleFinder;
