import { setAnalyticsConsent } from "utils/FirebaseAnalytics";
import {
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    ConsentTypes,
    CustomerBase,
    ErrorStatusCode,
    ReduxBaseType,
} from "../types";
import RequestStatus from "../utils/RequestStatus";
import { getAppSetting } from "../utils/AppSettings";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import dayjs from "dayjs";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");

// Actions
const GET_CUSTOMER_INFO = "pekuma/customers/GET_INFO";
const GET_CUSTOMER_INFO_SUCCESS = "pekuma/customers/GET_INFO_SUCCESS";
const GET_CUSTOMER_INFO_FAIL = "pekuma/customers/GET_INFO_FAIL";

const UPDATE_ONBOARDING_SHOWN = "pekuma/customers/UPDATE_ONBOARDING_SHOWN";

const ACCEPT_ANALYSE_OPT_IN = "pekuma/customers/ACCEPT_ANALYSE_OPT_IN";
const ACCEPT_ANALYSE_OPT_IN_SUCCESS = "pekuma/customers/ACCEPT_ANALYSE_OPT_IN_SUCCESS";
const ACCEPT_ANALYSE_OPT_IN_FAIL = "pekuma/customers/ACCEPT_ANALYSE_OPT_IN_FAIL";

const CLEAR_ALL_CUSTOMER = "pekuma/customers/CLEAR_ALL";

const SEND_CUSTOMER_CONFIRMATION_MAIL = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL";
const SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS";
const SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL = "pekuma/customers/SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL";

const TERMINATE_TEBONUS_CUSTOMER = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER";
const TERMINATE_TEBONUS_CUSTOMER_SUCCESS = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER_SUCCESS";
const TERMINATE_TEBONUS_CUSTOMER_FAIL = "pekuma/customers/TERMINATE_TEBONUS_CUSTOMER_FAIL";

const UPDATE_TRACKING_CONSENT = "UPDATE_TRACKING_CONSENT";
const UPDATE_TRACKING_CONSENT_SUCCESS = "UPDATE_TRACKING_CONSENT_SUCCESS";
const UPDATE_TRACKING_CONSENT_FAIL = "UPDATE_TRACKING_CONSENT_FAIL";

const SET_TRACKING_CONSENT_SET = "SET_TRACKING_CONSENT_SET";

interface GetCustomerResponse {
    given_name: string;
    family_name: string;
    unique_name: string;
    TegutCustomerNumber: string;
    tegut_identifier: string;
    AnalysisOptInDate: string;
    EmailConfirmed: string;
    TebonusRegistrationDate: string;
    TrackingConsentAccepted: string;
}

interface UpdateConsentData {
    type: number;
    isAccepted: boolean;
}

interface UpdateTrackingConsentRequest {
    data: UpdateConsentData[];
}

interface UpdateOnboardingShownAction {
    type: typeof UPDATE_ONBOARDING_SHOWN;
    newValue: boolean;
}

export interface GetCustomerInfoAction {
    type: typeof GET_CUSTOMER_INFO;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCustomerInfoSuccessAction {
    type: typeof GET_CUSTOMER_INFO_SUCCESS;
    payload: AxiosResponse<GetCustomerResponse>;
}

interface GetCustomerInfoFailAction {
    type: typeof GET_CUSTOMER_INFO_FAIL;
    error: AxiosRequestError;
}

interface AcceptAnalyseOptInAction {
    type: typeof ACCEPT_ANALYSE_OPT_IN;
    payload: {
        request: AxiosRequestConfig<UpdateTrackingConsentRequest>;
    };
}

interface AcceptAnalyseOptInSuccessAction {
    type: typeof ACCEPT_ANALYSE_OPT_IN_SUCCESS;
    payload: AxiosResponse;
}

interface AcceptAnalyseOptInFailAction {
    type: typeof ACCEPT_ANALYSE_OPT_IN_FAIL;
    error: AxiosRequestError;
}

interface ClearAllAction {
    type: typeof CLEAR_ALL_CUSTOMER;
}
interface SendCustomerConfirmationMailAction {
    type: typeof SEND_CUSTOMER_CONFIRMATION_MAIL;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface SendCustomerConfirmationMailSuccessAction {
    type: typeof SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS;
    payload: AxiosResponse;
}

interface SendCustomerConfirmationMailFailAction {
    type: typeof SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL;
    error: AxiosRequestError;
}

interface TerminateTebonusCustomerAction {
    type: typeof TERMINATE_TEBONUS_CUSTOMER;
    payload: {
        request: AxiosRequestConfig;
    };
}
interface TerminateTebonusCustomerSuccessAction {
    type: typeof TERMINATE_TEBONUS_CUSTOMER_SUCCESS;
    payload: AxiosResponse;
}
interface TerminateTebonusCustomerFailAction {
    type: typeof TERMINATE_TEBONUS_CUSTOMER_FAIL;
    error: AxiosRequestError;
}

interface SetTrackingConsentAction {
    type: typeof UPDATE_TRACKING_CONSENT;
    payload: {
        request: AxiosRequestConfig<UpdateTrackingConsentRequest>;
    };
}

interface SetTrackingConsentSuccessAction {
    type: typeof UPDATE_TRACKING_CONSENT_SUCCESS;
}

interface SetTrackingConsentFailAction {
    type: typeof UPDATE_TRACKING_CONSENT_FAIL;
    error: AxiosRequestError;
}

interface SetIsTrackingConsentSetAction {
    type: typeof SET_TRACKING_CONSENT_SET;
}

export interface CustomerState extends BaseType, ReduxBaseType {
    customer: CustomerBase | null;
    isRegistration: boolean;
    onboardingAlreadyShown: boolean;
    isEmailConfirmed: boolean;
    registrationDate: string;
    isActivating: boolean;
    isSendingConfirmation: boolean;
    isConfirmationSentSuccessfully: boolean;
    isDeleting: boolean;
    isTrackingConsentAccepted: boolean | null;
    isTrackingConsentSet: boolean;
}

export type KnownAction =
    | BaseKnownAction
    | GetCustomerInfoAction
    | GetCustomerInfoSuccessAction
    | GetCustomerInfoFailAction
    | UpdateOnboardingShownAction
    | AcceptAnalyseOptInAction
    | AcceptAnalyseOptInFailAction
    | AcceptAnalyseOptInSuccessAction
    | ClearAllAction
    | SendCustomerConfirmationMailAction
    | SendCustomerConfirmationMailSuccessAction
    | SendCustomerConfirmationMailFailAction
    | TerminateTebonusCustomerAction
    | TerminateTebonusCustomerSuccessAction
    | TerminateTebonusCustomerFailAction
    | SetTrackingConsentAction
    | SetTrackingConsentSuccessAction
    | SetTrackingConsentFailAction
    | SetIsTrackingConsentSetAction;

export type SuccessAction =
    | GetCustomerInfoSuccessAction
    | AcceptAnalyseOptInSuccessAction
    | TerminateTebonusCustomerSuccessAction;

export type ErrorAction = GetCustomerInfoFailAction | AcceptAnalyseOptInFailAction | TerminateTebonusCustomerFailAction;

export const INITIAL_STATE: CustomerState = {
    customer: null,
    isLoading: false,
    isDeleting: false,
    errorCode: ErrorStatusCode.noError,
    performedAction: null,
    isRegistration: false,
    onboardingAlreadyShown: false,
    isEmailConfirmed: false,
    registrationDate: "",
    isActivating: false,
    isSendingConfirmation: false,
    isConfirmationSentSuccessfully: false,
    isTrackingConsentAccepted: null,
    isTrackingConsentSet: false,
};

let prevTrackingConsentAccepted: boolean = null;

// Reducer
export const reducer: Reducer<CustomerState> = (state = INITIAL_STATE, action: KnownAction): CustomerState => {
    switch (action.type) {
        case GET_CUSTOMER_INFO: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_INFO,
            };
        }
        case GET_CUSTOMER_INFO_SUCCESS: {
            const { data } = action.payload;

            if (!data) {
                // success error code (starting with 2xx but no content)
                return {
                    ...state,
                    isLoading: false,
                    errorCode: 204,
                    performedAction: AllReduxActions.GET_CUSTOMER_INFO_SUCCESS,
                };
            }

            const { given_name, family_name, unique_name, TegutCustomerNumber } = data;
            const customer: CustomerBase = {
                firstName: given_name,
                lastName: family_name,
                email: unique_name,
                customerNumber: TegutCustomerNumber,
                id: data.tegut_identifier,
                analysisOptInDate:
                    data.AnalysisOptInDate === undefined ? "" : dayjs(data.AnalysisOptInDate).format("DD.MM.YYYY"),
                dontPersist: true,
            };

            const isConsentAccepted = data.TrackingConsentAccepted
                ? data.TrackingConsentAccepted.toLocaleLowerCase() === "true"
                : null;

            if (isConsentAccepted === false) {
                setAnalyticsConsent(isConsentAccepted);
            }

            return {
                ...state,
                isLoading: false,
                errorCode: ErrorStatusCode.noError,
                customer,
                isEmailConfirmed: data.EmailConfirmed.toLowerCase() === "true",
                registrationDate: data?.TebonusRegistrationDate,
                isTrackingConsentAccepted: isConsentAccepted,
                isTrackingConsentSet: isConsentAccepted !== null,
                performedAction: AllReduxActions.GET_CUSTOMER_INFO_SUCCESS,
            };
        }
        case GET_CUSTOMER_INFO_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CUSTOMER_INFO_FAIL,
            };
        }
        case UPDATE_ONBOARDING_SHOWN: {
            const onboardingAlreadyShown = action.newValue;
            return {
                ...state,
                onboardingAlreadyShown,
                performedAction: AllReduxActions.UPDATE_ONBOARDING_SHOWN,
            };
        }
        case ACCEPT_ANALYSE_OPT_IN: {
            return {
                ...state,
                isActivating: true,
                isLoading: true,
                performedAction: AllReduxActions.SET_ANALYSE_OPT_IN,
            };
        }
        case ACCEPT_ANALYSE_OPT_IN_FAIL: {
            return {
                ...state,
                isActivating: false,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.SET_ANALYSE_OPT_IN_FAIL,
            };
        }
        case ACCEPT_ANALYSE_OPT_IN_SUCCESS: {
            return {
                ...state,
                isActivating: false,
                isLoading: false,
                performedAction: AllReduxActions.SET_ANALYSE_OPT_IN_SUCCESS,
            };
        }
        case SEND_CUSTOMER_CONFIRMATION_MAIL: {
            return {
                ...state,
                isLoading: true,
                isSendingConfirmation: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SEND_CUSTOMER_CONFIRMATION_MAIL,
            };
        }

        case SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isSendingConfirmation: false,
                isConfirmationSentSuccessfully: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SEND_CUSTOMER_CONFIRMATION_MAIL_SUCCESS,
            };
        }

        case SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL: {
            return {
                ...state,
                isLoading: false,
                isSendingConfirmation: false,
                isConfirmationSentSuccessfully: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.SEND_CUSTOMER_CONFIRMATION_MAIL_FAIL,
            };
        }
        case TERMINATE_TEBONUS_CUSTOMER: {
            return {
                ...state,
                isDeleting: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.TERMINATE_TEBONUS_CUSTOMER,
            };
        }
        case TERMINATE_TEBONUS_CUSTOMER_SUCCESS: {
            return {
                ...state,
                isDeleting: false,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.TERMINATE_TEBONUS_CUSTOMER_SUCCESS,
            };
        }
        case TERMINATE_TEBONUS_CUSTOMER_FAIL: {
            return {
                ...state,
                isDeleting: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.TERMINATE_TEBONUS_CUSTOMER_FAIL,
            };
        }
        case UPDATE_TRACKING_CONSENT: {
            let isTrackingConsentAccepted;
            if (action.payload.request.data.data.length === 1) {
                isTrackingConsentAccepted = action.payload.request.data.data[0].isAccepted;
            } else {
                isTrackingConsentAccepted = false;
            }
            prevTrackingConsentAccepted = state.isTrackingConsentAccepted;

            return {
                ...state,
                isLoading: true,
                isTrackingConsentAccepted,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SET_TRACKING_CONSENT,
            };
        }
        case UPDATE_TRACKING_CONSENT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isTrackingConsentSet: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.SET_TRACKING_CONSENT_SUCCESS,
            };
        }
        case UPDATE_TRACKING_CONSENT_FAIL: {
            return {
                ...state,
                isLoading: false,
                isTrackingConsentAccepted: prevTrackingConsentAccepted,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.SET_TRACKING_CONSENT_FAIL,
            };
        }
        case SET_TRACKING_CONSENT_SET: {
            return {
                ...state,
                isTrackingConsentSet: true,
            };
        }

        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case CLEAR_ALL_CUSTOMER: {
            return INITIAL_STATE;
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    ...baseActionCreators,
    getCustomerInfo: (): GetCustomerInfoAction =>
        <GetCustomerInfoAction>{
            type: GET_CUSTOMER_INFO,
            payload: {
                request: {
                    method: "GET",
                    url: `${REACT_APP_IDENTITY_SERVER_URL}/connect/userinfo`,
                },
            },
        },
    acceptAnalyseOptIn: (): AcceptAnalyseOptInAction =>
        <AcceptAnalyseOptInAction>{
            type: ACCEPT_ANALYSE_OPT_IN,
            payload: {
                request: {
                    method: "PUT",
                    url: "/UpdateCustomerOptIns",
                    data: {
                        data: [
                            {
                                type: ConsentTypes.AnalysisOptIn,
                                isAccepted: true,
                            },
                        ],
                    },
                },
            },
        },
    updateOnboardingShown: (value: boolean): UpdateOnboardingShownAction =>
        <UpdateOnboardingShownAction>{
            type: UPDATE_ONBOARDING_SHOWN,
            newValue: value,
        },
    sendConfirmationMail: (): SendCustomerConfirmationMailAction =>
        <SendCustomerConfirmationMailAction>{
            type: SEND_CUSTOMER_CONFIRMATION_MAIL,
            payload: {
                request: {
                    method: "GET",
                    url: "/TriggerDoiMail",
                },
            },
        },
    updateTrackingConsent: (isConsent: boolean): SetTrackingConsentAction =>
        <SetTrackingConsentAction>{
            type: UPDATE_TRACKING_CONSENT,
            payload: {
                request: {
                    method: "PUT",
                    url: "/UpdateCustomerOptIns",
                    data: {
                        data: [
                            {
                                type: ConsentTypes.TrackingConsent,
                                isAccepted: isConsent,
                            },
                        ],
                    },
                },
            },
        },
    setTrackingConsentSet: (): SetIsTrackingConsentSetAction =>
        <SetIsTrackingConsentSetAction>{
            type: SET_TRACKING_CONSENT_SET,
        },

    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_CUSTOMER,
        },

    deleteAccount: (): TerminateTebonusCustomerAction =>
        <TerminateTebonusCustomerAction>{
            type: TERMINATE_TEBONUS_CUSTOMER,
            payload: {
                request: {
                    method: "POST",
                    url: "/TerminateCustomer",
                    data: {
                        appType: "tebonus",
                    },
                },
            },
        },
};

// side effects (thunks)
export type CustomerActions = typeof actionCreators;
