import { MultiplierStatus } from "components/ArticleMultipliers/MultiplierTile";
import { getAppSetting } from "./AppSettings";
import { isNextChangeDateExceeded, wasUpdatedToday } from "./CheckLastUpdate";
import Axios from "axios";

const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");

/**
 * Maps the status of the article multipliers to the respective enum
 * @param isInitial - indicates whether the user has never selected favorite products  before
 * @param isEditable - indicates whether the user can choose new favorite products
 * @returns the correct multiplier status
 */
export function getMultiplierStatus(isInitial: boolean, isEditable: boolean): MultiplierStatus {
    if (isInitial) {
        return MultiplierStatus.INITIAL;
    }
    if (isEditable) {
        return MultiplierStatus.ENABLED;
    }
    return MultiplierStatus.LOCKED;
}

export async function hasInternetConnection(): Promise<boolean> {
    let hasConnection = true;
    await Axios.get(`${REACT_APP_IDENTITY_SERVER_URL}/.well-known/openid-configuration`).catch(error => {
        error.message === "Network Error" && (hasConnection = false);
    });

    return hasConnection;
}

export function getMultplierPromotionCollisionsMessage(firstWarningMsgSentence: string): string {
    return `${firstWarningMsgSentence} 
    Wählen Sie ein Produkt als Lieblingsprodukt und Aktionsprodukt aus, 
    wird dieses Produkt nur einmal mit dem jeweils höheren Punktefaktor belohnt. 
    Tipp: Wählen Sie ein anderes Produkt um alle Vorteile genießen zu können.`;
}

export function hasToBeUpdated(isLoading: boolean, lastUpdate: number, nextChangeDate: string, dataLength: number): boolean {
    return !wasUpdatedToday(lastUpdate) && !isLoading && (isNextChangeDateExceeded(nextChangeDate) || dataLength === 0);
}
