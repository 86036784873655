import { CustomerScoreTransaction } from "types";
import { ScoreTransaction } from "./ScoreTransaction";
import React, { FunctionComponent, ReactElement } from "react";

export interface TransactionsPerMonthProps {
    openedCols: number[];
    setOpenedCols: (value: React.SetStateAction<number[]>) => void;
    displayTransactionsPerMonth: CustomerScoreTransaction[];
}

export const TransactionsPerMonth: FunctionComponent<TransactionsPerMonthProps> = (props): ReactElement => {
    /**
     * When a column is expandet the column id is saved in the opendCol state to track which columns should be extended.
     * If the column is already expanded it will be removed from the list.
     * @param id
     */
    const addRemoveFromOpenedColsList = (id: number): void => {
        if (props.openedCols.includes(id)) {
            // remove from list
            props.setOpenedCols(props.openedCols.filter(item => item !== id));
        } else {
            // add id to list
            props.setOpenedCols(openedCols => [...openedCols, id]);
        }
    };

    return (
        <div className="col-12 col-lg-11 col-xl-10">
            <table className="table table-hover">
                <colgroup>
                    <col style={{ width: "32%" }} />
                    <col style={{ width: "32%" }} />
                    <col style={{ width: "32%" }} />
                    <col style={{ width: "4%" }} />
                </colgroup>
                <thead>
                    <tr className="font-silver font-table-heading">
                        <th className="thead">Datum</th>
                        <th className="thead">Kategorie</th>
                        <th className="thead">Punkte</th>
                        {/* space needed for the transaction icon. */}
                        <th className="thead"></th>
                    </tr>
                </thead>
                {props.displayTransactionsPerMonth.map((transaction, colIndex) => (
                    <ScoreTransaction
                        key={colIndex}
                        transaction={transaction}
                        isColumnOpened={props.openedCols.includes(colIndex)}
                        updateOpenedColsList={() => addRemoveFromOpenedColsList(colIndex)}
                    />
                ))}
            </table>
        </div>
    );
};
