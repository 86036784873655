import dayjs from "dayjs";
import React, { FunctionComponent, ReactElement } from "react";

interface PromotionsHeaderProps {
    arePromotionsLocked: boolean;
    isInitial: boolean;
}

export const PromotionsHeader: FunctionComponent<PromotionsHeaderProps> = (props): ReactElement => {
    function getTitle(): string {
        let title = "Neuer Monat neue Punkte!";

        if (props.arePromotionsLocked) {
            title = `Ihre Aktionsprodukte im Monat\n${dayjs().format("MMMM")}.`;
        } else if (props.isInitial) {
            title = "Bereit zu punkten?";
        }

        return title;
    }

    function getSubtitle(): React.JSX.Element {
        if (props.arePromotionsLocked) {
            return <>Viel Spaß beim Punkte sammeln!</>;
        }

        if (props.isInitial) {
            return (
                <>
                    {"Wählen Sie bis zu "}
                    <span className="font-unitText-Bold">7 Aktionsprodukte</span>
                    {", auf die Sie "}
                    <span className="font-unitText-Bold">diesen Monat</span>
                    {" extra punkten."}
                </>
            );
        }

        return (
            <>
                {"Welche "}
                <span className="font-unitText-Bold">7 Aktionsprodukte</span>
                {" wählen Sie "}
                <span className="font-unitText-Bold">diesen Monat</span>, um Punkte zu sammeln?
            </>
        );
    }

    return (
        <div className="d-flex flex-column align-items-center mb-3">
            <div className="text-justify text-center font-subheading">{getTitle()}</div>
            <div className="text-justify text-center font-description">{getSubtitle()}</div>
        </div>
    );
};
