import { ExpandedPurchaseProps } from "./types";
import React, { FunctionComponent, ReactElement } from "react";

export const ExpandedPurchaseDesktop: FunctionComponent<ExpandedPurchaseProps> = (props): ReactElement => {
    return (
        <>
            <tr className="font-unitText-Bold bg-transparentGray table-borderless d-none d-md-table-row">
                <td colSpan={4} className="pb-2 pt-1">
                    {`${props.transaction.reason} um ${props.receiptDate}`}
                </td>
            </tr>
            {/* Part of the transaction which is hidden until the expand arrow was pressed */}
            {props.storeName && (
                <tr className="font-unitText-Bold bg-transparentGray table-borderless d-none d-md-table-row">
                    <td colSpan={4} className="pb-2 pt-1">
                        {`tegut… Markt ${props.storeName}`}
                    </td>
                </tr>
            )}

            <tr className="font-unitText-Bold noPaddingNoBorderTable d-none d-md-table-row">
                <td>
                    <div className="row">
                        <div className="col-8">Bonsumme:</div>
                        <div className="col-4">{props.transaction.totalPrice}€</div>
                    </div>
                </td>
                <td>
                    <div className="row">
                        <div className="col-8">Gesammelte Punkte:</div>
                        <div className="col-4">{props.transaction.totalPointsEarned}</div>
                    </div>
                </td>
                <td>
                    <div className="row">
                        <div className="col-8">Punktestand nach Einkauf:</div>
                        <div className="col-4">{props.transaction.totalPointsAfterTransaction}</div>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr className="noPaddingNoBorderTable d-none d-md-table-row">
                <td>
                    <div className="row">
                        <div className="col-8">Punktefähige Summe*:</div>
                        <div className="col-4">{props.transaction.totalDiscountablePrice}€</div>
                    </div>
                </td>
                <td>
                    <div className="row">
                        <div className="col-8">Basispunkte:</div>
                        <div className="col-4">{props.transaction.basePointsEarned}</div>
                    </div>
                </td>
                <td>
                    <div className="row">
                        <div className="col-8">Punktestand vor Einkauf:</div>
                        <div className="col-4">{props.transaction.totalPointsBeforeTransaction}</div>
                    </div>
                </td>
                <td></td>
            </tr>

            <tr className="noPaddingNoBorderTable d-none d-md-table-row">
                <td></td>
                <td>
                    <div className="row">
                        <div className="col-8">Bonuspunkte:</div>
                        <div className="col-4">{props.transaction.bonusPoints}</div>
                    </div>
                </td>
                <td></td>
                <td></td>
            </tr>

            <tr className="noPaddingNoBorderTable d-none d-md-table-row">
                <td></td>
                <td>
                    <div className="row">
                        {/* earn/ incentive points */}
                        {props.transaction.incentiveDetails.map(incentive => {
                            return (
                                <>
                                    <div className="col-8 truncate-2">{incentive.name}</div>
                                    <div className="col-4">{incentive.points}</div>
                                </>
                            );
                        })}
                    </div>
                </td>
                <td></td>
                <td></td>
            </tr>

            <tr className="noPaddingNoBorderTable d-none d-md-table-row">
                <td></td>
                <td>
                    <div className="row">
                        <div className="col-8">Eingelöste Punkte:</div>
                        <div className="col-4">{props.transaction.totalPointsSpent}</div>
                    </div>
                </td>
                <td></td>
                <td></td>
            </tr>

            <tr className="bg-transparentGray table-borderless d-none d-md-table-row">
                <td colSpan={4} className="conditionText pt-3 pb-4">
                    {props.exceptionsText}
                </td>
            </tr>
        </>
    );
};
