/* eslint-disable no-extra-boolean-cast */
import { TegutBadge } from "components/_tegut/TegutBadge";
import { DuplicateErrorMsg, multipliersHasDuplicates } from "pages/ArticleMultipliers/utils";
import { AppState } from "store/configureStore";
import { Colors } from "styles";
import { Article } from "types";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";
import { useFocusTrap } from "utils/useFocusTrap";
import { getImagePath } from "utils/ImagePath";
import defaultProduct from "../../../assets/icons/products/default-camera-icon.svg";
import { TebonusSingleHeart } from "../../_graphics";
import React, { FunctionComponent, ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import CloseSharp from "@material-ui/icons/CloseSharp";
import Check from "@material-ui/icons/Check";

interface SaveMultipliersModalProps {
    title: string;
    subtitle: string;
    buttonPositiveText: string;
    buttonNegativeText: string;
    onButtonPositiveClick: () => void;
    onButtonNegativeClick: () => void;
    selectedMultipliers: Article[];
}

const SaveMultipliersModal: FunctionComponent<SaveMultipliersModalProps> = props => {
    const articleMultipliers = useSelector((state: AppState) => state.multiplierReducer);
    const dialogRef = useFocusTrap();

    useEffect(() => {
        toggleModalOpenClass(true);

        if (dialogRef && dialogRef.current) {
            dialogRef.current.focus();
        }
        return () => {
            toggleModalOpenClass(false);
        };
    }, []);

    /**
     * Renders the badge that shows the points multiplier received with a certain product.
     * Multipliers:
     * 1st product -> 20×
     * 2nd product -> 10×
     * 3rd product -> 3×
     * @param index The index of the product. Used to determine the multiplier amount (20×, 10×, or 5×)
     * @returns
     */
    const renderDetailsBadge = (index: number): ReactElement => {
        return (
            <TegutBadge className="badge-multiplier-dashboard modal-detail-badge" bgColor={Colors.plumPie}>
                <div className="d-flex flex-column">
                    <div className="badge-advantageAmount-points-dashboard-fav">{`${articleMultipliers.values[index]}×`}</div>
                    <div className="badge-advantageAmount-text-dashboard-fav">Punkte</div>
                </div>
            </TegutBadge>
        );
    };

    /**
     * Renders the icon to the right of the product text. It indicates whether the product
     * has been selected (green icon) or not (red icon).
     * @param item The current item fo which an icon is generated.
     * @returns
     */
    const renderIcons = (item: Article): ReactElement => {
        const isValid = item && !(props.selectedMultipliers.filter(m => m && m.tan === item.tan).length > 1);

        return (
            <div style={{ paddingLeft: 10 }}>
                <TegutBadge className="modal-icon-badge" bgColor={isValid ? Colors.seaweedGreen : Colors.white}>
                    {isValid ? (
                        <Check style={{ color: Colors.white, fontSize: 50 }} />
                    ) : (
                        <CloseSharp className="modal-close-icon" style={{ color: Colors.errorRed, fontSize: 50 }} />
                    )}
                </TegutBadge>
            </div>
        );
    };

    /**
     * Renders a product that the user selected as article multiplier.
     * @param item The current item
     * @param index The index of the current item.
     * @returns
     */
    const renderSelectedProduct = (item: Article, index: number): ReactElement => {
        return (
            <div className="parent-overlapping multiplier-modal-image-container" aria-label="Produktbild Lieblingsprodukte">
                <img
                    className="selected-multiplier"
                    src={getImagePath(item)}
                    alt={!!item.name ? item.name : "Produktbild Lieblingsprodukte"}
                    onError={({ currentTarget }) => {
                        currentTarget.src = defaultProduct;
                    }}
                />
                {renderDetailsBadge(index)}
            </div>
        );
    };

    /**
     * Renders a badge with the Tebonus heart in it. The badge indicates that the
     * user has not selected a product for the current index.
     * @param index The index of the slot for which no product has been selected.
     * @returns
     */
    const renderUnselectedProduct = (index: number): ReactElement => {
        return (
            //  the minimum width is the width of the badge + the extra width for the details
            <div className="parent-overlapping multiplier-modal-image-container">
                <TegutBadge className="img-empty-badge-fav unselected-multiplier" bgColor={Colors.white}>
                    <TebonusSingleHeart color={Colors.lightGray} preserveAspectRatio={true} />
                </TegutBadge>
                {renderDetailsBadge(index)}
            </div>
        );
    };

    /**
     * Renders a container for the multipliers.
     * Each multiplier container consists of a place
     * 1. for the product picture, or default badge,
     * 2. for the name of the product, or default text, and
     * 3. for the icon that indicates whether the product has been selected or not.
     * @returns
     */
    const renderMultiplierContainer = (): React.JSX.Element => {
        return (
            <div>
                {props.selectedMultipliers.map((item, index) => {
                    return (
                        <div className="d-flex flex-row align-items-center multiplier-container" key={index}>
                            {/* product image with details badge */}
                            {item ? renderSelectedProduct(item, index) : renderUnselectedProduct(index)}

                            {/* product text */}
                            <div className="truncate-2 d-flex w-75 font-unitText-Regular font-dimGray">
                                <div className="truncate-2">
                                    {item
                                        ? item.name
                                        : [
                                              <span key="no-product" className="font-unitText-Bold">
                                                  Produkt für diesen Monat nicht ausgewählt.
                                              </span>,
                                          ]}
                                </div>
                            </div>

                            {/* green check icons for selected products and red close icons for unselected ones*/}
                            {renderIcons(item)}
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className="center-on-container">
            {/* disable background */}
            <div className="modal-bg-disabled"></div>

            {/* actual modal */}
            <div tabIndex={-1} role="dialog" className="modal-position" ref={dialogRef}>
                <div className="modal-tile-information" role="document" style={{ width: "85%", maxWidth: "600px" }}>
                    {/* title and description text */}
                    <div className="modal-content-multiplier table-scroll-bar">
                        <div className="multiplier-modal-header">
                            <div className="modal-title text-center">{props.title}</div>
                            <div className="font-unitText-Regular text-center font-dimGray"> {props.subtitle}</div>
                        </div>
                        {renderMultiplierContainer()}
                        {/* Confirm and  cancel buttons*/}
                        <div className="multiplier-modal-footer">
                            {multipliersHasDuplicates(props.selectedMultipliers) && (
                                <p className="font-unitText-Regular font-red text-center">{DuplicateErrorMsg}</p>
                            )}
                            <button
                                className="btn btn-orange btn-select"
                                onClick={props.onButtonPositiveClick}
                                disabled={multipliersHasDuplicates(props.selectedMultipliers)}
                            >
                                {props.buttonPositiveText}
                            </button>
                            <button className="btn btn-outline-orange btn-select mt-3" onClick={props.onButtonNegativeClick}>
                                {props.buttonNegativeText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaveMultipliersModal;
