import { Article, MultiplierBaseProps } from "types";
import { getImagePath } from "utils/ImagePath";
import { getMultiplierStatus } from "utils/MultiplierPromotionUtils";
import MultiplierTile, { MultiplierStatus } from "./MultiplierTile";
import React, { FunctionComponent, ReactElement } from "react";

interface ProductTilesProps extends MultiplierBaseProps {
    isOnDetailsPage: boolean;
    changedMultipliers: Article[];
    amounts: number[];
    openArticleSelection: (index: number) => void;
}

const ProductTiles: FunctionComponent<ProductTilesProps> = (props): ReactElement => {
    const emptyItems = [0, 1, 2];

    return (
        <div className="d-flex flex-column text-center">
            {props.isLoading ? (
                <div className="d-flex justify-content-center flex-row flex-wrap">
                    {emptyItems.map(item => (
                        <MultiplierTile
                            isOnDetailsPage={props.isOnDetailsPage}
                            multiplierStatus={MultiplierStatus.EMPTY}
                            key={item}
                        />
                    ))}
                </div>
            ) : (
                <div className="d-flex justify-content-center flex-grow flex-wrap">
                    {props.changedMultipliers.map((article, index) => (
                        <MultiplierTile
                            key={index}
                            openArticleFinder={() => props.openArticleSelection(index)}
                            advantageAmount={props.amounts[index]}
                            multiplierStatus={getMultiplierStatus(props.isInitial, props.isEditable)}
                            imagePath={getImagePath(article)}
                            isOnDetailsPage={props.isOnDetailsPage}
                            title={article ? article.name : ""}
                            description={article ? article.description : ""}
                            hasTileSelectedItem={!!sessionStorage.getItem(`${index}`)}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default ProductTiles;
