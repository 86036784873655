import { AnalyticsButtonPromptInfos } from "types";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";
import { logAnalyticsButton } from "utils/FirebaseAnalytics";
import { useFocusTrap } from "utils/useFocusTrap";
import React, { FunctionComponent, ReactElement, useEffect } from "react";

interface CustomLeavePagePromptProps {
    title: string;
    description: string;
    buttonPositiveText: string;
    buttonNegativeText: string;
    handleUserAction: Function;
    discardButtonLogInfos?: AnalyticsButtonPromptInfos;
    returnButtonLogInfos?: AnalyticsButtonPromptInfos;
}

const CustomLeavePagePrompt: FunctionComponent<CustomLeavePagePromptProps> = (
    props: CustomLeavePagePromptProps
): ReactElement => {
    const dialogRef = useFocusTrap();

    useEffect(() => {
        toggleModalOpenClass(true);

        return () => {
            toggleModalOpenClass(false);
        };
    }, []);

    const logButtonEvent = (buttonLogInfos: AnalyticsButtonPromptInfos): void => {
        logAnalyticsButton(buttonLogInfos.parameters);
    };

    const leavePage = (): void => {
        logButtonEvent(props.discardButtonLogInfos);
        props.handleUserAction(true);
    };

    const stayOnPage = (): void => {
        logButtonEvent(props.returnButtonLogInfos);
        props.handleUserAction(false);
    };

    return (
        <div className="center-on-container">
            {/* div which creates a dark background over the whole screen which is not clickable */}
            <div className="modal-bg-disabled"></div>

            <div className="modal-position" ref={dialogRef}>
                <div className="modal-tile-information d-flex flex-column align-items-center ">
                    <div className="modal-title text-center">{props.title}</div>
                    <div className="modal-body-text mt-3 text-center">{props.description}</div>
                    <div className="mt-4 d-flex flex-column align-items-center">
                        <button onClick={leavePage} className="btn btn-orange btn-select w-100" autoFocus>
                            {props.buttonPositiveText}
                        </button>
                        <button onClick={stayOnPage} className="btn btn-outline-orange btn-select mt-3 w-100">
                            {props.buttonNegativeText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomLeavePagePrompt;
