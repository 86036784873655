import { PromotionAdvantage } from "../../types";
import { TegutBadge } from "../_tegut/TegutBadge";
import { TebonusBagIcon, TebonusInfoIcon } from "../_graphics";
import defaultProduct from "../../assets/icons/products/default-camera-icon.svg";
import { Colors } from "../../styles";
import React, { FunctionComponent, useState } from "react";
import Add from "@material-ui/icons/Add";
import CloseSharp from "@material-ui/icons/CloseSharp";
import CheckIcon from "@material-ui/icons/Check";

export enum ProductStatus {
    isIdle,
    isSelected,
    isActivated,
    isDisabled,
    isEmpty,
}

interface ProductTileProps {
    id?: string;
    promotionTitle?: string;
    image?: string;
    advantageAmount?: number;
    advantage?: number;
    onSelectButton?: Function;
    productStatus: ProductStatus;
    onClick?: () => void; // used in the article search, when the whole tile should be clickable
    showSelectionBadge?: boolean;
}

const ProductTile: FunctionComponent<ProductTileProps> = props => {
    // if no image is included take a default image
    const productImage = props.image ? props.image : defaultProduct;

    const SELECT_SYMBOL =
        props.productStatus === ProductStatus.isActivated || props.productStatus === ProductStatus.isSelected ? (
            <CheckIcon className="badge-select-text" />
        ) : (
            <Add className="badge-select-text" />
        );

    const [showBack, setShowBack] = useState(false);

    function handleClicked(): void {
        return setShowBack(!showBack);
    }

    /**
     * @returns the batch text with the multiplier points
     */
    function getAdvantageAmountBatchText(): React.JSX.Element {
        if (props.advantage === PromotionAdvantage.Multiplier) {
            return <>{props.advantageAmount}×</>;
        }
        return <> +{props.advantageAmount}</>;
    }

    /**
     *
     * @returns an empty tile with no content.
     */
    function showEmptyTile(): React.JSX.Element {
        return (
            <div className="emptyProductTile">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <TegutBadge className="emptyProductTile-badge" bgColor={Colors.white}>
                        <TebonusBagIcon htmlColor={Colors.lightGray} style={{ fontSize: 64 }} />
                    </TegutBadge>
                </div>
            </div>
        );
    }

    /**
     * @returns a product tile with the corresponding content
     */
    function showProductTile(): React.JSX.Element {
        return (
            <div
                // parent-overlapping is needed in the className if the flip tile button is used
                className={`productTile
                    ${
                        props.productStatus === ProductStatus.isSelected || props.productStatus === ProductStatus.isActivated
                            ? "productTile-selected"
                            : "productTile-deselected"
                    }
                    ${props.productStatus === ProductStatus.isDisabled && "tile-inactive"}
                    flip-card ${showBack ? "showBack" : ""}
                    `}
            >
                <div className="card card-front">
                    {/* Top button Add. The button will be hidden if it is not needed to keep the design of the tile. */}
                    <div
                        className="d-flex justify-content-end"
                        style={props.showSelectionBadge ? { visibility: "visible" } : { visibility: "hidden" }}
                    >
                        <button
                            className="btn-badge-select"
                            onClick={() => !!props.onSelectButton && props.onSelectButton()}
                            disabled={
                                props.productStatus === ProductStatus.isActivated ||
                                props.productStatus === ProductStatus.isDisabled
                            }
                            aria-label="Wählen"
                        >
                            <TegutBadge
                                className="badge-select"
                                bgColor={
                                    props.productStatus === ProductStatus.isSelected ||
                                    props.productStatus === ProductStatus.isActivated
                                        ? Colors.seaweedGreen
                                        : Colors.orange
                                }
                            >
                                {SELECT_SYMBOL}
                            </TegutBadge>
                        </button>
                    </div>

                    {/* Product image + xPunkte Batch */}
                    <div
                        className={`d-flex justify-content-center parent-overlapping
                        ${props.productStatus !== ProductStatus.isActivated && "cursor-pointer"} `}
                        onClick={
                            props.productStatus !== ProductStatus.isActivated
                                ? () => !!props.onSelectButton && props.onSelectButton()
                                : undefined
                        }
                    >
                        {/* product image */}
                        <img
                            className="product-image"
                            onError={({ currentTarget }) => {
                                currentTarget.src = defaultProduct;
                            }}
                            src={productImage}
                            loading="lazy"
                            alt={props.promotionTitle}
                        />

                        {/* multiplier badge */}
                        <TegutBadge className="badge-multiplier" bgColor={Colors.plumPie}>
                            {/* show 20x or +20 as multiplier*/}
                            <div>
                                <div className="badge-advantageAmount-points">{getAdvantageAmountBatchText()}</div>
                                <div className="badge-advantageAmount-text">Punkte</div>
                            </div>
                        </TegutBadge>
                    </div>

                    <div className="mt-2">
                        <div className="promotion-title truncate-text">{props.promotionTitle}</div>
                    </div>
                    {/* Info Button */}
                    <div className="info-btn">
                        <button className="btn-badge-select" onClick={handleClicked} aria-label="Mehr Informationen">
                            <TebonusInfoIcon htmlColor={Colors.orange} fontSize="small" />
                        </button>
                    </div>
                </div>

                <div className="card card-back">
                    <div className="d-flex justify-content-end">
                        <button className="btn-badge-select" onClick={handleClicked} aria-label="Schließen">
                            <CloseSharp className="icon-accent-wrapper icon-accent-orange"></CloseSharp>
                        </button>
                    </div>
                    <div className="promotion-title text-left w-100 table-scroll-bar">{props.promotionTitle}</div>
                </div>
            </div>
        );
    }

    return props.productStatus === ProductStatus.isEmpty ? showEmptyTile() : showProductTile();
};
export default ProductTile;
