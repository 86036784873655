import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";
import React, { FunctionComponent, ReactElement } from "react";

interface RetrySavingModalProps {
    onSaveSelectionButton: Function;
    onReturnButton: Function;
}

export const RetrySavingModal: FunctionComponent<RetrySavingModalProps> = (props): ReactElement => {
    return (
        <ModalConfirmInfo
            titleText="Etwas ist schiefgelaufen"
            bodyText="Bei ihrer Aktion ist leider ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
            button1Text="Erneut versuchen"
            button1function={() => {
                logAnalyticsButton({ button_title: CommonButtonTitles.selection.saveSelection });
                props.onSaveSelectionButton();
            }}
            button2Text="Später versuchen"
            button2function={() => {
                props.onReturnButton();
            }}
        />
    );
};
