import LoadingIndicator from "components/LoadingIndicator";
import { PromotionAdvantage } from "../../types";
import { TegutBadge } from "../_tegut/TegutBadge";
import { TebonusBagIcon, TebonusSingleHeart } from "../_graphics";
import defaultProduct from "../../assets/icons/products/default-camera-icon.svg";
import { Colors } from "../../styles";
import React, { FunctionComponent } from "react";
import CheckIcon from "@material-ui/icons/Check";

export enum ProductStatus {
    isIdle, // isEditable
    isActivated, // isLocked
    isEmpty, // isInitial
    isLoading,
}

export enum TilePlacement {
    dashboardWeeklyHits,
    dashboardPromotions,
    dashboardMultiplier,
}

interface PromoProductTileDashboardProps {
    title?: string;
    image?: string;
    advantageAmount?: number;
    advantage?: PromotionAdvantage;
    productStatus: ProductStatus;
    tilePlacement: TilePlacement;
    isArticleLocked?: boolean;
}

const DashboardProductTile: FunctionComponent<PromoProductTileDashboardProps> = props => {
    const SELECT_SYMBOL = <CheckIcon className="badge-select-icon-dashboard-promotions" />;
    // if no image is included take a default image
    const productImage = props.image ? props.image : defaultProduct;

    // image size is done by 100%
    const IMG_SIZE_HEIGHT = "100%";
    const IMG_SIZE_WIDTH = "100%";

    function getTileStyle(): string {
        return props.tilePlacement === TilePlacement.dashboardWeeklyHits
            ? "dashboardTile-weeklyHits"
            : "dashboardTile-promotions";
    }

    function getBadgeMultiplierStyle(): string {
        return props.tilePlacement === TilePlacement.dashboardWeeklyHits
            ? "badge-multiplier-dashboard-weeklyHits"
            : "badge-multiplier-dashboard-promotions";
    }

    function getAdvantagePointsStyle(): string {
        return props.tilePlacement === TilePlacement.dashboardWeeklyHits
            ? "badge-advantageAmount-points-dashboard-weeklyHits"
            : "badge-advantageAmount-points-dashboard-promotions";
    }

    function getAdvantageTextStyle(): string {
        return props.tilePlacement === TilePlacement.dashboardWeeklyHits
            ? "badge-advantageAmount-text-dashboard-weeklyHits"
            : "badge-advantageAmount-text-dashboard-promotions";
    }

    /**
     * Decides if the advantage amount is written as +amount or as amount×
     * @returns the advanatage amount with "+" or "×"
     */
    function getAdvantageAmountBatchText(): string {
        if (props.advantage === PromotionAdvantage.Multiplier) {
            return `${props.advantageAmount}×`;
        }

        return `+${props.advantageAmount}`;
    }

    /**
     * @returns an empty tile with the tebonusBag logo
     */
    function showEmptyTile(): React.JSX.Element {
        return (
            <div className={getTileStyle()}>
                <div className="d-flex justify-content-center align-items-center h-100">
                    {/* Show loading indicator when it is loading */}
                    {props.productStatus == ProductStatus.isLoading ? (
                        <LoadingIndicator isPositionStatic={true}></LoadingIndicator>
                    ) : (
                        /* If it is an empty tile show empty badge */
                        <TegutBadge className="emptyProductTile-badge-dashboard" bgColor={Colors.white}>
                            {props.tilePlacement == TilePlacement.dashboardMultiplier ? (
                                <TebonusSingleHeart color={Colors.lightGray} preserveAspectRatio={true} />
                            ) : (
                                <TebonusBagIcon htmlColor={Colors.lightGray} style={{ width: "90%", height: "90%" }} />
                            )}
                        </TegutBadge>
                    )}
                </div>
            </div>
        );
    }

    function renderTebonusHeart(): React.JSX.Element {
        return (
            <div className="d-flex w-100 justify-content-center">
                <TegutBadge className="emptyProductTile-badge-dashboard" bgColor={Colors.white}>
                    <TebonusSingleHeart color={Colors.lightGray} preserveAspectRatio={true} />
                </TegutBadge>
            </div>
        );
    }

    function renderProductImage(): React.JSX.Element {
        return (
            <img
                src={productImage}
                onError={({ currentTarget }) => {
                    currentTarget.src = defaultProduct;
                }}
                width={IMG_SIZE_WIDTH}
                height={IMG_SIZE_HEIGHT}
                loading="lazy"
                alt={props.title}
                style={{ objectFit: "contain" }}
            />
        );
    }

    /**
     * Creates a product tile with the corresponding texts from the props.
     * The style is dependent on if the product is activated and where it is placed
     * @returns a product tile
     */
    function showProductTile(): React.JSX.Element {
        const isDashboardPlacementMultiplier = props.tilePlacement === TilePlacement.dashboardMultiplier;
        const isDashboardMultiplierIdle = isDashboardPlacementMultiplier && props.productStatus === ProductStatus.isIdle;
        const isDashboardMultiplierImageSet = isDashboardPlacementMultiplier && props.image === null;

        return (
            <div className={`${getTileStyle()} d-flex flex-column justify-content-center parent-overlapping`}>
                {/* Select status is only shown if the product is activated*/}
                {props.productStatus === ProductStatus.isActivated && props.isArticleLocked && (
                    <button className="btn-badge-select" disabled={true}>
                        <TegutBadge className="badge-select-button-dashboard tile-inactive" bgColor={Colors.seaweedGreen}>
                            {SELECT_SYMBOL}
                        </TegutBadge>
                    </button>
                )}

                {/* product image */}

                {/* w-100 and h-100 is needed to define the maximum size of the contained image */}
                <div className="d-flex w-100 h-100 m-auto " aria-label="Produktbild">
                    {/* image will be as large as possible */}
                    {/* Cases when the heart icon should be shown:
                    1. the tile is a multiplier (not a promotion) and it is in initial status (idle)
                    2. the tile is a multipler (not a promotion), the status is activated/locked,
                       and the image path is null (i.e., this particular article has been left out by the user) */}
                    {isDashboardMultiplierIdle || isDashboardMultiplierImageSet ? renderTebonusHeart() : renderProductImage()}
                </div>

                {/* multiplier badge */}
                <TegutBadge className={getBadgeMultiplierStyle()} bgColor={Colors.plumPie}>
                    {/* show 20x or +20 as multiplier*/}
                    <div>
                        <div className={getAdvantagePointsStyle()}>{getAdvantageAmountBatchText()}</div>
                        <div className={getAdvantageTextStyle()}>Punkte</div>
                    </div>
                </TegutBadge>
            </div>
        );
    }

    return props.productStatus === ProductStatus.isEmpty || props.productStatus === ProductStatus.isLoading
        ? showEmptyTile()
        : showProductTile();
};

export default DashboardProductTile;
