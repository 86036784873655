import ModalConfirmInfo from "components/_overlays/ConfirmationModal";
import { Colors } from "styles";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import { useFocusTrap } from "utils/useFocusTrap";
import { actionCreators } from "../../store";
import { AppState } from "../../store/configureStore";
import { ErrorStatusCode } from "../../types";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

interface AnalyzeOptInModalProps {
    onCloseButtonClick: () => void;
    setHasOptInError: (hasError: boolean) => void;
    handleAgreement: () => void;
}

const AnalyzeOptInModal: FunctionComponent<AnalyzeOptInModalProps> = props => {
    const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [wasConfirmationClicked, setWasConfirmationClicked] = useState<boolean>(false);

    const customers = useAppSelector((state: AppState) => state.customerReducer);

    const dialogRef = useFocusTrap();

    useEffect(() => {
        toggleModalOpenClass(true);

        if (dialogRef && dialogRef.current) {
            dialogRef.current.focus();
        }

        return () => {
            toggleModalOpenClass(false);
        };
    }, []);

    useEffect(() => {
        if (wasConfirmationClicked && !customers.isActivating) {
            if (customers.errorCode !== ErrorStatusCode.noError) {
                props.onCloseButtonClick();
                props.setHasOptInError(true);
            } else if (customers.errorCode === ErrorStatusCode.noError) {
                props.handleAgreement();
            }
        }
    }, [customers.isActivating]);

    function setAnalysisOptInDate(): void {
        dispatch(actionCreators.customers.acceptAnalyseOptIn());
    }

    function renderBenefitsModal(): React.JSX.Element {
        return (
            <div className="center-on-container font-unitText-Regular font-dimGray">
                <div className="benefit-modal-overlay d-flex justify-content-center">
                    <div className="benefits-modal-content ">
                        <div className="modal-title font-black" style={{ marginBottom: "10px" }}>
                            tegut… Exklusiv-Vorteile auf einen Blick:
                        </div>
                        <ul>
                            <li>tebonus Punkte-Aktionen</li>
                            <li>Coupons und Rabatte</li>
                            <li>Aktuelles zu Lebensmitteln, Haushalts-, Drogerie- und Aktionswaren</li>
                            <li>Rezeptideen und Gewinnspiele</li>
                            <li>Einladungen zu tegut… Veranstaltungen</li>
                            <li>Kundenbefragungen</li>
                        </ul>
                        <button
                            type="button"
                            className="btn btn-orange btn-select mb-3"
                            onClick={() => setIsBenefitsModalOpen(false)}
                        >
                            Schließen
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    return customers.isLoading ? (
        // Show loading indicator when the customer is loading/ activating the analyseOptIn
        <ModalConfirmInfo showLoadingIndicator={true} />
    ) : (
        <div className="center-on-container">
            <div className="modal-bg-disabled"></div>
            <div tabIndex={-1} role="dialog" className="modal-position" ref={dialogRef}>
                {/* d-flex justify-content-center"> */}
                <div className="d-flex flex-column" style={{ position: "relative" }}>
                    {isBenefitsModalOpen && renderBenefitsModal()}
                    <div className="analyze-opt-in-modal d-flex flex-column align-items-center">
                        <div className="modal-title text-center px-3" style={{ marginBottom: 20 }}>
                            Jetzt persönliche Vorteile aktivieren!
                        </div>

                        <div
                            style={{
                                height: "450px",
                                overflowY: "scroll",
                            }}
                            className="font-unitText-Regular font-dimGray table-scroll-bar px-3"
                        >
                            <div>
                                Ich möchte <span className="font-unitText-Bold">auf mich persönlich zugeschnittene</span>{" "}
                                <span className="tebonus-benefits-link" onClick={() => setIsBenefitsModalOpen(true)}>
                                    tegut… Exklusiv-Vorteile
                                </span>{" "}
                                erhalten und von <span className="font-unitText-Bold">allen tebonus-Punkte-Aktionen</span>{" "}
                                profitieren. Hierzu willige ich ein, dass meine personenbezogenen Daten in einem{" "}
                                <span className="font-unitText-Bold">tebonus Kundenprofil</span> verarbeitet und nach meinen
                                persönlichen Interessen analysiert werden.
                            </div>

                            <div style={{ marginTop: 10 }}>
                                Dies umfasst personenbezogene Daten aus:
                                <ul>
                                    <li>
                                        meiner Anmeldung, dem Einsatz meiner tebonus Kundenkarte un der Kommunikation hierzu mit
                                        tegut… per Post, Telefon, E-Mail sowie über die tebonus-App und Online-Kontaktforumlare
                                    </li>
                                    <li>
                                        meinem Nutzungsverhalten im Newsletter, in der tebonus App und auf der tegut… Webseite
                                        (sofern ich einem damit verbundenen Tracking jeweils zugestimmt habe)
                                    </li>
                                    <li>Kundenbefragungen</li>
                                </ul>
                                Meine Einwilligung kann ich jederzeit für die Zukunft widerrufen.
                            </div>
                            <div className="mt-4 d-flex flex-column align-items-center">
                                <button
                                    className="btn btn-orange btn-select"
                                    onClick={() => {
                                        setWasConfirmationClicked(true);
                                        setAnalysisOptInDate();
                                    }}
                                >
                                    Zustimmen
                                </button>
                                <button
                                    className="btn btn-outline-orange btn-select mt-3"
                                    onClick={() => props.onCloseButtonClick()}
                                >
                                    Jetzt nicht
                                </button>
                            </div>
                            <div className="modal-footer justify-content-center" style={{ marginTop: "40px" }}>
                                <Link
                                    to="/Impressum"
                                    target="_blank"
                                    className="tebonus-benefits-link font-unitText-Regular font-dimGray"
                                >
                                    Impressum
                                </Link>
                                <Link
                                    to="/Datenschutz"
                                    target="_blank"
                                    className="tebonus-benefits-link font-unitText-Regular font-dimGray"
                                >
                                    Datenschutz
                                </Link>
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={() => props.onCloseButtonClick()}
                        style={{
                            position: "absolute",
                            right: "15px",
                            top: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                        }}
                        aria-label="Schließen"
                    >
                        <CloseSharpIcon
                            style={{
                                color: Colors.dimGray,
                            }}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
};
export default AnalyzeOptInModal;
