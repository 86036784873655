// https://github.com/cytrowski/teedux
import { AppThunkAction } from "store";
import {
    AllReduxActions,
    AxiosRequestError,
    BaseType,
    CustomerScoreTransaction,
    ErrorStatusCode,
    ReduxBaseType,
} from "../types";
import RequestStatus from "../utils/RequestStatus";
import { baseActionCreators, BaseKnownAction, CLEAR_LOADING_STATE } from "./BaseActions";
import { Reducer } from "redux";
import dayjs from "dayjs";
import { AxiosRequestConfig, AxiosResponse } from "axios";

// Actions
const GET_CUSTOMER_SCORE = "pekuma/customers/GET_SCORE";
const GET_CUSTOMER_SCORE_SUCCESS = "pekuma/customers/GET_SCORE_SUCCESS";
const GET_CUSTOMER_SCORE_FAIL = "pekuma/customers/GET_SCORE_FAIL";

const GET_CUSTOMER_SCORETRANSACTIONS = "pekuma/customers/GET_SCORETRANSACTIONS";
const GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS = "pekuma/customers/GET_SCORETRANSACTIONS_SUCCESS";
const GET_CUSTOMER_SCORETRANSACTIONS_FAIL = "pekuma/customers/GET_SCORETRANSACTIONS_FAIL";

const CLEAR_CUSTOMER_SCORE_TRANSACTIONS = "pekuma/customers/CLEAR_CUSTOMER_SCORE_TRANSACTIONS";

const CLEAR_ALL_CUSTOMER_SCORE = "pekuma/customers/CLEAR_ALL";

interface GetCustomerScore {
    totalPoints: number;
    lastUpdate: number;
}

export interface GetCustomerScoreAction {
    type: typeof GET_CUSTOMER_SCORE;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCustomerScoreSuccessAction {
    type: typeof GET_CUSTOMER_SCORE_SUCCESS;
    payload: AxiosResponse<GetCustomerScore>;
}

interface GetCustomerScoreFailAction {
    type: typeof GET_CUSTOMER_SCORE_FAIL;
    error: AxiosRequestError;
}

interface GetCustomerScoreTransacions {
    transactions: CustomerScoreTransaction[];
}

export interface GetCustomerScoreTransactionsAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS;
    payload: {
        request: AxiosRequestConfig;
    };
}

interface GetCustomerScoreTransactionsSuccessAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS;
    payload: AxiosResponse<GetCustomerScoreTransacions>;
}

interface GetCustomerScoreTransactionsFailAction {
    type: typeof GET_CUSTOMER_SCORETRANSACTIONS_FAIL;
    error: AxiosRequestError;
}

interface ClearTransactionsStateAction {
    type: typeof CLEAR_CUSTOMER_SCORE_TRANSACTIONS;
}
interface ClearAllAction {
    type: typeof CLEAR_ALL_CUSTOMER_SCORE;
}

export type KnownAction =
    | BaseKnownAction
    | GetCustomerScoreAction
    | GetCustomerScoreSuccessAction
    | GetCustomerScoreFailAction
    | GetCustomerScoreTransactionsAction
    | GetCustomerScoreTransactionsSuccessAction
    | GetCustomerScoreTransactionsFailAction
    | ClearTransactionsStateAction
    | ClearAllAction;

export interface CustomerScoreState extends BaseType, ReduxBaseType {
    currentScore: number | undefined;
    scoreTransactions: CustomerScoreTransaction[];
    areTransactionsLoading: boolean;
}

export const INITIAL_STATE: CustomerScoreState = {
    currentScore: undefined,
    lastUpdate: 0,
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
    performedAction: null,
    scoreTransactions: [],
    areTransactionsLoading: false,
};

// Reducer
export const reducer: Reducer<CustomerScoreState> = (state = INITIAL_STATE, action: KnownAction): CustomerScoreState => {
    switch (action.type) {
        case GET_CUSTOMER_SCORE: {
            return {
                ...state,
                isLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE,
            };
        }
        case GET_CUSTOMER_SCORE_SUCCESS: {
            const { totalPoints } = action.payload.data;
            return {
                ...state,
                isLoading: false,
                currentScore: totalPoints,
                lastUpdate: dayjs().unix(),
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE_SUCCESS,
            };
        }
        case GET_CUSTOMER_SCORE_FAIL: {
            return {
                ...state,
                isLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CUSTOMER_SCORE_FAIL,
            };
        }

        case GET_CUSTOMER_SCORETRANSACTIONS: {
            return {
                ...state,
                areTransactionsLoading: true,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS,
            };
        }
        case GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS: {
            return {
                ...state,
                areTransactionsLoading: false,
                scoreTransactions: action.payload.data?.transactions,
                errorCode: ErrorStatusCode.noError,
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS_SUCCESS,
            };
        }
        case GET_CUSTOMER_SCORETRANSACTIONS_FAIL: {
            return {
                ...state,
                areTransactionsLoading: false,
                errorCode: RequestStatus.getErrorStatusCode(action.error),
                performedAction: AllReduxActions.GET_CUSTOMER_SCORETRANSACTIONS_FAIL,
            };
        }
        case CLEAR_CUSTOMER_SCORE_TRANSACTIONS: {
            return {
                ...state,
                scoreTransactions: [],
                performedAction: AllReduxActions.CLEAR_CUSTOMER_SCORE_TRANSACTIONS,
            };
        }
        case CLEAR_ALL_CUSTOMER_SCORE: {
            return INITIAL_STATE;
        }
        case CLEAR_LOADING_STATE: {
            return {
                ...state,
                isLoading: false,
                areTransactionsLoading: false,
            };
        }
        default:
            return state;
    }
};

// Action Creators
export const actionCreators = {
    ...baseActionCreators,
    requestGetCustomerScore: (): AppThunkAction<GetCustomerScoreAction> => async (dispatch, getState) => {
        const { isLoading } = getState().customerScoreReducer as CustomerScoreState;

        if (!isLoading) {
            dispatch(<GetCustomerScoreAction>{
                type: GET_CUSTOMER_SCORE,
                payload: {
                    request: {
                        method: "GET",
                        url: "/GetCustomerScore",
                    },
                },
            });
        }
    },
    requestGetCustomerScoreTransactions:
        (): AppThunkAction<GetCustomerScoreTransactionsAction> => async (dispatch, getState) => {
            const { areTransactionsLoading } = getState().customerScoreReducer as CustomerScoreState;

            if (!areTransactionsLoading) {
                dispatch(<GetCustomerScoreTransactionsAction>{
                    type: GET_CUSTOMER_SCORETRANSACTIONS,
                    payload: {
                        request: {
                            method: "GET",
                            url: `/GetCustomerScoreTransactions`,
                        },
                    },
                });
            }
        },
    clearAllTransactions: (): ClearTransactionsStateAction =>
        <ClearTransactionsStateAction>{
            type: CLEAR_CUSTOMER_SCORE_TRANSACTIONS,
        },

    clearAll: (): ClearAllAction =>
        <ClearAllAction>{
            type: CLEAR_ALL_CUSTOMER_SCORE,
        },
};
export type CustomerScoreActions = typeof actionCreators;
