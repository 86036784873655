export const CLEAR_LOADING_STATE = "CLEAR_LOADING_STATE";

interface ClearLoadingStateAction {
    type: typeof CLEAR_LOADING_STATE;
}

export type BaseKnownAction = ClearLoadingStateAction;

export const baseActionCreators = {
    clearLoadingState: (): ClearLoadingStateAction =>
        <ClearLoadingStateAction>{
            type: CLEAR_LOADING_STATE,
        },
};
