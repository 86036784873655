import { useAppDispatch, useAppSelector } from "utils/hooks";
import { actionCreators } from "../../store";
import { AppState } from "../../store/configureStore";
import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";

const TokenRefresh: FunctionComponent = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { isAuthenticated, isRefreshing } = useAppSelector((state: AppState) => state.authReducer);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }

        if (
            !isRefreshing &&
            (!dispatch(actionCreators.auth.isTokenAlive()) || dispatch(actionCreators.auth.isTokenExpiring()))
        ) {
            dispatch(actionCreators.auth.refreshToken());
        }
    }, [location?.pathname]);

    return null;
};

export default TokenRefresh;
