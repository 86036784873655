import { Colors } from "styles";
import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

const TebonusCardIcon: React.FC<SvgIconProps> = props => {
    return (
        <SvgIcon {...props} viewBox="0 0 88 88">
            <path
                id="Fill_1"
                data-name="Fill 1"
                d="M37.333,1.212C53.651-1.665,63.26.84,70.615,5.991S83.609,19.311,86.486,35.63l.3,1.7c2.877,16.318.374,25.928-4.779,33.284S68.688,83.6,52.37,86.486l-1.706.3c-16.319,2.877-25.926.374-33.28-4.783S4.394,68.689,1.513,52.368l-.3-1.7C-1.666,34.344.841,24.739,5.992,17.389s13.317-13,29.639-15.875Z"
                transform="translate(0)"
                fill={Colors.orange}
            />
            <path
                id="Fill_4"
                data-name="Fill 4"
                d="M62.572,37.63A3.481,3.481,0,0,1,59.1,41.1H3.472A3.48,3.48,0,0,1,0,37.63V3.472A3.482,3.482,0,0,1,3.472,0H59.1a3.483,3.483,0,0,1,3.472,3.472Z"
                transform="translate(13.142 23.764)"
                fill={Colors.white}
            />
            <path
                id="Stroke_6"
                data-name="Stroke 6"
                d="M0,.5H62.88"
                transform="translate(12.875 36.094)"
                fill="none"
                stroke={Colors.orange}
                strokeWidth="1.5"
            />
            <path
                id="Stroke_8"
                data-name="Stroke 8"
                d="M0,.9,22.057.375"
                transform="translate(45.927 53.704)"
                fill="none"
                stroke={Colors.orange}
                strokeWidth="1.5"
            />
        </SvgIcon>
    );
};

export default TebonusCardIcon;
