import {
    TebonusArrowLeftRound,
    TebonusArrowLeftSharp,
    TebonusArrowRightRound,
    TebonusArrowRightSharp,
    TebonusArrowSize,
    TebonusArrowType,
} from "components/_graphics";
import Slider, { SwipeDirection, Settings } from "react-slick";
import React, { ReactNode, useEffect, useState } from "react";

interface CustomCarouselArrows {
    arrowType?: TebonusArrowType;
    arrowSize?: TebonusArrowSize;
    className?: string;
    prevSliderRequested?: () => void; // !IMPORTANT: muss be implemented in the parent component when customArrows is set to true
    nextSliderRequested?: () => void; // !IMPORTANT: muss be implemented in the parent component when customArrows is set to true
}
export interface DataCarouselProps {
    children: ReactNode;
    arrows?: boolean;
    infinity?: boolean;
    animationSpeed?: number;
    customArrows?: boolean;
    customArrowsProps?: CustomCarouselArrows;
    arrowsOutside?: boolean;
    className?: string;
    slidesToScroll?: number;
    slidesToShowAllBreakpoints?: number; // all breakpoints
    slidesToShowXXL?: number; // max 3000 - min 1800
    slidesToShowXL?: number; // max 1800 - min 1440
    slidesToShowLG?: number; // max 1440 - min 1024
    slidesToShowMD?: number; // max 1024 - min 768
    slidesToShowSM?: number; // max 768 - min 576
    slidesToShowXS?: number; // max 576 - min 0
    elementNumber: number;
    tabSupportIdentifier?: string; // tab-support by providing a string concatinated before the slide-index
    onSwipe?: (swipeDirection: SwipeDirection) => void;
}

const defaultCustomCarouselArrows: CustomCarouselArrows = {
    className: "icon-accent-wrapper icon-accent-orange",
    arrowType: TebonusArrowType.SHARP,
    arrowSize: TebonusArrowSize.SMALL,
};

const defaultDataCarouselProps: DataCarouselProps = {
    children: null,
    arrows: false,
    infinity: true,
    animationSpeed: 500,
    customArrows: true,
    customArrowsProps: defaultCustomCarouselArrows,
    arrowsOutside: true,
    className: "text-center",
    slidesToScroll: 1,
    slidesToShowXXL: 3,
    slidesToShowXL: 3,
    slidesToShowLG: 2,
    slidesToShowMD: 2,
    slidesToShowSM: 3,
    slidesToShowXS: 3,
    elementNumber: 0,
};

export const DataCarousel = React.forwardRef<Slider, DataCarouselProps>((props, ref) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        if (props.tabSupportIdentifier && props.tabSupportIdentifier != "") {
            for (let index = 0; index < React.Children.count(props.children); index++) {
                const n = document.getElementById(`${props.tabSupportIdentifier}${index}`);

                if (!n) {
                    continue;
                }

                if (currentIndex === index) {
                    n.tabIndex = 0;
                } else {
                    n.tabIndex = -1;
                }
            }
        }
    }, [currentIndex]);

    const settings: Settings = {
        children: null,
        arrows: props.arrows,
        //if infinity is set to true and the item count is lesser than slidesToShow, the Carousel will render duplicate items, this check fixes it.
        //temporary fix until the author of the library implement his own fix.
        infinite:
            props.infinity && props.children ? React.Children.count(props.children) > props.slidesToShowAllBreakpoints : false,
        speed: props.animationSpeed,
        slidesToScroll: props.slidesToScroll,
        initialSlide: 0,
        slidesToShow: props.slidesToShowAllBreakpoints, //default -> all breakpoints.
        onSwipe: props.onSwipe,
        responsive: [
            {
                breakpoint: 3000, //XXL, max 3000
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowXXL,
                },
            },
            {
                breakpoint: 1800, //XL, max 1800
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowXL,
                },
            },
            {
                breakpoint: 1440, //LG, max 1440
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowLG,
                },
            },
            {
                breakpoint: 1024, //MD, max 1024
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowMD,
                },
            },
            {
                breakpoint: 768, // SM, max 768
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowSM,
                },
            },
            {
                breakpoint: 576, // XS, max 576
                settings: {
                    slidesToShow: props.slidesToShowAllBreakpoints ?? props.slidesToShowXS,
                },
            },
        ],
        afterChange: index => setCurrentIndex(index),
    };

    const carouselArrowBack = (): React.JSX.Element => {
        if (props.elementNumber <= 1) {
            return null;
        }

        if (props.customArrows) {
            return (
                <button
                    className="icon-accent-wrapper icon-accent-orange"
                    style={{
                        ...(props.arrowsOutside ? {} : { position: "absolute", left: 20, zIndex: 1 }),
                    }}
                    onClick={() => props.customArrowsProps.prevSliderRequested()}
                    aria-label="Zurück"
                >
                    {/* round custom arrow, also the default custom arrow type */}
                    {(props.customArrowsProps.arrowType === TebonusArrowType.ROUND || !props.customArrowsProps.arrowType) && (
                        <>
                            <div className="d-none d-lg-block">
                                <TebonusArrowLeftRound size={props.customArrowsProps.arrowSize} />
                            </div>
                            <div className="d-lg-none">
                                <TebonusArrowLeftRound size={TebonusArrowSize.SMALL} />
                            </div>
                        </>
                    )}

                    {/* sharp custom arrow */}
                    {props.customArrowsProps.arrowType === TebonusArrowType.SHARP && (
                        <TebonusArrowLeftSharp size={props.customArrowsProps.arrowSize} />
                    )}
                </button>
            );
        }
    };

    const carouselArrowForward = (): React.JSX.Element => {
        if (props.elementNumber <= 1) {
            return null;
        }

        if (props.customArrows) {
            return (
                <button
                    className="icon-accent-wrapper icon-accent-orange"
                    style={{
                        ...(props.arrowsOutside ? {} : { position: "absolute", right: 20, zIndex: 1 }),
                    }}
                    onClick={() => props.customArrowsProps.nextSliderRequested()}
                    aria-label="Weiter"
                >
                    {/* round custom arrow, also the default custom arrow type */}
                    {(props.customArrowsProps.arrowType === TebonusArrowType.ROUND || !props.customArrowsProps.arrowType) && (
                        <>
                            <div className="d-none d-lg-block">
                                <TebonusArrowRightRound size={props.customArrowsProps.arrowSize} />
                            </div>
                            <div className="d-lg-none">
                                <TebonusArrowRightRound size={TebonusArrowSize.SMALL} />
                            </div>
                        </>
                    )}

                    {/* sharp custom arrow */}
                    {props.customArrowsProps.arrowType === TebonusArrowType.SHARP && (
                        <TebonusArrowRightSharp size={props.customArrowsProps.arrowSize} />
                    )}
                </button>
            );
        }
    };

    return (
        <>
            {props.arrowsOutside ? (
                <div className="d-flex row align-items-center justify-content-center">
                    <div className="d-xl-none col-2 col-lg-1 p-0 text-center custom-data-carousel-arrow-left">
                        {carouselArrowBack()}
                    </div>
                    <div className="d-none d-xl-block col-xl-1 p-0 text-right custom-data-carousel-arrow-left">
                        {carouselArrowBack()}
                    </div>
                    <div className="col-8 col-lg-10 custom-data-carousel-slider-container">
                        <Slider ref={ref} {...settings} className={props.className}>
                            {props.children}
                        </Slider>
                    </div>

                    <div className="d-xl-none col-2 col-lg-1 p-0 text-center custom-data-carousel-arrow-right">
                        {carouselArrowForward()}
                    </div>
                    <div className="d-none d-xl-block col-xl-1 p-0 text-left custom-data-carousel-arrow-right">
                        {carouselArrowForward()}
                    </div>
                </div>
            ) : (
                // Navigation arrows are in the inside of the slider
                <div className="d-flex justify-content-center align-items-center text-center h-100">
                    {carouselArrowBack()}
                    <Slider ref={ref} {...settings} className={props.className}>
                        {props.children}
                    </Slider>
                    {carouselArrowForward()}
                </div>
            )}
        </>
    );
});

DataCarousel.defaultProps = defaultDataCarouselProps;
DataCarousel.displayName = "DataCarousel"; // necessary for the TranspileName during compiling and debugging
