// Overview of the Points in the dashboard
import LoadingIndicator from "components/LoadingIndicator";
import { isLoadingCustomerScore } from "utils/LoadingCustomerScore";
import { useAppDispatch } from "utils/hooks";
import { actionCreators } from "store";
import { TebonusLogoBackground } from "../../components/_graphics";
import * as navLinks from "../../navigation";
import { AppState } from "../../store/configureStore";
import React, { FunctionComponent, useEffect } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Greeting: FunctionComponent = () => {
    const customer = useSelector((state: AppState) => state.customerReducer);
    const customerScore = useSelector((state: AppState) => state.customerScoreReducer);
    const dispatch = useAppDispatch();

    const scoreOverviewLink = navLinks.linksSubMenu_MyTebonus.find(p => p.title === "Punkteübersicht").htmlLink;

    useEffect(() => {
        dispatch(actionCreators.customerScore.requestGetCustomerScore());
    }, []);
    /**
     * Determine the personalized greeting text depending on the current time
     * @returns a string with either "Guten Morgen", "Guten Abend" or "Hallo" with the customer name.
     */
    function getGreetingText(): string {
        let greeting = "";
        const givenName = customer.customer ? customer.customer.firstName : "";
        const familyName = customer.customer ? customer.customer.lastName : "";

        // Between 04:01 - 10:00 -> Guten Morgen,
        const format = "hh:mm:ss";
        if (dayjs().isBetween(dayjs("04:01", format), dayjs("10:00", format))) {
            greeting = "Guten Morgen";
        }
        // Between 10:01 - 18:00 -> Hallo,
        else if (dayjs().isBetween(dayjs("10:01", format), dayjs("18:00", format))) {
            greeting = "Hallo";
        }
        // Between 18:01 - 00:00 -> Guten Abend,
        else if (dayjs().isBetween(dayjs("18:01", format), dayjs("24:00", format))) {
            greeting = "Guten Abend";
        }
        // Between 00:01 - 04:00 -> Hallo,
        else {
            greeting = "Hallo";
        }
        return `${greeting}, ${givenName} ${familyName}!`;
    }

    /**
     * @returns the customer number.
     */
    function getCustomerNumber(): string {
        return customer.customer ? customer.customer.customerNumber : "";
    }

    /**
     * Gets the current customer score. If the score has more than 5 digits it will be truncated
     * @returns the customer score of the user.
     */
    function getCustomerScore(): string {
        const score = String(customerScore.currentScore);
        return score.length > 5 ? `${score.substring(0, 5)}...` : score;
    }

    return (
        <div className="d-flex justify-content-center justify-content-lg-start align-items-center h-100">
            <div className="greetings-bg-logo d-lg-none">
                <TebonusLogoBackground showLogo={false} showTebonus={false} height="100%" width="100%" />
            </div>

            <div className="text-center text-lg-left">
                <div className="greetingTitle mb-1 mb-lg-2 truncate-2"> {getGreetingText()}</div>
                <div className="greetingSubTitle d-flex justify-content-start">
                    <div className="mr-2">Punktestand:</div>
                    {isLoadingCustomerScore(customerScore.currentScore, customerScore.isLoading) ? (
                        <div className="ml-2" style={{ fontSize: "1rem" }}>
                            <LoadingIndicator isPositionStatic={true} />
                        </div>
                    ) : (
                        <Link className="pointsLink" to={scoreOverviewLink} aria-label="zur Punkteübersicht">
                            <strong>{`${getCustomerScore()} Punkte`}</strong>
                        </Link>
                    )}
                </div>
                <div className="greetingSubTitle">
                    Kundennummer: <span style={{ fontFamily: "unitText-Bold" }}>{getCustomerNumber()}</span>
                </div>
            </div>
        </div>
    );
};

export default Greeting;
