import React, { FunctionComponent } from "react";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";

// Page title with the go back button on the left side

interface PageTitleProps {
    title: string;
    icon?: React.JSX.Element;
    prevPageLink: string;
}

const PageTitle: FunctionComponent<PageTitleProps> = props => {
    return (
        <>
            {/* Desktop title of the page. It is shown if the screen is large */}
            <div className="row mt-2 d-none d-md-flex mb-4">
                {/* Back button */}
                <div className="col-1 d-flex justify-content-center">
                    <div className="font-orange d-flex align-items-center">
                        <Link className="font-orange" to={props.prevPageLink} aria-label="Zurück zur letzten Seite">
                            <ArrowBack style={{ fontSize: "1.875 rem" }} />
                        </Link>
                    </div>
                </div>

                {/* Title */}
                <div className="col-10 d-flex justify-content-center align-items-center">
                    <div className="font-orange mr-2">{props.icon}</div>
                    <div className="text-justify text-center font-heading">{props.title}</div>
                </div>

                <div className="col-1">
                    {/* placeholder is needed otherwise the content will be on the right and left side. No better solution was found*/}
                </div>
            </div>

            {/* Mobile version of the title. It will be hidden if the screen is larger than md*/}
            <div className="row mx-n1 bg-yellow py-1 d-md-none mb-5">
                {/* Back button */}
                <div className="col-1 d-flex justify-content-start">
                    <div className="d-flex align-items-center">
                        <Link
                            className="font-white d-flex align-items-center"
                            to={props.prevPageLink}
                            aria-label="Zurück zur letzten Seite"
                        >
                            <ArrowBack style={{ fontSize: 20 }} />
                        </Link>
                    </div>
                </div>

                {/* Title */}
                <div className="col-10 d-flex justify-content-center align-items-center text-justify text-center font-heading">
                    {props.title}
                </div>

                <div className="col-1">
                    {/* placeholder is needed otherwise the content will be on the right and left side. No better solution was found*/}
                </div>
            </div>
        </>
    );
};

export default PageTitle;
