import logo from "assets/images/tebonus_Karte_rgb_Website.png";
import { useAppDispatch } from "utils/hooks";
import { actionCreators } from "store";
import React, { FunctionComponent, useState } from "react";

const LandingPage: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const [imgLoaded, setImgLoaded] = useState(false);

    /**
     * redirect user using the implemented openid-flow
     * @param isRegistration true if it is a registration otherwise false for a default login
     */
    const authorize = (isRegistration: boolean): void => {
        dispatch(actionCreators.auth.redirect(isRegistration));
    };

    return (
        <div className="row justify-content-center mx-0 mt-0 mt-lg-5">
            <div style={{ maxWidth: "800px" }}>
                <div className="bg-white tile p-5 mx-0">
                    <div>
                        <h3 className="text-orange font-weight-bold">Punkten und sparen Sie mit tebonus!</h3>
                        <h5 className="font-weight-bold">Das tegut... Bonusprogramm</h5>
                    </div>

                    <div className="row justify-content-center pt-3">
                        <div className="col-12 col-xs-8 col-sm-8 col-lg-6">
                            <div className="row justify-content-center px-5 px-lg-0">
                                {imgLoaded ? null : (
                                    <div
                                        style={{
                                            width: "352px",
                                        }}
                                    />
                                )}
                                <img
                                    src={logo}
                                    width="100%"
                                    style={imgLoaded ? { transform: "rotate(-3.5deg)" } : { display: "none" }}
                                    onLoad={() => {
                                        setImgLoaded(true);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="mb-5">
                                <h5 className="my-3 text-center font-dimGray">Hier geht&apos;s zum Login</h5>
                                <div className="row justify-content-center">
                                    <button
                                        className="btn btn-orange btn-navigationBar"
                                        onClick={() => {
                                            authorize(false);
                                        }}
                                    >
                                        Jetzt einloggen!
                                    </button>
                                </div>
                            </div>
                            <div>
                                <h5 className="mt-3 mb-0 text-center font-dimGray">Noch keine tebonus Karte</h5>
                                <h5 className="mb-3 text-center font-dimGray"> oder Web-Zugang?</h5>
                                <div className="row justify-content-center">
                                    <button
                                        className="btn btn-outline-orange btn-navigationBar"
                                        onClick={() => {
                                            authorize(true);
                                        }}
                                    >
                                        Jetzt registrieren!
                                    </button>
                                </div>
                            </div>
                        </div>
                        <p className="mt-5 text-justify px-2">
                            Mit tebonus erhalten Sie noch mehr Vorteile beim Einkaufen. Denn mit Einsatz der tebonus Karte oder
                            der tebonus App sammeln Sie ganz einfach Punkte und können sich zusätzlich über viele
                            Punkte-Aktionen freuen.{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.tegut.com/tebonus.html"
                                className="font-orange"
                            >
                                Jetzt mehr erfahren.
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
