import PageBase from "components/Shared/PageBase";
import FlyerContentBanner from "../../assets/images/Angebote_Content.png";
import React, { FunctionComponent, ReactElement } from "react";

const Flyer: FunctionComponent = (): ReactElement => {
    function renderPageContent(): React.JSX.Element {
        return (
            <div className="container-md content-in-tile">
                <div className="d-flex flex-column flex-md-row px-5">
                    <div className="col-12 col-md-6 pr-md-4">
                        <img src={FlyerContentBanner} width="100%" style={{ borderRadius: 10 }} />
                    </div>
                    <div className="col-12 col-md-6 mt-4 mt-md-0 pl-md-4">
                        <h2 className="h1">Jede Woche aufs Neue sparen</h2>
                        <p className="h5">In unserem Prospekt finden Sie jederzeit die aktuellen Angebote der Woche.</p>
                        <p className="h5">
                            So geht&apos;s: Prospekt Ihres Marktes unter Angabe Ihrer Postleitzahl abrufen und Angebote
                            entdecken.
                        </p>
                        <a
                            className="h5 d-flex font-orange mt-4"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.tegut.com/angebote-produkte/angebote.html?utm_source=tbapp&utm_medium=banner&utm_campaign=&utm_term=Flugblatt&utm_content=#c66663"
                        >
                            Jetzt Markt auswählen
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    return <PageBase title="Prospekt">{renderPageContent()}</PageBase>;
};

export default Flyer;
