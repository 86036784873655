import { Article, MultiplierBaseProps } from "types";
import { DuplicateErrorMsg, getCollisionsWarningMsg, multipliersHasDuplicates } from "../../pages/ArticleMultipliers/utils";
import React, { FunctionComponent, ReactElement } from "react";

interface SaveButtonProps extends MultiplierBaseProps {
    areCollisionsFound: boolean;
    numberOfMultipliers: number;
    changedMultipliers: Article[];
    collidedMultiplierTans: string[];
    onSaveButtonClicked: () => void;
}

const SaveButton: FunctionComponent<SaveButtonProps> = (props): ReactElement => {
    function getButtonText(): string {
        if (props.isLoading) {
            return "";
        }

        if (props.isInitial || props.isEditable) {
            return "Auswahl speichern";
        }

        return "Produkte gespeichert";
    }

    /**
     * Determines whether the select button below the product tiles should be clickable, or not.
     * It should not be clickable when:
     * 1. the page is currently loading or
     * 2. multipliers are locked
     * 3. when the user are able to select multipliers again (initial or new month) but have not selected at
     * least one product.
     * 4. list has duplicates
     * @returns true, if the button should be disabled, false otherwise
     */
    function shouldSelectButtonBeDisabled(): boolean {
        return (
            props.isLoading ||
            !props.isEditable ||
            (props.isEditable && props.numberOfMultipliers === 0) ||
            multipliersHasDuplicates(props.changedMultipliers)
        );
    }

    return (
        <div className="d-flex flex-column justify-content-center mt-3 align-item-center">
            {multipliersHasDuplicates(props.changedMultipliers) && (
                <p className="font-unitText-Regular font-red text-center">{DuplicateErrorMsg}</p>
            )}
            {props.areCollisionsFound && (
                <p className="font-unitText-Regular text-warning text-center">
                    {getCollisionsWarningMsg(
                        props.changedMultipliers.filter(m => m),
                        props.collidedMultiplierTans
                    )}
                </p>
            )}
            <button
                disabled={shouldSelectButtonBeDisabled()}
                className="btn btn-orange btn-select"
                onClick={props.onSaveButtonClicked}
                style={{ alignSelf: "center" }}
            >
                {getButtonText()}
            </button>
        </div>
    );
};

export default SaveButton;
