import DropDownMenu from "components/Shared/DropDownMenu";
import { TebonusFilterIcon } from "components/_graphics";
import { actionCreators } from "store";
import { ArticleSearchState } from "store/ArticleSearch";
import { AppState } from "store/configureStore";
import { Colors } from "styles";
import { SearchProps } from "types";
import { CommonButtonTitles, logAnalyticsButton } from "utils/FirebaseAnalytics";
import { useAppDispatch } from "utils/hooks";
import { useSelector } from "react-redux";
import React from "react";

interface SearchFilterDropDownsProps {
    selectedBrands: SearchProps[];
    setSelectedBrands: (SearchProps) => void;
    selectedCategories: SearchProps[];
    setSelectedCategories: (SearchProps) => void;
    allCategories: SearchProps[];
    searchTerm: string;
}

const SearchFilterDropDowns: React.FunctionComponent<SearchFilterDropDownsProps> = props => {
    const articleSearchState: ArticleSearchState = useSelector((state: AppState) => state.articleSearchReducer);
    const dispatch = useAppDispatch();

    const getDropDownMenuOptions = (): SearchProps[] => {
        if (articleSearchState.brands?.length > 0) {
            return articleSearchState.brands.map(item => {
                return { id: item, name: item };
            });
        }

        return [];
    };

    const onBrandsFilterOpened = (): void => {
        logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.openBrandsFilter });
        dispatch(actionCreators.articleSearch.findArticleBrands(props.searchTerm, getCurrentCategoryId()));
    };

    const onBrandsScrollEnd = (): void => {
        dispatch(actionCreators.articleSearch.loadNextBrands());
    };

    const onSearchTermChanged = (value: string): void => {
        dispatch(actionCreators.articleSearch.findArticleBrands(props.searchTerm, getCurrentCategoryId(), value));
    };

    const getCurrentCategoryId = (): string => {
        if (props.selectedCategories.length === 0) {
            return "";
        }

        return props.selectedCategories[props.selectedCategories.length - 1]?.id;
    };

    return (
        <div className="d-flex w-100 align-items-center justify-content-center mt-3 mb-2">
            <div className="mx-2 d-flex align-items-center justify-content-center">
                <TebonusFilterIcon htmlColor={Colors.orange} />
            </div>
            <div className="mx-2 d-flex">
                {/* Dropdown menu for brands */}
                <DropDownMenu
                    title="Marke"
                    options={getDropDownMenuOptions()}
                    selectedItems={props.selectedBrands}
                    setSelectedItems={props.setSelectedBrands}
                    isLoading={articleSearchState.isLoading}
                    onOpen={onBrandsFilterOpened}
                    onClose={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.resetBrandsFilter })}
                    onScrollEnd={onBrandsScrollEnd}
                    hasSearchBar={true}
                    searchPlaceholder="Marke suchen"
                    onSearchTermChanged={onSearchTermChanged}
                    width={175}
                    height={40}
                />
            </div>

            {/* Dropdown menu for categories */}
            <div className="mx-2 d-flex">
                <DropDownMenu
                    title="Warengruppe"
                    options={props.allCategories}
                    selectedItems={props.selectedCategories}
                    setSelectedItems={props.setSelectedCategories}
                    onOpen={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.openCategoriesFilter })}
                    onClose={() => logAnalyticsButton({ button_title: CommonButtonTitles.multiplier.resetCategoriesFilter })}
                    width={175}
                    height={40}
                />
            </div>
        </div>
    );
};

export default SearchFilterDropDowns;
