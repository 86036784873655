import { RegistrationConfirmationModals } from "components/RegistrationConfirmation/Modals";
import PageBase from "components/Shared/PageBase";
import { actionCreators } from "store";
import { AppState } from "store/configureStore";
import { useAppDispatch, useAppSelector } from "utils/hooks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RegistrationConfirmation: React.FC = () => {
    const STORAGE_KEY = "isDialogDismissed";
    const STORAGE_VALUE = sessionStorage.getItem(STORAGE_KEY);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const customerState = useAppSelector((state: AppState) => state.customerReducer);

    const [isRenderStatusPopup, setIsRenderStatusPopup] = useState<boolean>(JSON.parse(STORAGE_VALUE));

    // redirect to Homepage
    if (customerState.isEmailConfirmed) {
        navigate("/");
    }

    const handleOnClickSendConfirmationMail = (): void => {
        if (!customerState.isSendingConfirmation) {
            dispatch(actionCreators.customers.sendConfirmationMail());

            // In session storage, set the isConfirmationSentSuccessfully to true if no key has been created yet
            // or the value is false (false if the user has already sent a confirmation mail and the dialog has been dismissed).
            if (JSON.parse(STORAGE_VALUE) !== true) {
                sessionStorage.setItem(STORAGE_KEY, JSON.stringify(true));
                setIsRenderStatusPopup(JSON.parse(sessionStorage.getItem(STORAGE_KEY)));
            }
        }
    };

    return (
        <PageBase
            title="Registrierungsbestätigung"
            modals={
                <RegistrationConfirmationModals
                    storageKey={STORAGE_KEY}
                    customerState={customerState}
                    isEmailSentStateVisible={isRenderStatusPopup}
                    isSendingConfirmation={customerState.isSendingConfirmation}
                    setIsEmailSentStateVisible={setIsRenderStatusPopup}
                />
            }
        >
            <div className="container-md content-in-tile">
                <div className="d-flex flex-column align-items-center my-4">
                    <div className="text-justify text-center font-subheading">Jetzt E-Mail-Adresse bestätigen</div>
                </div>

                <div className="d-flex flex-column align-items-center my-4">
                    <div className=" text-center font-description w-50">
                        Die E-Mail-Adresse zu Ihrem tegut… Konto ist noch nicht bestätigt. Mit Klick auf den Button können Sie
                        Ihre Bestätigungsmail erneut anfordern, um Ihre E-Mail-Adresse zu verifizieren.
                    </div>
                </div>

                {/* button */}
                <div className="d-flex justify-content-center my-5">
                    <button className="btn btn-orange btn-select my-2" onClick={handleOnClickSendConfirmationMail}>
                        Bestätigungsmail anfordern
                    </button>
                </div>
            </div>
        </PageBase>
    );
};
export default RegistrationConfirmation;
