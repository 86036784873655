import { useAppDispatch, useAppSelector } from "utils/hooks";
import MultiplierDashboardHeader from "components/ArticleMultipliers/Dashboard/MultiplierDashboardHeader";
import MultiplierDashboardFooter from "components/ArticleMultipliers/Dashboard/MultiplierDashboardFooter";
import { AppState } from "../../store/configureStore";
import { actionCreators } from "../../store";
import { ArticleMultiplierState } from "../../store/ArticleMultipliers";
import ProductTiles from "../../components/ArticleMultipliers/Dashboard/DashboardProductTiles";
import React, { FunctionComponent, ReactElement, useEffect } from "react";

const FavProducts: FunctionComponent = (): ReactElement => {
    const dispatch = useAppDispatch();

    const articleMultipliers: ArticleMultiplierState = useAppSelector((state: AppState) => state.multiplierReducer);

    useEffect(() => {
        dispatch(actionCreators.articleMultipliers.requestGetArticleMultipliers());
    }, []);

    useEffect(() => {
        if (articleMultipliers.shouldFetchDetails) {
            dispatch(
                actionCreators.articleMultipliers.getMultiplierDetails(
                    articleMultipliers.articles.map(multiplier => multiplier?.tan)
                )
            );
        }
    }, [articleMultipliers.shouldFetchDetails]);

    function isInitial(): boolean {
        return !articleMultipliers.nextChangeDate;
    }

    function areMultipliersEditable(): boolean {
        return !articleMultipliers.areMultiplierLocked;
    }

    return (
        <div className="d-flex flex-column h-100">
            <MultiplierDashboardHeader isInitial={isInitial()} isEditable={areMultipliersEditable()} />
            <ProductTiles articleMultipliers={articleMultipliers} />
            <MultiplierDashboardFooter
                isLoading={articleMultipliers.isLoading}
                isInitial={isInitial()}
                isEditable={areMultipliersEditable()}
                nextChangeDate={articleMultipliers.nextChangeDate}
            />
        </div>
    );
};

export default FavProducts;
