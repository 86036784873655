import { TebonusCardIcon } from "components/_graphics";
import { TegutBadge } from "components/_tegut/TegutBadge";
import { Colors } from "styles";
import { toggleModalOpenClass } from "utils/BodyModalOpenHandler";
import { useFocusTrap } from "utils/useFocusTrap";
import StepNavigation from "components/StepNavigation";
import { ANDROID, getAppStoreLink, IOS } from "utils/GetAppStoreLink";
import { RouteMaps, logAnalyticsScreenView } from "utils/FirebaseAnalytics";
import CardImage from "../../../assets/images/Bild_tebonus_Karte_01.jpg";
import StoreImage from "../../../assets/images/Bild_digitale_Karte.jpg";
import PlayStoreImage from "../../../assets/images/google-play-badge.png";
import AppStoreImage from "../../../assets/images/Appstore_badge.png";
import React, { FunctionComponent, useEffect, useState } from "react";
import CloseSharp from "@material-ui/icons/CloseSharp";

interface OnboardingModalProps {
    submitButtonAction: Function;
}

type OnboardingViewmode = "mobile" | "tablet" | "desktop";

const SLIDE_POSITION = "Ansicht Onboarding";

/**
 * The modal is placed above the content and is blocking the website
 */
const OnboardingModal: FunctionComponent<OnboardingModalProps> = props => {
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const dialogRef = useFocusTrap();

    useEffect(() => {
        toggleModalOpenClass(true);

        function handleResize(): void {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            toggleModalOpenClass(false);
        };
    }, []);

    useEffect(() => {
        logAnalyticsScreenView(
            {
                [SLIDE_POSITION]: slideIndex + 1,
            },
            RouteMaps.Onboarding
        );
    }, [slideIndex]);

    const getViewMode = (): OnboardingViewmode => {
        if (windowWidth <= 365) {
            return "mobile";
        }

        if (windowWidth >= 768) {
            return "desktop";
        }

        return "tablet";
    };

    const breakOrSpace = (breakLine: boolean): React.ReactNode => {
        return breakLine ? <br /> : " ";
    };

    const slides: {
        header: string;
        body: React.JSX.Element;
        img: string;
        mobileBackgroundSize?: number;
        showStoreBadges?: boolean;
        accentColor?: string;
    }[] = [
        {
            header: "Ihre tebonus Karte ist unterwegs!",
            body: (
                <div>
                    <p>
                        Vielen Dank für Ihre Registrierung.
                        <br />
                        Ihre tebonus Karte ist auf dem Weg zu Ihnen.
                        <br />
                        Sie erhalten diese innerhalb{breakOrSpace(getViewMode() === "desktop")}der nächsten
                        {breakOrSpace(getViewMode() != "desktop")}
                        <strong>7 Werktage</strong> an die angegebene Adresse.
                    </p>
                </div>
            ),
            img: CardImage,
            mobileBackgroundSize: 1.0,
        },
        {
            header: "Kennen Sie die tebonus App?",
            body: (
                <div>
                    <p>
                        Dort können Sie sich mit Ihren eben{breakOrSpace(getViewMode() != "desktop")}vergegebenen Login
                        {breakOrSpace(getViewMode() === "desktop")}
                        Daten einloggen{breakOrSpace(getViewMode() != "desktop")}und mit Ihrer digitalen Karte Punkte
                        <br />
                        sammeln und einlösen.
                    </p>
                </div>
            ),
            img: StoreImage,
            mobileBackgroundSize: 1.6,
            showStoreBadges: true,
            accentColor: Colors.white,
        },
    ];

    const renderModalButton = (): React.JSX.Element => {
        // weiter-Button
        if (slideIndex < slides.length - 1) {
            return (
                <button
                    onClick={() => {
                        setSlideIndex(slideIndex + 1);
                    }}
                    className="btn btn-outline-white btn-select"
                >
                    Weiter
                </button>
            );
        }

        // submit-Button
        return (
            <button
                onClick={() => {
                    props.submitButtonAction();
                }}
                className="btn btn-outline-white btn-select"
            >
                Jetzt loslegen!
            </button>
        );
    };

    const jumpToSlide = (index): void => {
        setSlideIndex(index);
    };

    return (
        <div className="center-on-container">
            {/* div which creates a dark background over the whole screen which is not clickable */}
            <div className="modal-bg-disabled"></div>

            <div tabIndex={-1} role="dialog" className="modal-position" ref={dialogRef}>
                <div className="onboarding-modal">
                    <div className="onboarding-modal-scroll-container table-scroll-bar">
                        <div className="onboarding-modal-container d-flex flex-column align-items-center overflow-hidden">
                            <div
                                className="onboarding-background-image h-100 w-100"
                                style={{
                                    backgroundImage: `url(${slides[slideIndex].img})`,
                                    backgroundSize: `${
                                        (slides[slideIndex].mobileBackgroundSize && getViewMode() != "desktop"
                                            ? slides[slideIndex].mobileBackgroundSize
                                            : 1) * 100
                                    }%`,
                                }}
                            ></div>
                            <div style={{ height: getViewMode() === "mobile" ? 500 : 300, width: "100%" }}>
                                <button
                                    className="btn p-0"
                                    style={{ position: "absolute", top: 10, right: 10 }}
                                    onClick={() => {
                                        props.submitButtonAction();
                                    }}
                                    aria-label="Schließen"
                                >
                                    <CloseSharp
                                        style={{ fontSize: 30, color: slides[slideIndex].accentColor ?? Colors.black }}
                                    />
                                </button>
                                {slides[slideIndex].showStoreBadges && (
                                    <div
                                        style={{
                                            height: "35%",
                                            position: "absolute",
                                            width: getViewMode() === "mobile" || getViewMode() === "tablet" ? "30%" : "20%",
                                            top: 40,
                                            right: 10,
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: getViewMode() != "mobile" ? "end" : "center",
                                            marginRight: 15,
                                        }}
                                    >
                                        <a
                                            href={getAppStoreLink(ANDROID)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Open Google-Play page"
                                        >
                                            <img src={PlayStoreImage} width="100%" />
                                        </a>
                                        <a
                                            href={getAppStoreLink(IOS)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            aria-label="Open App-Store page"
                                        >
                                            <img src={AppStoreImage} width="100%" />
                                        </a>
                                    </div>
                                )}
                            </div>
                            {/* modal background blob */}
                            <TegutBadge bgColor={Colors.yellow} className="onboarding-background-badge" />
                            {/* modal icon */}
                            <div className="onboarding-modal-icon">
                                <TebonusCardIcon style={{ fontSize: "4rem" }} />
                            </div>
                            {/* modal information */}
                            <div
                                className={`onboarding-modal-info-container font-white d-flex flex-column ${
                                    getViewMode() === "mobile" ? " h-100" : ""
                                }`}
                            >
                                <div className="modal-title text-center pb-4">{slides[slideIndex].header}</div>
                                <div className="onboarding-modal-body-text text-center">{slides[slideIndex].body}</div>
                                <div
                                    className="mt-auto d-flex flex-column justify-content-end align-items-center py-1 py-sm-2"
                                    style={{ backgroundColor: Colors.yellow }}
                                >
                                    {renderModalButton()}
                                    <StepNavigation
                                        className="mt-2 mt-md-3 mb-2"
                                        numberOfItems={slides.length}
                                        selectedIndex={slideIndex}
                                        jumpTo={jumpToSlide.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OnboardingModal;
