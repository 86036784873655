import { TebonusArrowSize, TebonusArrowType } from "components/_graphics";
import { DataCarousel, DataCarouselProps } from "../../Shared/DataCarousel";
import React, { FunctionComponent, useEffect } from "react";
import Slider from "react-slick";

interface YearSelectorProps {
    yearsList?: number[];
    selectedYear: number;
    setSelectedYear: (year: number) => void;
    isSmallScreen?: boolean;
}

export const YearSelector: FunctionComponent<YearSelectorProps> = props => {
    const yearSlider = React.createRef<Slider>();
    const hasTransactions = props.yearsList?.length;

    // Year selector Carousel settings
    const carouselProps = {
        arrows: false,
        infinity: false,
        animationSpeed: 600,
        customArrows: true,
        slidesToShowAllBreakpoints: 3,
        customArrowsProps: {
            arrowType: TebonusArrowType.SHARP,
            arrowSize: TebonusArrowSize.EXTRA_SMALL,
            prevSliderRequested() {
                yearSlider?.current?.slickPrev();
            },
            nextSliderRequested() {
                yearSlider?.current?.slickNext();
            },
        },
        elementNumber: props.yearsList?.length,
    } as DataCarouselProps;

    useEffect(() => {
        if (yearSlider) {
            yearSlider?.current?.slickGoTo(props.yearsList?.length);
        }
    }, []);

    const handleSelection = (year: number): void => {
        props.setSelectedYear(year);
    };

    const getYearsList = (): number[] => {
        if (hasTransactions) {
            return props.yearsList;
        }
        return [props.selectedYear];
    };

    // Mobile and Tablet
    if (props.isSmallScreen) {
        return (
            <select
                className="mx-2 date-dropdown w-100"
                aria-label="Year Selector"
                defaultValue="Jahr"
                value={props.selectedYear}
                onChange={e => handleSelection(Number.parseInt(e.target.value))}
            >
                <option hidden value="Jahr">
                    Jahr
                </option>
                {getYearsList().map((year, index) => {
                    return (
                        <option key={index} className="font-date-selector">
                            {year}
                        </option>
                    );
                })}
            </select>
        );
    }
    //Desktop
    return (
        <DataCarousel ref={yearSlider} {...carouselProps}>
            {getYearsList().map((year, index) => {
                return (
                    <div key={index}>
                        <div
                            className={`inline-link ${
                                year === props.selectedYear && hasTransactions ? "font-orange" : "font-silver"
                            }`}
                            onClick={() => handleSelection(year)}
                            onKeyUp={e => {
                                if (e.key === "Enter") {
                                    handleSelection(year);
                                }
                            }}
                            tabIndex={0}
                        >
                            {year}
                        </div>
                    </div>
                );
            })}
        </DataCarousel>
    );
};
