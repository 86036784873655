import { getAppSetting } from "utils/AppSettings";
import { logAnalyticsLogout } from "utils/FirebaseAnalytics";
import { AccessTokenData, AllReduxActions, AxiosRequestError, ErrorStatusCode, ReduxBaseType } from "../types/";
import {
    fetchServiceConfiguration,
    getAccessTokenUsingRefreshToken,
    invokeEndSession,
    invokeIdpLogout,
    makeAuthorizationRequest,
    makeTokenRequestUsingCode,
    revokeToken,
} from "../utils/AuthHelper";
import { AppThunkAction } from ".";
import { Reducer } from "redux";
import { TokenResponse } from "@openid/appauth";
import { AxiosResponse } from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

const REDIRECT_TO_IDP_SERVER = "REDIRECT_TO_IDP_SERVER";
const REDIRECT_TO_IDP_SERVER_SUCCESS = "REDIRECT_TO_IDP_SERVER_SUCCESS";
const REDIRECT_TO_IDP_SERVER_FAIL = "REDIRECT_TO_IDP_SERVER_FAIL";
const USER_AUTHENTICATE_SUCCESS = "USER_AUTHENTICATE_SUCCESS";
const USER_AUTHENTICATE_FAIL = "USER_AUTHENTICATE_FAIL";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const LOGOUT_FAIL = "LOGOUT_FAIL";
const REFRESH_TOKEN = "REFRESH_TOKEN";
const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";
const RESET_AUTH_STATE = "RESET_AUTH_STATE";
const DESIRED_LANDING_URL = "DESIRED_LANDING_URL";

interface IDesiredLandingUrlAction {
    type: typeof DESIRED_LANDING_URL;
    payload: string;
}

export interface IResetAuthStateAction {
    type: typeof RESET_AUTH_STATE;
}

export interface IRedirectToIdpServerAction {
    type: typeof REDIRECT_TO_IDP_SERVER;
}

export interface IRedirectToIdpServerSuccessAction {
    type: typeof REDIRECT_TO_IDP_SERVER_SUCCESS;
}

export interface IRedirectToIdpServerFailAction {
    type: typeof REDIRECT_TO_IDP_SERVER_FAIL;
    error: string;
}

export interface IUserAuthenticateSuccessAction {
    type: typeof USER_AUTHENTICATE_SUCCESS;
    payload: TokenResponse;
}

export interface IUserAuthenticateFailAction {
    type: typeof USER_AUTHENTICATE_FAIL;
    error: AxiosRequestError;
}

export interface ILogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS;
    payload: AxiosResponse;
}

export interface ILogoutFailAction {
    type: typeof LOGOUT_FAIL;
    error: AxiosRequestError;
}

export interface IAuthState extends ReduxBaseType {
    tokenResponse: TokenResponse | null;
    isAuthenticated: boolean;
    isRefreshing: boolean;
    isRedirected: boolean;
    error: AxiosRequestError | string;
    desiredLandingURL: string;
}

export interface IRefreshTokenAction {
    type: typeof REFRESH_TOKEN;
}

export interface IRefreshTokenSuccessAction {
    type: typeof REFRESH_TOKEN_SUCCESS;
    payload: TokenResponse;
}

export interface IRefreshTokenFailAction {
    type: typeof REFRESH_TOKEN_FAIL;
    error: AxiosRequestError;
}

export type AuthActionTypes =
    | IUserAuthenticateSuccessAction
    | IUserAuthenticateFailAction
    | ILogoutFailAction
    | ILogoutSuccessAction
    | IRefreshTokenAction
    | IRefreshTokenSuccessAction
    | IRefreshTokenFailAction
    | IRedirectToIdpServerAction
    | IRedirectToIdpServerSuccessAction
    | IRedirectToIdpServerFailAction
    | IResetAuthStateAction
    | IDesiredLandingUrlAction;

const authInitialState: IAuthState = {
    tokenResponse: null,
    isAuthenticated: false,
    isRefreshing: false,
    isRedirected: false,
    error: "",
    desiredLandingURL: "/",
    isLoading: false,
    errorCode: ErrorStatusCode.noError,
};

export const reducer: Reducer<IAuthState> = (state: IAuthState = authInitialState, action: AuthActionTypes): IAuthState => {
    switch (action.type) {
        case REDIRECT_TO_IDP_SERVER:
            return {
                ...state,
                isRedirected: false,
                performedAction: AllReduxActions.REDIRECT_TO_IDP_SERVER,
            };
        case REDIRECT_TO_IDP_SERVER_SUCCESS:
            return {
                ...state,
                isRedirected: true,
                performedAction: AllReduxActions.REDIRECT_TO_IDP_SERVER_SUCCESS,
            };
        case REDIRECT_TO_IDP_SERVER_FAIL:
            return {
                ...state,
                isRedirected: false,
                error: (action as IRedirectToIdpServerFailAction).error,
                performedAction: AllReduxActions.REDIRECT_TO_IDP_SERVER_FAIL,
            };
        case USER_AUTHENTICATE_SUCCESS:
            return {
                ...state,
                tokenResponse: (action as IUserAuthenticateSuccessAction).payload,
                isAuthenticated: true,
                error: "",
                performedAction: AllReduxActions.USER_AUTHENTICATE_SUCCESS,
            };
        case USER_AUTHENTICATE_FAIL:
            return {
                ...state,
                tokenResponse: null,
                isAuthenticated: false,
                error: (action as IUserAuthenticateFailAction).error,
                performedAction: AllReduxActions.USER_AUTHENTICATE_FAIL,
            };
        case REFRESH_TOKEN:
            return {
                ...state,
                isRefreshing: true,
                performedAction: AllReduxActions.REFRESH_TOKEN,
            };
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                tokenResponse: (action as IRefreshTokenSuccessAction).payload,
                isRefreshing: false,
                isAuthenticated: true,
                error: "",
                performedAction: AllReduxActions.REFRESH_TOKEN_SUCCESS,
            };
        case REFRESH_TOKEN_FAIL:
            return {
                ...state,
                tokenResponse: null,
                error: (action as IRefreshTokenFailAction).error,
                isRefreshing: false,
                isAuthenticated: false,
                performedAction: AllReduxActions.REFRESH_TOKEN_FAIL,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                isAuthenticated: false,
                tokenResponse: null,
                error: "",
                performedAction: AllReduxActions.LOGOUT_SUCCESS,
            };
        case LOGOUT_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                tokenResponse: null,
                error: (action as ILogoutFailAction).error,
                performedAction: AllReduxActions.LOGOUT_FAIL,
            };
        case RESET_AUTH_STATE: {
            return {
                ...state,
                isRefreshing: false,
                isAuthenticated: false,
                tokenResponse: null,
                error: "",
                performedAction: AllReduxActions.RESET_AUTH_STATE,
            };
        }
        case DESIRED_LANDING_URL:
            return {
                ...state,
                desiredLandingURL: action.payload,
                performedAction: AllReduxActions.DESIRED_LANDING_URL,
            };
        default:
            return state;
    }
};

export const actionCreators = {
    setDesiredLandingURL: (desiredLandingUrl: string): AuthActionTypes =>
        <IDesiredLandingUrlAction>{
            type: DESIRED_LANDING_URL,
            payload: desiredLandingUrl,
        },

    redirect:
        (isRegistration = false): AppThunkAction<AuthActionTypes> =>
        async dispatch => {
            try {
                dispatch(<IRedirectToIdpServerAction>{ type: REDIRECT_TO_IDP_SERVER });
                const serviceConfig = await fetchServiceConfiguration();

                if (isRegistration) {
                    const REACT_APP_IDENTITY_SERVER_URL = getAppSetting("REACT_APP_IDENTITY_SERVER_URL");
                    serviceConfig.authorizationEndpoint = `${REACT_APP_IDENTITY_SERVER_URL}/Identity/Account/Register`;
                }

                const isRedirected = await makeAuthorizationRequest(serviceConfig, isRegistration);
                if (isRedirected) {
                    dispatch(<IRedirectToIdpServerSuccessAction>{
                        type: REDIRECT_TO_IDP_SERVER_SUCCESS,
                    });
                }
            } catch {
                dispatch(<IRedirectToIdpServerFailAction>{
                    type: REDIRECT_TO_IDP_SERVER_FAIL,
                    error: "Redirect Failed!",
                });
            }
        },
    userAuthentication: (): AppThunkAction<AuthActionTypes> => async dispatch => {
        try {
            const tokenResponse = await makeTokenRequestUsingCode();
            dispatch(<IUserAuthenticateSuccessAction>{
                type: USER_AUTHENTICATE_SUCCESS,
                payload: tokenResponse,
            });
        } catch (err) {
            dispatch(<IUserAuthenticateFailAction>{ type: USER_AUTHENTICATE_FAIL, error: err });
        }
    },
    refreshToken: (): AppThunkAction<AuthActionTypes> => async (dispatch, getState) => {
        dispatch(<IRefreshTokenAction>{ type: REFRESH_TOKEN });
        try {
            const { tokenResponse } = getState().authReducer;
            if (tokenResponse != null) {
                const { refreshToken } = tokenResponse;

                const freshTokenResponse = await getAccessTokenUsingRefreshToken(refreshToken);
                dispatch(<IRefreshTokenSuccessAction>{
                    type: REFRESH_TOKEN_SUCCESS,
                    payload: freshTokenResponse,
                });
            } else {
                dispatch(<IResetAuthStateAction>{ type: RESET_AUTH_STATE });
            }
        } catch (err) {
            dispatch(<IRefreshTokenFailAction>{ type: REFRESH_TOKEN_FAIL, error: err });
        }
    },
    logout: (): AppThunkAction<AuthActionTypes> => async (dispatch, getState) => {
        try {
            const { tokenResponse } = getState().authReducer;

            if (tokenResponse != null) {
                invokeEndSession(tokenResponse.idToken);

                const isRefreshTokenRevoked = await revokeToken(tokenResponse.refreshToken, "refresh_token");
                const isAccessTokenRevoked = await revokeToken(tokenResponse.accessToken, "access_token");

                if (isRefreshTokenRevoked && isAccessTokenRevoked) {
                    invokeIdpLogout();
                    dispatch(<ILogoutSuccessAction>{ type: LOGOUT_SUCCESS });
                    logAnalyticsLogout();
                }
            } else {
                dispatch(<IResetAuthStateAction>{ type: RESET_AUTH_STATE });
            }
        } catch (err) {
            dispatch(<ILogoutFailAction>{ type: LOGOUT_FAIL, error: err });
        }
    },
    isTokenAlive: (): AppThunkAction<AuthActionTypes, boolean> => (__dispatch, getState) => {
        const { tokenResponse } = getState().authReducer;

        try {
            const decoded = jwtDecode(tokenResponse.accessToken) as AccessTokenData;
            const tokenExpirationTime = dayjs.unix(Number.parseInt(decoded.exp, 10));
            return dayjs().isBefore(tokenExpirationTime);
        } catch {
            return false;
        }
    },
    isTokenExpiring: (): AppThunkAction<AuthActionTypes, boolean> => (__dispatch, getState) => {
        const { tokenResponse } = getState().authReducer;

        try {
            const decoded = jwtDecode(tokenResponse.accessToken) as AccessTokenData;
            const tokenExpirationTime = dayjs.unix(Number.parseInt(decoded.exp, 10));

            // check if token is within 30 seconds to current time
            const isExpiring = Math.abs(dayjs().diff(tokenExpirationTime, "seconds")) < 30;

            return isExpiring;
        } catch {
            return true;
        }
    },
};
